import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-12lw4hd-0"
})(["box-sizing:border-box;position:relative;textarea{box-sizing:border-box;display:block;width:100%;padding:", ";border:1px solid ", ";border-radius:", ";background-color:", ";color:", ";outline:none;resize:none;transition:border-color ", " ", ",box-shadow ", " ", ";font-family:", ";", " font-weight:", ";appearance:none;&:focus{", "}&::placeholder{color:", ";font-style:italic;}", " ", " ", "}", " ", " ", ""], function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.theme.colors.form.border.base;
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.fontWeights.normal;
}, focusRing, function (props) {
  return props.theme.colors.form.placeholder.base;
}, function (props) {
  return props.resizable && css(["resize:vertical;"]);
}, function (props) {
  return props.hasBeforeElement && css(["padding-left:40px;"]);
}, function (props) {
  return props.hasAfterElement && css(["padding-right:40px;"]);
}, function (props) {
  return props.disabled && css(["opacity:0.4;textarea{cursor:not-allowed;}"]);
}, function (props) {
  return props.invalid && css(["textarea{border-color:", ";}"], function (props) {
    return props.theme.colors.form.border.error;
  });
}, COMMON);
export var Accessory = styled.div.withConfig({
  displayName: "styles__Accessory",
  componentId: "sc-12lw4hd-1"
})(["position:absolute;color:", ";", ";", ";"], function (props) {
  return props.theme.colors.icon.base;
}, function (props) {
  return props.before && css(["top:", ";left:", ";"], props.theme.space[300], props.theme.space[350]);
}, function (props) {
  return props.after && css(["right:", ";bottom:", ";"], props.theme.space[350], props.theme.space[300]);
});
Container.displayName = "TextareaContainer";
Accessory.displayName = "TextareaAccessory";
export default Container;