import * as React from "react";
import { ThemeProvider as BaseThemeProvider } from "styled-components";
import { theme } from "@sproutsocial/seeds-react-theme";
import { TypeSproutTheme, TypeTheme } from "@sproutsocial/seeds-react-theme";

// We can append additional themes types here
type TypeAllThemes = TypeTheme | TypeSproutTheme;

type TypeProps = {
  readonly theme?: TypeAllThemes;
  readonly children?: React.ReactNode;
};

const ThemeProvider = (props: TypeProps) => (
  <BaseThemeProvider {...props} theme={props.theme || theme} />
);

export default ThemeProvider;
