import styled, { css } from "styled-components";
import { TYPOGRAPHY, COMMON, FLEXBOX } from "@sproutsocial/seeds-react-system-props";
import { focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import Text from "../Text";
var Container = styled(Text).withConfig({
  displayName: "styles__Container",
  componentId: "sc-t42egr-0"
})(["border:0;font-family:", ";text-decoration:", ";appearance:none;cursor:pointer;", " font-weight:", ";color:", ";&:hover{color:", ";text-decoration:underline;}&:active{color:", ";}&:focus{", "}&:focus:active{box-shadow:none;}", " ", " ", " ", " ", ""], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.underline ? "underline" : "none";
}, function (props) {
  return props.disabled && css(["opacity:0.4;cursor:not-allowed;"]);
}, function (props) {
  return props.theme.fontWeights.semibold;
}, function (props) {
  return props.theme.colors.link.base;
}, function (props) {
  return props.theme.colors.link.hover;
}, function (props) {
  return props.theme.colors.link.hover;
}, focusRing, function (props) {
  return !props.href && css(["background:none;"]);
}, function (props) {
  return props.disabled && disabled;
}, COMMON, TYPOGRAPHY, FLEXBOX);
export default Container;