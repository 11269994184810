import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.th.withConfig({
  displayName: "styles__Container",
  componentId: "sc-fjeapu-0"
})(["", " font-weight:", ";padding:", ";text-align:", ";", " ", ""], function (props) {
  return props.theme.typography[100];
}, function (props) {
  return props.theme.fontWeights.semibold;
}, function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.alignment;
}, function (props) {
  return props.sortable && css(["position:relative;cursor:pointer;"]);
}, COMMON);
export var SortIcon = styled.span.withConfig({
  displayName: "styles__SortIcon",
  componentId: "sc-fjeapu-1"
})(["position:absolute;top:50%;transform:translateY(-50%);font-size:0;padding:0 ", ";"], function (props) {
  return props.theme.space[200];
});
export var UnstyledButton = styled.button.withConfig({
  displayName: "styles__UnstyledButton",
  componentId: "sc-fjeapu-2"
})(["background:none;border:none;color:inherit;font:inherit;line-height:normal;overflow:visible;padding:0;-webkit-appearance:none;-moz-appearance:none;cursor:pointer;"]);
export default Container;