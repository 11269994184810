import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-18dw7or-0"
})(["box-sizing:border-box;position:relative;display:flex;flex-wrap:wrap;align-items:center;align-content:center;cursor:text;width:100%;border:1px solid ", ";border-radius:", ";margin:0;padding:", ";padding-top:", ";background-color:", ";color:", ";transition:border-color ", " ", ",box-shadow ", " ", ";", ";font-family:", ";font-weight:", ";appearance:none;button{margin:", " ", " 0 0;}input{", ";outline:none;border:none;flex:1;padding:0;padding-top:", ";margin:", " ", " ", " 0;color:", ";background-color:", ";min-height:20px;&::-webkit-search-cancel-button{appearance:none;}&:focus{box-shadow:none;}&::-ms-clear{display:none;}&:not(output):not(:focus):-moz-ui-invalid{box-shadow:none;}&::placeholder{color:", ";font-style:italic;}", "}", " ", " ", " ", " ", " ", " ", ""], function (props) {
  return props.theme.colors.form.border.base;
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontWeights.normal;
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.space[100];
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.theme.space[100];
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.colors.form.placeholder.base;
}, function (props) {
  return props.disabled && css(["opacity:0.4;cursor:not-allowed;"]);
}, function (props) {
  return props.hasBeforeElement && css(["padding-left:40px;"]);
}, function (props) {
  return props.hasAfterElement && css(["padding-right:40px;"]);
}, function (props) {
  return props.disabled && css(["opacity:0.4;cursor:not-allowed;"]);
}, function (props) {
  return props.focused && css(["", ""], focusRing);
}, function (props) {
  return props.invalid && css(["border-color:", ";"], function (props) {
    return props.theme.colors.form.border.error;
  });
}, function (props) {
  return props.warning && css(["border-color:", ";"], function (props) {
    return props.theme.colors.form.border.warning;
  });
}, COMMON);
Container.displayName = "TokenInputContainer";
export default Container;