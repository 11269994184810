import React from "react";
import { Tooltip, Text, Box, Icon } from "@sproutsocial/racine";
import { Headline, LinkContainer } from "./styles";
import useComponentDescription from "queries/useComponentDescription";
import ClientOnly from "components/ClientOnly";

const ComponentTag = ({ name, slug = "", ...rest }) => {
  const lowerCaseName = name.toLowerCase();
  const displayName = lowerCaseName.replace("-", " ");
  const urlSlug = lowerCaseName.replace(" ", "-") + slug;
  const description = useComponentDescription(displayName);

  return (
    <ClientOnly>
      <Tooltip
        placement="top"
        maxWidth="300px"
        content={
          <Box display="flex" flexDirection="column">
            <Headline mb={200} color="text.headline" fontWeight="bold">
              {displayName}
            </Headline>
            {description && <Text color="text.body">{description}</Text>}
          </Box>
        }
        zIndex={501}
      >
        <LinkContainer to={"/components/" + urlSlug} {...rest}>
          <Icon mr={300} size="mini" name="atom-solid" />
          {displayName}
        </LinkContainer>
      </Tooltip>
    </ClientOnly>
  );
};

export default ComponentTag;
