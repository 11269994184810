import React from "react";
import styled from "styled-components";
import media from "utils/media";
import Logo from "components/Logo";
import NavDropdown from "components/navigation/NavDropdown";
import PatternsMenu from "components/navigation/PatternsMenu";
import BrandMenu from "components/navigation/BrandMenu";
import WritingMenu from "components/navigation/WritingMenu";
import VisualMenu from "components/navigation/VisualMenu";
import ComponentsMenu from "components/navigation/ComponentsMenu";
import HeaderNav from "components/navigation/HeaderNav";

const Container = styled.header`
  display: flex;
  position: relative;
  background: ${(props) => props.theme.colors.neutral[0]};
  ${(props) => props.theme.typography[300]} ${media.medium`
    grid-template-columns: 1fr;
  `}

  #header-menu {
    opacity: 0;
  }

  &:hover #header-menu {
    opacity: 1;

    ${media.small`
      opacity: 0;
      pointer-events: none;
    `};
  }

  &:before {
    content: "";
    position: absolute;
    background: ${(p) => p.theme.colors.neutral[200]};
    top: calc(${(p) => p.theme.sizes.header.height}px - 1px);
    left: ${(p) => p.theme.sizes.sidebar.minWidth}px;
    right: 0;
    height: 1px;

    ${media.medium`
      left: 0;
    `};
  }
`;

class Header extends React.Component {
  state = {
    hoveredItem: ""
  };

  handleMouseOver = (e) => {
    if (e.target.text) {
      const text = e.target.text.toLowerCase().trim();

      setTimeout(() => {
        this.setState({
          hoveredItem: text
        });
      }, 150);
    }
  };

  handleMouseOut = (e) => {
    this.setState({ hoveredItem: "" });
  };

  // Only close the nav when you click on a page, not elsewhere in the dropdown
  handleClick = (e) => {
    const tag = e.target.tagName;

    if (
      tag === "DIV" ||
      tag === "H2" ||
      tag === "P" ||
      tag === "LI" ||
      tag === "UL"
    ) {
      return;
    }

    this.setState({ hoveredItem: "" });
  };

  getMenuComponent = () => {
    switch (this.state.hoveredItem) {
      case "patterns":
        return <PatternsMenu />;
      case "brand":
        return <BrandMenu />;
      case "visual":
        return <VisualMenu />;
      case "writing":
        return <WritingMenu />;
      case "components":
        return <ComponentsMenu />;
      default:
        return null;
    }
  };

  render() {
    const menuComponent = this.getMenuComponent();

    return (
      <Container
        {...this.props}
        onMouseLeave={this.handleMouseOut}
        onClick={this.handleClick}
        id="site-header"
      >
        <Logo />

        <HeaderNav // eslint-disable-line
          onMouseOver={this.handleMouseOver}
          hoveredItem={this.state.hoveredItem}
        />

        {this.state.hoveredItem && menuComponent && (
          <NavDropdown>{menuComponent}</NavDropdown>
        )}
      </Container>
    );
  }
}

export default Header;
