import React from "react";
import { Highlight } from "prism-react-renderer";
import prismTheme from "utils/prism-theme";

const HighlightedCode = ({ codeString, language, ...props }) => {
  return (
    <Highlight theme={prismTheme} code={codeString} language={language}>
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={`${className} ${props.className}`} style={style}>
          <code className={`language-${language}`}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span key={key} {...getTokenProps({ token })} />
                ))}
              </div>
            ))}
          </code>
        </pre>
      )}
    </Highlight>
  );
};

export default HighlightedCode;
