import React from "react";
import { MenuButton, Menu, Box, Text } from "@sproutsocial/racine";
import Insights from "assets/logo_small.svg";
import Design from "assets/design-blog.svg";

const ITEMS = [
  {
    url: "https://medium.com/sprout-social-design",
    image: Design,
    title: "Design at Sprout Social",
    subtitle:
      "A collection of stories, ideas, and lessons from Sprout Social’s design and creative teams."
  },
  {
    url: "https://sproutsocial.com/insights",
    image: Insights,
    title: "Insights",
    subtitle: "Social Media insights and product updates"
  }
];

const BlogMenu = () => (
  <Menu>
    <Menu.Group maxWidth="320px">
      {ITEMS.map((item, i) => (
        <Menu.Item onClick={() => window.open(item.url, "_blank")} key={i}>
          <Box display="flex">
            <img src={item.image} style={{ margin: 0 }} alt="" />

            <Box ml={350}>
              <Text as="div" fontSize={200} fontWeight="bold">
                {item.title}
              </Text>
              <Text as="div" fontSize={100} color="neutral.600">
                {item.subtitle}
              </Text>
            </Box>
          </Box>
        </Menu.Item>
      ))}
    </Menu.Group>
  </Menu>
);

const BlogDropdown = (props) => {
  return (
    <MenuButton
      content={<BlogMenu />}
      popoutProps={{
        zIndex: 99999
      }}
      {...props}
    >
      <Text
        fontWeight="normal"
        fontSize={200}
        color="neutral.700"
        css={`
          &:hover {
            text-decoration: underline;
          }
        `}
      >
        Blog
      </Text>
    </MenuButton>
  );
};

export default BlogDropdown;
