import React from "react";
import Directives from "components/global/Directives";

const isObject = (obj) => {
  return obj === Object(obj);
};

// we need to clean up the array since `n/` newlines break this now
const filterChildren = (children) =>
  children.filter((item) => typeof item !== "string");

const Renderer = ({ children }) => {
  const filteredChildren = filterChildren(children);

  const itemRenderer = (props) => {
    const type = props.children[0].props.children === "DO:" ? "do" : "dont";
    const strippedChildren = props.children.slice(1);

    if (type === "do") {
      return <Directives.Do>{strippedChildren}</Directives.Do>;
    }

    return <Directives.Dont>{strippedChildren}</Directives.Dont>;
  };

  return (
    <Directives>
      {React.Children.map(filteredChildren, (child) =>
        itemRenderer(child.props)
      )}
    </Directives>
  );
};

export const shouldRenderDirectives = (children) => {
  const filteredChildren = filterChildren(children);

  // We are looking for the component structure of an unordered list element that has multiple `li` elements as children, and those `li` elements begin with a `strong` tag that has a value of either "DO:" or "DON'T"
  // First, make sure that we have children, at least on child, and that that first child has a props object
  if (filteredChildren && filteredChildren[0] && filteredChildren[0].props) {
    // Grab a reference to the props of the first `li` element in the `ul`
    const liProps = filteredChildren[0].props;
    // If the `li` element has multiple children
    if (liProps && liProps.children && liProps.children[0]) {
      // Check to see if the `li` element's first child is an object
      if (isObject(liProps.children[0])) {
        // Grab a reference to the first child
        const firstLiElement = liProps.children[0];

        // If the first `li` element's first child is a `strong` element with the appropriate values
        if (
          firstLiElement.props &&
          firstLiElement.type === "strong" &&
          ["DO:", "DON’T:", "DON'T:"].includes(firstLiElement.props.children)
        ) {
          // the first <li> in the list's first child is a bold element with the a value of either "DO:" or "DON'T:", so we can assume at this point that we are inside of a DO/DON'T block.
          return true;
        }
      }
    }
  }

  return false;
};

export default Renderer;
