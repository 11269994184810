import React from "react";
import { Box } from "@sproutsocial/racine";

const Image = ({ image, src, alt, title, ...rest }) => {
  // gatsby is not compiling the imported images passed to src
  const source = image || src;

  if (source && (source.endsWith(".gif") || source.endsWith(".svg")) && title) {
    return (
      <figure>
        <img src={source} alt={alt} {...rest} style={{ margin: 0 }} />
        <figcaption className="gatsby-resp-image-figcaption">
          {title}
        </figcaption>
      </figure>
    );
  }

  return <Box as="img" src={source} alt={alt} {...rest} />;
};

export default Image;
