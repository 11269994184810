import React from "react";
import { useState } from "react";
import {
  Box,
  Checkbox,
  Icon,
  Text,
  Label as RacineLabel,
  theme
} from "@sproutsocial/racine";
import styled from "styled-components";
import profiles from "docs/examples/modal/ModalProfiles";

const Ul = styled.ul`
  padding: "0px";
  margin: "0px";
`;

const Li = styled.li`
  list-style: none;
  margin-bottom: ${theme.space[0]};
`;

const Label = styled(RacineLabel)`
  display: flex;
  justify-content: space-between;
  padding: ${theme.space[300]};
  cursor: pointer;
  border-radius: ${theme.radii.inner};
  &:hover {
    background: ${theme.colors.neutral[100]};
  }
`;

function ModalListExample() {
  const [selectedValues, setSelectedValues] = useState([]);
  const isChecked = (value) => selectedValues.includes(value);
  const removeItem = (value) => {
    const filteredValues = selectedValues.filter((item) => item !== value);
    setSelectedValues(filteredValues);
  };

  return (
    <Ul>
      {profiles.map((profile) => (
        <Li key={profile.uId}>
          <Label htmlFor={profile.uId}>
            <Box display="flex" alignItems="center">
              <Icon name={profile.icon.name} color={profile.icon.color}></Icon>
              <Text ml={300} fontSize={200}>
                {profile.profile}
              </Text>
            </Box>
            <Checkbox
              id={profile.uId}
              value={profile.uId}
              checked={isChecked(profile.uId)}
              onChange={(e) =>
                isChecked(profile.uId)
                  ? removeItem(e.target.value)
                  : setSelectedValues([...selectedValues, e.target.value])
              }
            />
          </Label>
        </Li>
      ))}
    </Ul>
  );
}

export default ModalListExample;
