import styled, { createGlobalStyle } from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import "react-toastify/dist/ReactToastify.css";
import Box from "../Box";
import Icon from "../Icon";
var Container = styled(Box).withConfig({
  displayName: "styles__Container",
  componentId: "sc-oqrk23-0"
})(["display:flex;align-items:center;font-family:", ";", " position:relative;background:", ";border-left:2px solid ", ";"], function (p) {
  return p.theme.fontFamily;
}, function (p) {
  return p.theme.typography[200];
}, function (p) {
  return p.theme.colors.container.background.base;
}, function (p) {
  return p.theme.colors.container.border[p.type];
});
export var CustomIcon = styled(Icon).withConfig({
  displayName: "styles__CustomIcon",
  componentId: "sc-oqrk23-1"
})(["margin-right:", ";transform:translateY(4px);color:", ";"], function (props) {
  return props.theme.space[400];
}, function (p) {
  return p.customColor ? undefined : p.theme.colors.icon[p.type];
});
export var GlobalToastStyles = createGlobalStyle([".Toastify__toast{box-shadow:", " !important;background:transparent !important;}.Toastify__toast:last-of-type{margin-bottom:0 !important;}.Toastify-container-overrides{padding:0 !important;width:360px !important;}.Toastify-toast-overrides{padding:0 !important;min-height:0 !important;border-radius:2px !important;}.Toastify__toast-container--bottom-right{bottom:", " !important;right:", " !important;}@media only screen and (max-width:480px){.Toastify-container-overrides{min-width:initial !important;}}"], function (p) {
  return p.theme.shadows.low;
}, function (p) {
  return p.theme.space[400];
}, function (p) {
  return p.theme.space[400];
});
export default Container;