import React from "react";
import styled from "styled-components";
import Box from "components/global/Box";
import Text from "components/global/Text";
import Link from "components/Link";
import { Icon, Token, Tooltip } from "@sproutsocial/racine";
import ClientOnly from "components/ClientOnly";

const Prop = styled(Token)`
  cursor: help;
  padding-top: 2px;
  font-family: ${(props) => props.theme.fontFamily.mono};
`;

const SystemProps = ({ systemProps, hideCTA = false, ...props }) => {
  return (
    <ClientOnly>
      <Box mb={300}>
        <Text typography={400} fontWeight="semibold">
          System props
        </Text>
      </Box>

      <Box {...props} className="medium" mb={500}>
        {systemProps.map((prop, i) => (
          <Tooltip
            key={i}
            placement="top"
            appearance="box"
            content={
              <Box maxWidth="25ch">
                <Text
                  as="div"
                  fontFamily="monospace"
                  typography={200}
                  css="white-space: pre-wrap;"
                  textAlign="center"
                >
                  {key[prop].reduce((string, item) => `${string}\n${item}`)}
                </Text>
              </Box>
            }
          >
            <Prop closeable={false} category={prop} mr={300}>
              {prop}
            </Prop>
          </Tooltip>
        ))}

        {!hideCTA && (
          <Text.span typography={300} ml={200} fontWeight="semibold">
            <Link to="/components/system-props">
              Learn more
              <Icon name="arrow-right-solid" size="mini" ml={300} mt="-2px" />
            </Link>
          </Text.span>
        )}
      </Box>
    </ClientOnly>
  );
};

export default SystemProps;

const key = {
  COMMON: ["color", "bg", "margin", "padding"],
  BORDER: [
    "border",
    "borderTop",
    "borderRight",
    "borderBottom",
    "borderLeft",
    "borderWidth",
    "borderStyle",
    "borderColor",
    "borderRadius",
    "borderBottomLeftRadius",
    "borderBottomRightRadius",
    "borderTopLeftRadius",
    "borderTopRightRadius",
    "boxShadow"
  ],
  TYPOGRAPHY: [
    "fontFamily",
    "typeScale",
    "fontStyle",
    "fontWeight",
    "lineHeight",
    "textAlign"
  ],
  LAYOUT: [
    "display",
    "size",
    "width",
    "height",
    "minWidth",
    "minHeight",
    "maxWidth",
    "maxHeight",
    "overflow",
    "verticalAlign"
  ],
  POSITION: ["position", "zIndex", "top", "right", "bottom", "left"],
  FLEXBOX: [
    "flexBasis",
    "flexDirection",
    "flexWrap",
    "alignContent",
    "alignItems",
    "justifyContent",
    "justifyItems",
    "order",
    "flex",
    "justifySelf",
    "alignSelf"
  ],
  GRID: [
    "gridGap",
    "gridRowGap",
    "gridColumnGap",
    "gridColumn",
    "gridRow",
    "gridArea",
    "gridAutoFlow",
    "gridAutoRows",
    "gridAutoColumns",
    "gridTemplateRows",
    "gridTemplateColumns",
    "gridTemplateAreas"
  ]
};
