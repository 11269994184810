import React from "react";
import Link from "components/Link";
import Box from "components/global/Box";
import { UnorderedList } from "components/Lists";
import NavHeading from "components/navigation/NavHeading";

const ProductMenu = (props) => {
  return (
    <>
      <Box width={[1 / 3]}>
        <NavHeading>Foundation</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/brand/personality">Brand personality</Link>
          </li>
          <li>
            <Link to="/brand/tenets">Brand tenets</Link>
          </li>
          <li>
            <Link to="/brand/values">Brand values</Link>
          </li>
        </UnorderedList>
      </Box>
    </>
  );
};

export default ProductMenu;
