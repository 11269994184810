import React from "react";
import styled from "styled-components";
import Table from "components/Table";
import { Icon, Text } from "@sproutsocial/racine";
import useComponentProps from "queries/useComponentProps";
import "array-flat-polyfill";

const StyledTable = styled(Table)`
  td {
    vertical-align: middle;
  }
`;

const Type = ({ name, raw, elements }) => {
  if (elements) {
    const filteredElements = elements.filter(
      (element) => element.name !== "unknown"
    );

    return (
      <React.Fragment>
        {filteredElements.map((element, i) => (
          <Text as="div" mr={300} my={200} key={i}>
            <code>{element.raw || element.value || element.name}</code>
          </Text>
        ))}
      </React.Fragment>
    );
  }

  return (
    <code
      css={`
        white-space: break-spaces;
      `}
    >
      {raw || name}
    </code>
  );
};

const Row = ({ name, type, description, defaultValue, required, ...rest }) => {
  if (!!description) {
    if (description.text.startsWith("DEPRECATED")) return null;
  }

  if (!type) return null;

  return (
    <tr>
      <td>
        <code>{name}</code>
      </td>
      <td>
        <Type {...type} />
      </td>
      <td>{defaultValue ? <code>{defaultValue.value}</code> : ""}</td>
      <td>
        <Text as="div" fontSize={200}>
          {description ? description.text : ""}
        </Text>
      </td>
      <td
        css={`
          text-align: center;
        `}
      >
        {required ? <Icon color="neutral.800" name="check-outline" /> : ""}
      </td>
    </tr>
  );
};

const PropTable = ({ name, ...rest }) => {
  const componentProps = useComponentProps(name);

  if (!componentProps || componentProps.length === 0) {
    return null;
  }

  return (
    <StyledTable className="medium" {...rest}>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Default</th>
          <th>Description</th>
          <th
            css={`
              text-align: center;
            `}
          >
            Required?
          </th>
        </tr>
      </thead>

      <tbody>
        {componentProps.map((prop, i) => (
          <Row {...prop} key={i} />
        ))}
      </tbody>
    </StyledTable>
  );
};

export default PropTable;
