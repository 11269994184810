import { createGlobalStyle } from "styled-components";
import { focusRing } from "@sproutsocial/racine";
import { transparentize } from "polished";

const GlobalStyles = createGlobalStyle`
  :root {
    --docsearch-container-background: ${(props) =>
      transparentize(0.32, props.theme.colors.neutral[1000])} !important;
    --docsearch-logo-color: ${(p) => p.theme.colors.neutral[400]} !important;
    --docsearch-modal-background: ${(p) =>
      p.theme.colors.neutral[100]} !important;
    --docsearch-primary-color: ${(p) =>
      p.theme.colors.button.primary.background.base} !important;
    --docsearch-hit-background: ${(p) =>
      p.theme.colors.button.primary.text.base} !important;
  }

  *:focus {
    ${focusRing}
  }

  /* Focus visible polyfill styles */
  /* For more info please see https://github.com/WICG/focus-visible */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none !important;
    box-shadow: none !important;
  }

  [class*='Checkbox']:before {
    transform: none;
  }

  [data-reach-menu] {
    z-index: 300;
  }

  iframe {
    width: 100%;
  }

  .gatsby-resp-image-wrapper {
    margin: 0 !important;
  }

  #main {
    scroll-padding-top: 40px;
  }

  // Override hack for breadcrumb component
  [data-qa-breadcrumb] li {
    margin-bottom: 0;
  }

  // Override hack for Menu & Listbox component
  [role=menu] li,
  [role=listbox] li,
  [role=list] li {
    margin-bottom: unset;
  }

  .DocSearch-Hits {
    color: ${(p) => p.theme.colors.button.primary.text.base} !important;
  }

  .DocSearch-Modal {
    border-radius: 8px !important;
  }

  .DocSearch-Commands li {
    margin-bottom: 0 !important;
  }

  .DocSearch-Input:focus {
    box-shadow: none !important;
  }

  .DocSearch-Hit-source {
    color: ${(p) => p.theme.colors.text.body}
  }

  .DocSearch-Commands-Key {
    box-shadow: none !important;
    border: 1px solid ${(p) => p.theme.colors.container.border.base} !important;
    background: transparent !important;
    padding: 0 !important;
  }

  .DocSearch-Hit-Container mark {
    color: ${(p) => p.theme.colors.pink[500]} !important;
  }
`;
export default GlobalStyles;
