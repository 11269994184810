import * as React from "react";
import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useTransition, animated } from "react-spring";
import FocusLock from "react-focus-lock";
import { Popper } from "react-popper";
import { MOTION_DURATION_FAST } from "@sproutsocial/seeds-motion/dist/seeds-motion-unitless";
import { useMutationObserver } from "@sproutsocial/seeds-react-hooks";
import Portal from "@sproutsocial/seeds-react-portal";
import Box, { TypeBoxProps } from "@sproutsocial/seeds-react-box";
import { TargetWrapper } from "./styles";
import type { TypePopoutProps, EnumPlacements } from "./PopoutTypes";

export const placements: { [key: string]: EnumPlacements } = {
  auto: "auto",

  top: "top",
  right: "right",
  bottom: "bottom",
  left: "left",

  "top-start": "top-start",
  "right-start": "right-start",
  "bottom-start": "bottom-start",
  "left-start": "left-start",

  "top-end": "top-end",
  "right-end": "right-end",
  "bottom-end": "bottom-end",
  "left-end": "left-end",
};

const doesRefContainEventTarget = (ref, event) => {
  return (
    ref.current &&
    event.target instanceof Node &&
    ref.current.contains(event.target)
  );
};

// Transition definitions for fading in and out
const transitionConfig = {
  from: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  leave: {
    opacity: 0,
  },
  config: {
    duration: MOTION_DURATION_FAST * 1000,
  },
};

export function Popout({
  isOpen,
  setIsOpen,
  content,
  children,
  placement = "auto",
  fullWidth = false,
  zIndex = 7,
  focusOnContent = true,
  onOpen,
  onClose,
  qa = {},
  popperProps,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  scheduleUpdateRef = () => {},
  appendToBody = true,
  focusLockProps = {},
  color,
  "aria-haspopup": ariaHasPopup,
  disableWrapperAria = false,
  id,
  ...rest
}: TypePopoutProps) {
  const PopoutComponentWrapper = appendToBody ? Portal : React.Fragment;
  const [isInternalShown, setIsInternalShown] = useState<boolean>(false);
  const isControlled = typeof isOpen === "boolean";
  const isShown = isControlled ? isOpen : isInternalShown;

  const setIsShown = useMemo(
    () =>
      isControlled && setIsOpen
        ? setIsOpen
        : (nextIsShown) => setIsInternalShown(nextIsShown),
    [isControlled, setIsOpen]
  );

  const targetRef = useRef<HTMLDivElement>();
  const popoutRef = useRef<HTMLDivElement>();
  const isFirstRender = useRef(true);

  // This callback will automatically trigger a recalculation of the popout position if the content is changed
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const scheduleUpdateCallback = useRef(() => {});

  useMutationObserver(
    popoutRef.current ?? null,
    {
      childList: true,
      characterData: true,
      subtree: true,
    },
    scheduleUpdateCallback.current
  );

  const {
    autoFocus = true,
    returnFocus = true,
    ...restFocusLockProps
  } = focusLockProps;

  const isInvalidContent = content === null || content === undefined;

  // Callbacks for showing, hiding, and toggling visibility of the popout
  // (Not used when isOpen is passed explicitly)
  const show = useCallback(() => setIsShown(true), [setIsShown]);
  const hide = useCallback(() => setIsShown(false), [setIsShown]);
  const toggle = useCallback(() => setIsShown(!isShown), [isShown, setIsShown]);

  useEffect(() => {
    const documentBody = document.body;

    if (isShown && documentBody) {
      // Callback passed to a click handler attached to document.body,
      // allowing user to close the popout by clicking outside
      const bodyClick = (e: MouseEvent): void => {
        if (
          doesRefContainEventTarget(targetRef, e) ||
          doesRefContainEventTarget(popoutRef, e)
        ) {
          return;
        }

        setIsShown(false, e);
      };

      // Callback for allowing user to close by keying "esc"
      const onEsc = (e: KeyboardEvent): void => {
        // older browsers use "Esc"
        if (["Escape", "Esc"].includes(e.key)) {
          // stop propagation to avoid interacting with other components when popout is shown
          // ie if we have a popout shown in a modal and hit esc, we don't want to close both the popout and modal
          e.stopPropagation();
          setIsShown(false, e);
        }
      };

      documentBody.addEventListener("click", bodyClick, { capture: true });
      documentBody.addEventListener("keydown", onEsc, { capture: true });
      return () => {
        documentBody.removeEventListener("click", bodyClick, { capture: true });
        documentBody.removeEventListener("keydown", onEsc, { capture: true });
      };
    }
  }, [isShown, setIsShown]);

  // Manage onOpen and onClose callbacks
  useEffect(
    () => {
      // Don't fire onClose on the first render
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      if (!onOpen && !onClose) {
        return;
      }

      if (isShown && onOpen) {
        onOpen();
      }

      if (!isShown && onClose) {
        onClose();
      }
    },
    // eslint-disable-next-line
    [isShown]
  );

  const transitions = useTransition(isShown, null, transitionConfig);
  // WAI-Aria properties for the popout trigger, disabled if necessary
  const ariaProps = useMemo(
    () =>
      disableWrapperAria
        ? {}
        : {
            "aria-expanded": isShown,
            "aria-haspopup": ariaHasPopup ? ariaHasPopup : true,
          },
    [isShown, ariaHasPopup, disableWrapperAria]
  );

  // In cases where a controlled popout is used (e.g. props.isOpen is true), we need
  // to wait for the targetRef to receive a value before rendering the popout. Otherwise,
  // the Popout component renders, but doesn't know how to position itself due the
  // `refereElement` property being undefined.
  const [shouldRenderPopout, setShouldRenderPopout] = useState<boolean>( // Only trigger this shouldRenderPopout logic when using a controlled component.
    // The reason for that is because controlled components may render the popout
    // immediately before the targetRef has a value set to it.
    !isControlled
  );

  const childrenRef = (el) => {
    targetRef.current = el;

    if (targetRef.current) {
      setShouldRenderPopout(true);
    }
  };

  return (
    <React.Fragment>
      {typeof children === "function" ? (
        children({
          ref: childrenRef,
          toggle,
          show,
          hide,
          ariaProps,
        })
      ) : (
        <TargetWrapper {...qa} id={id} {...rest} ref={childrenRef}>
          {React.cloneElement(children, {
            ...ariaProps,
            ...(!isControlled
              ? {
                  onClick: toggle,
                }
              : undefined),
          })}
        </TargetWrapper>
      )}
      {shouldRenderPopout &&
        !isInvalidContent &&
        transitions.map(
          ({ item, key, props }) =>
            item && (
              <PopoutComponentWrapper key={key}>
                <Popper
                  referenceElement={targetRef.current}
                  placement={placement}
                  modifiers={{
                    preventOverflow: {
                      boundariesElement: "viewport",
                    },
                  }}
                  {...popperProps}
                >
                  {({
                    ref,
                    style,
                    placement,
                    outOfBoundaries,
                    scheduleUpdate,
                  }) => {
                    const interceptRef = (el) => {
                      popoutRef.current = el;
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      ref(el);
                    };

                    scheduleUpdateCallback.current = scheduleUpdate;
                    scheduleUpdateRef(scheduleUpdate);

                    return (
                      <div
                        ref={interceptRef}
                        style={{
                          ...style,
                          zIndex,
                          width:
                            fullWidth && targetRef.current
                              ? targetRef.current.offsetWidth
                              : "initial",
                        }}
                        data-placement={placement}
                        data-qa-popout=""
                        data-qa-popout-isopen={isOpen === true}
                        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        color={color}
                        {...rest}
                      >
                        {item && !outOfBoundaries && (
                          <animated.div style={props}>
                            <FocusLock
                              autoFocus={autoFocus}
                              returnFocus={returnFocus}
                              disabled={!focusOnContent}
                              {...restFocusLockProps}
                            >
                              {typeof content === "function" &&
                                content({
                                  hide,
                                })}
                              {typeof content !== "function" && content}
                            </FocusLock>
                          </animated.div>
                        )}
                      </div>
                    );
                  }}
                </Popper>
              </PopoutComponentWrapper>
            )
        )}
    </React.Fragment>
  );
}

const PopoutContent = ({ children, ...rest }: TypeBoxProps) => (
  <Box
    bg="container.background.base"
    color="text.body"
    border={500}
    borderColor="container.border.base"
    borderRadius="outer"
    boxShadow="medium"
    p={400}
    m={300}
    {...rest}
  >
    {children}
  </Box>
);

PopoutContent.displayName = "Popout.Content";
Popout.Content = PopoutContent;

export default Popout;
