import { darken, lighten } from "polished";
import { useTheme } from "styled-components";

/**
 * The useInteractiveColor hook has context of theme mode (light or dark)
 * and can be used to lighten or darken a color dynamically
 *
 * note: colors are limited to our theme colors
 */
var useInteractiveColor = function useInteractiveColor(themeColor) {
  // Throw error if used outside of a ThemeProvider (styled-components)
  if (!useTheme()) {
    throw new Error("useInteractiveColor() must be used within a Styled Components ThemeProvider");
  }

  // Get the current theme mode ie. 'light' or 'dark'
  var themeMode = useTheme().mode;

  // If the theme mode is dark, return a lightened version of the themeValue
  if (themeMode === "dark") {
    return lighten(0.2, themeColor);
  } else {
    // If the theme mode is light, return a darkened version of the themeValue
    return darken(0.2, themeColor);
  }
};
export { useInteractiveColor };