import React from "react";
import {
  Box,
  Text,
  Icon,
  Card as RacineCard,
  CardLink as RacineCardLink
} from "@sproutsocial/racine";
import { trackEvent } from "utils/js";

import styled from "styled-components";

const TitleBox = styled.div`
  position: relative;
  flex-direction: column;
  height: 250px;
  padding: 40px;
  background: ${(p) => p.theme.colors.blue[200]};
  overflow: hidden;
  outline: 3px ${(p) => p.theme.colors.teal[1000]} solid;
  outline-offset: -15px;
  border-top-left-radius: ${(p) => p.theme.radii.outer};
  border-top-right-radius: ${(p) => p.theme.radii.outer};
  &:before {
    content: "";
    width: 80%;
    height: 80%;
    background: linear-gradient(
      58deg,
      rgba(125, 234, 213, 1) 0%,
      rgba(32, 91, 195, 1) 100%
    );
    filter: blur(50px);
    position: absolute;
    right: 0%;
    bottom: 0%;
    z-index: 0;
  }
  > div {
    position: relative;
    z-index: 2;
  }
  > div a,
  > div p {
    color: ${(p) => p.theme.colors.teal[1000]};
  }
`;

// This card is specifically built for the Resources page link tiles
const Card = ({
  title,
  description,
  media,
  children,
  icon,
  group,
  ...rest
}) => {
  return (
    <RacineCard
      display="flex"
      elevation="medium"
      flexDirection="column"
      height="100%"
      role="link"
      p={0}
      {...rest}
    >
      <Box
        onClick={() =>
          trackEvent("click", { category: "outbound", label: rest.href })
        }
      >
        {!media ? (
          <TitleBox>
            <div>
              <Text
                as="p"
                fontSize={200}
                fontWeight={700}
                fontStyle="uppercase"
              >
                {group}
              </Text>
              <RacineCardLink fontSize={700} fontWeight={500} external={true}>
                {title}
              </RacineCardLink>
            </div>
          </TitleBox>
        ) : (
          <Box
            borderTopLeftRadius="outer"
            borderTopRightRadius="outer"
            overflow="hidden"
            css={`
              img {
                margin: 0;
              }
            `}
          >
            <img src={media} alt={title} />
          </Box>
        )}

        <Box
          p={350}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          flex={1}
        >
          <Box>
            {media && <RacineCardLink external={true}>{title}</RacineCardLink>}
            <Text as="p" fontSize={200} color="text.body">
              {description}
            </Text>
          </Box>

          <Box display="flex" mt={400}>
            <Icon fixedWidth name="squares-arrow-up-right-outline" />
            {icon && (
              <img
                css={`
                  margin: 0 0 0 8px;
                `}
                src={icon}
                alt=""
              />
            )}
          </Box>
        </Box>
      </Box>
    </RacineCard>
  );
};

export default Card;
