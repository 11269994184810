import styled from "styled-components";
import { space, display, borders, borderColor, width } from "styled-system";

export const UnorderedList = styled.ul`
  ${(props) =>
    props.inline &&
    `
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline
      margin-bottom: 0;
    }
  `}

  ${(props) =>
    props.unstyled &&
    `
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 0;
    }
  `}

  ${space}
  ${display}
  ${borders}
  ${borderColor}
  ${width}
`;

export const OrderedList = styled.ol`
  margin: 0 0 ${(p) => p.theme.space[600]} 0;
  padding: 0;

  li {
    margin-left: calc(
      ${(p) => p.theme.space[400]} + ${(p) => p.theme.space[100]}
    );
  }

  ${space}
  ${display}
  ${borders}
  ${borderColor}
  ${width}
`;
