import { theme as baseLightTheme } from "@sproutsocial/racine";
import TYPOGRAPHY from "@sproutsocial/seeds-typography";

const breakpoints = ["676px", "900px", "1250px", "1450px"];

const namedBreakpoints = {
  tiny: breakpoints[0],
  small: breakpoints[1],
  medium: breakpoints[2],
  large: breakpoints[3]
};

const sizes = {
  wrapper: {
    gutter: 32
  },
  header: {
    height: 80
  },
  content: {
    padding: 96,
    // 1232px + (2 * 96px) (96 is padding on right and left)
    maxWidth: 1424
  },
  sidebar: {
    minWidth: 320,
    minWidthSmall: 280
  }
};

const fontFamily = Object.assign(TYPOGRAPHY.TYPOGRAPHY_FAMILY, {
  body: TYPOGRAPHY.TYPOGRAPHY_FAMILY.replace(/[""&]+/g, "").split(", "),
  bodyString: TYPOGRAPHY.TYPOGRAPHY_FAMILY,
  mono: "ibm-plex-mono, Menlo, Monaco, OperatorMono-Book, Inconsolata, monospace",
  system: TYPOGRAPHY.TYPOGRAPHY_FAMILY,
  proxima:
    '"Proxima Nova", proxima-nova, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  roboto:
    '"Roboto Serif", roboto-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Helvetica, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"'
});

const fontWeights = {
  normal: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_NORMAL,
  semibold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_SEMIBOLD,
  bold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_BOLD,
  extrabold: TYPOGRAPHY.TYPOGRAPHY_WEIGHT_EXTRA_BOLD
};

const namedBrandColors = {
  onyx: baseLightTheme.colors.neutral[1100],
  snowcap: baseLightTheme.colors.neutral[0],
  backgroundGray: baseLightTheme.colors.neutral[100],
  borderGray: baseLightTheme.colors.neutral[200],
  mediumGray: baseLightTheme.colors.neutral[700],
  jade: baseLightTheme.colors.teal[1000],
  spruce: baseLightTheme.colors.teal[900],
  emerald: baseLightTheme.colors.green[900],
  fern: baseLightTheme.colors.green[300],
  mint: baseLightTheme.colors.green[0],
  midnight: baseLightTheme.colors.blue[1000],
  sapphire: baseLightTheme.colors.blue[900],
  cobalt: baseLightTheme.colors.blue[700],
  cornflower: baseLightTheme.colors.blue[300],
  sky: baseLightTheme.colors.blue[200],
  cactus: baseLightTheme.colors.green[500],
  ocean: baseLightTheme.colors.teal[500],
  glacier: baseLightTheme.colors.teal[300],
  mist: baseLightTheme.colors.teal[50],
  citrine: baseLightTheme.colors.yellow[700],
  marigold: baseLightTheme.colors.yellow[600],
  sunrise: baseLightTheme.colors.yellow[300],
  canary: baseLightTheme.colors.yellow[100],
  almandine: baseLightTheme.colors.pink[900],
  rose: baseLightTheme.colors.pink[700],
  salmon: baseLightTheme.colors.orange[300],
  apricot: baseLightTheme.colors.orange[100],
  tourmaline: baseLightTheme.colors.magenta[900],
  orchid: baseLightTheme.colors.magenta[700],
  violet: baseLightTheme.colors.magenta[350],
  periwinkle: baseLightTheme.colors.purple[200]
};
const theme = {
  ...baseLightTheme,
  namedBreakpoints,
  breakpoints,
  fontWeights,
  sizes,
  fontFamily,
  fonts: fontFamily,
  namedBrandColors
};

export default theme;
