import styled from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { visuallyHidden } from "@sproutsocial/seeds-react-mixins";
var Container = styled.span.attrs({
  className: "Indicator"
}).withConfig({
  displayName: "styles__Container",
  componentId: "sc-8xib7y-0"
})(["position:relative;display:block;width:", ";height:", ";font-size:10px;line-height:0;color:", ";text-align:center;> div{", "}", ""], function (props) {
  return props.theme.space[400];
}, function (props) {
  return props.theme.space[400];
}, function (props) {
  var _props$color;
  return (_props$color = props.color) !== null && _props$color !== void 0 ? _props$color : props.theme.colors.icon.info;
}, visuallyHidden, COMMON);
export default Container;