import React from "react";
import Link from "components/Link";
import Box from "components/global/Box";
import { UnorderedList } from "components/Lists";
import NavHeading from "components/navigation/NavHeading";
import Grid from "components/Grid";
import Text from "components/global/Text";
import { Icon } from "@sproutsocial/racine";

const ComponentsMenu = (props) => {
  return (
    <>
      <Box width={[1 / 3]}>
        <NavHeading>Documentation</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/components/getting-started">Getting started</Link>
          </li>
          <li>
            <Link to="/components/migration">Migration</Link>
          </li>
          <li>
            <Link to="/components/system-props">System props</Link>
          </li>
          <li>
            <Link to="/components/theme">Theme</Link>
          </li>
          <li>
            <Link to="/components/utilities">Utilities</Link>
          </li>
          <li>
            <Link to="/components/changelog">Version history</Link>
          </li>
        </UnorderedList>
      </Box>

      <Box width={[2 / 3]}>
        <NavHeading>Components</NavHeading>

        <Grid>
          <Box width={1 / 3}>
            <UnorderedList unstyled>
              <li>
                <Link to="/components/avatar">Avatar</Link>
              </li>
              <li>
                <Link to="/components/badge">Badge</Link>
              </li>
              <li>
                <Link to="/components/banner">Banner</Link>
              </li>
              <li>
                <Link to="/components/box">Box</Link>
              </li>
              <li>
                <Link to="/components/button">Button</Link>
              </li>
              <li>
                <Link to="/components/character-counter">
                  Character Counter
                </Link>
              </li>
              <li>
                <Link to="/components/chart-legend">Chart Legend</Link>
              </li>
              <li>
                <Link to="/components/checkbox">Checkbox</Link>
              </li>
            </UnorderedList>
          </Box>

          <Box width={1 / 3}>
            <UnorderedList unstyled>
              <li>
                <Link to="/components/icon">Icon</Link>
              </li>
              <li>
                <Link to="/components/image">Image</Link>
              </li>
              <li>
                <Link to="/components/indicator">Indicator</Link>
              </li>
              <li>
                <Link to="/components/input">Input</Link>
              </li>
              <li>
                <Link to="/components/keyboard-key">Keyboard Key</Link>
              </li>
              <li>
                <Link to="/components/label">Label</Link>
              </li>
              <li>
                <Link to="/components/loader">Loader</Link>
              </li>
              <li>
                <Link to="/components/message">Message</Link>
              </li>
            </UnorderedList>
          </Box>

          <Box width={1 / 3}>
            <UnorderedList unstyled>
              <li>
                <Link to="/components/modal">Modal</Link>
              </li>
              <li>
                <Link to="/components/radio">Radio</Link>
              </li>
              <li>
                <Link to="/components/select">Select</Link>
              </li>
              <li>
                <Link to="/components/switch">Switch</Link>
              </li>
              <li>
                <Link to="/components/tabs">Tabs</Link>
              </li>
              <li>
                <Link to="/components/text">Text</Link>
              </li>
              <li>
                <Link to="/components/textarea">Textarea</Link>
              </li>
              <li>
                <Text.span typography={300} fontWeight="semibold">
                  <Link
                    to="/components"
                    css={(props) => `
                      color: ${props.theme.colors.blue[600]} !important;
                    `}
                  >
                    See all
                    <Icon
                      name="arrow-right-solid"
                      size="mini"
                      ml={300}
                      mt="-2px"
                    />
                  </Link>
                </Text.span>
              </li>
            </UnorderedList>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default ComponentsMenu;
