import React from "react";
import HighlightedCode from "components/mdx/HighlightedCode";
import CodeSandbox from "components/racine/CodeSandbox";

const preToCodeBlock = (preProps) => {
  if (
    preProps.children &&
    preProps.children.props &&
    preProps.children.type === "code"
  ) {
    // we have a <pre><code> situation
    const { children: codeString, className } = preProps.children.props;
    const { children, ...metaProps } = preProps;

    return {
      codeString: codeString.trim(),
      language: className && className.split("-")[1],
      ...metaProps
    };
  }

  return undefined;
};

const Pre = (preProps) => {
  const props = preToCodeBlock(preProps);

  if (props) {
    if (props.live || props.demo) {
      const linesOfCode = props.codeString.split(/\r\n|\r|\n/).length;

      return (
        <CodeSandbox
          inline={props.stateful ? false : true}
          static={props.static ? true : false}
          showEditor={!props.demo}
          linesOfCode={props.linesOfCode || linesOfCode}
          importStatement={props.import}
          className={props.wide ? "medium" : undefined}
        >
          {props.codeString}
        </CodeSandbox>
      );
    }

    return (
      <HighlightedCode
        className={props.wide ? "medium" : undefined}
        {...props}
      />
    );
  }

  return <pre {...preProps} />;
};

export default Pre;
