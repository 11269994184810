import * as React from "react";
import styled from "styled-components";
import Container from "./styles";
import type { TypeTextProps } from "./TextTypes";

const Headline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[400]}
`;

const SubHeadline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[300]}
`;

const SmallSubHeadline = styled(Container)`
  color: ${(props) => props.theme.colors.text.headline};
  font-weight: ${(props) => props.theme.fontWeights.bold};
  ${(props) => props.theme.typography[200]}
`;

const Byline = styled(Container)`
  color: ${(props) => props.theme.colors.text.subtext};
  ${(props) => props.theme.typography[200]}
`;

const SmallByline = styled(Container)`
  color: ${(props) => props.theme.colors.text.subtext};
  ${(props) => props.theme.typography[100]}
`;

const BodyCopy = styled(Container)`
  color: ${(props) => props.theme.colors.text.body};
  ${(props) => props.theme.typography[300]}
`;

const SmallBodyCopy = styled(Container)`
  color: ${(props) => props.theme.colors.text.body};
  ${(props) => props.theme.typography[200]}
`;

const Text = ({ fontSize, children, qa, color, ...rest }: TypeTextProps) => {
  const qaText = typeof children === "string" ? children : undefined;

  return (
    <Container
      typeScale={fontSize}
      data-qa-text={qaText}
      // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      color={color}
      {...qa}
      {...rest}
    >
      {children}
    </Container>
  );
};

Headline.displayName = "Text.Headline";
SubHeadline.displayName = "Text.SubHeadline";
SmallSubHeadline.displayName = "Text.SmallSubHeadline";
Byline.displayName = "Text.Byline";
SmallByline.displayName = "Text.SmallByline";
BodyCopy.displayName = "Text.BodyCopy";
SmallBodyCopy.displayName = "Text.SmallBodyCopy";

Text.Headline = Headline;
Text.SubHeadline = SubHeadline;
Text.SmallSubHeadline = SmallSubHeadline;
Text.Byline = Byline;
Text.SmallByline = SmallByline;
Text.BodyCopy = BodyCopy;
Text.SmallBodyCopy = SmallBodyCopy;

export default Text;
