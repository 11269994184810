import React from "react";
import styled from "styled-components";
import { color, space } from "styled-system";

const Swatch = styled.button.attrs(({ selected, onChange, bg }) => ({
  onClick: () => onChange(bg),
  isSelected: selected === bg
}))`
  width: 16px;
  height: 16px;
  border: none;
  appearance: none;
  cursor: pointer;
  border-radius: 500%;
  border: 1px solid ${(props) => props.theme.colors.neutral[200]};
  margin: 0;
  padding: 0;

  ${color}
  ${space}
`;

const ThemePicker = (props) => {
  return (
    <React.Fragment>
      <Swatch bg="neutral.0" aria-label="Light theme" mr={200} {...props} />
      <Swatch bg="neutral.1000" aria-label="Dark theme" {...props} />
    </React.Fragment>
  );
};

export default ThemePicker;
