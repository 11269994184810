import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-4stdun-0"
})(["box-sizing:border-box;position:relative;input{box-sizing:border-box;width:100%;border:1px solid transparent;border-radius:", ";background-color:", ";color:", ";outline:none;transition:border-color ", " ", ",box-shadow ", " ", ";font-family:", ";font-weight:", ";appearance:none;margin:0;padding:", ";font-size:", ";line-height:", ";&::-ms-clear{display:none;}&::-webkit-search-cancel-button{appearance:none;}&:focus{", "}&:not(output):not(:focus):-moz-ui-invalid{box-shadow:none;}&::placeholder{color:", ";font-style:italic;}", " ", "}", " ", " ", " ", " ", ""], function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontWeights.normal;
}, function (props) {
  switch (props.size) {
    case "large":
      return "".concat(props.theme.space[350], " ").concat(props.theme.space[400]);
    case "small":
      return props.theme.space[200];
    case "default":
    default:
      return props.theme.space[300];
  }
}, function (props) {
  switch (props.size) {
    case "large":
      return props.theme.typography[300].fontSize;
    case "small":
    case "default":
    default:
      return props.theme.typography[200].fontSize;
  }
}, function (props) {
  switch (props.size) {
    case "large":
      return props.theme.typography[300].lineHeight;

    /* 16px was the value prior to this change to 'large'. Leaving as-is but it is a non-Seeds typography line-height. It may have a big impact though */
    case "small":
    case "default":
    default:
      return "16px";
  }
}, focusRing, function (props) {
  return props.theme.colors.form.placeholder.base;
}, function (props) {
  return props.hasBeforeElement && css(["padding-left:40px;"]);
}, function (props) {
  return props.hasAfterElement && css(["padding-right:40px;"]);
}, function (props) {
  return props.disabled && css(["opacity:0.4;input{cursor:not-allowed;}"]);
}, function (props) {
  return props.appearance === "primary" && css(["input{border:1px solid ", ";}"], function (props) {
    return props.theme.colors.form.border.base;
  });
}, function (props) {
  return props.invalid && css(["input{border-color:", ";}"], function (props) {
    return props.theme.colors.form.border.error;
  });
}, function (props) {
  return props.warning && css(["input{border-color:", ";}"], function (props) {
    return props.theme.colors.form.border.warning;
  });
}, COMMON);
export var Accessory = styled.div.withConfig({
  displayName: "styles__Accessory",
  componentId: "sc-4stdun-1"
})(["position:absolute;top:50%;transform:translateY(-50%);color:", ";display:flex;align-items:center;", ";", ";"], function (props) {
  return props.theme.colors.icon.base;
}, function (props) {
  return props.before && css(["left:", ";"], props.theme.space[300]);
}, function (props) {
  return props.after && css(["right:", ";"], props.isClearButton ? 0 : props.theme.space[300]);
});
Container.displayName = "InputContainer";
Accessory.displayName = "InputAccessory";
export default Container;