function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { useState, useLayoutEffect } from "react";
var initialBounds = Object.freeze({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0
});
export function useMeasure(ref) {
  var _useState = useState(initialBounds),
    _useState2 = _slicedToArray(_useState, 2),
    bounds = _useState2[0],
    setContentRect = _useState2[1];
  useLayoutEffect(function () {
    var element = ref.current;
    if (!element ||
    // in non-browser environments (e.g. Jest tests) ResizeObserver is not defined
    !("ResizeObserver" in window)) {
      return;
    }
    var resizeObserver = new ResizeObserver(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
        entry = _ref2[0];
      var _entry$contentRect = entry.contentRect,
        x = _entry$contentRect.x,
        y = _entry$contentRect.y,
        width = _entry$contentRect.width,
        height = _entry$contentRect.height,
        top = _entry$contentRect.top,
        right = _entry$contentRect.right,
        bottom = _entry$contentRect.bottom,
        left = _entry$contentRect.left;
      setContentRect({
        x: x,
        y: y,
        width: width,
        height: height,
        top: top,
        right: right,
        bottom: bottom,
        left: left
      });
    });
    resizeObserver.observe(ref.current);
    return function () {
      resizeObserver.disconnect();
    };
  }, [ref]);
  return bounds;
}