import styled from "styled-components";
import Box from "../Box";
export var CollapsingBox = styled(Box).withConfig({
  displayName: "styles__CollapsingBox",
  componentId: "sc-1wvva09-0"
})(["transition:max-height ", " ", ";will-change:max-height;position:relative;overflow:auto;", ""], function (p) {
  return p.theme.duration.medium;
}, function (p) {
  return p.theme.easing.ease_inout;
}, function (_ref) {
  var hasShadow = _ref.hasShadow,
    scrollable = _ref.scrollable;
  return hasShadow ? "background:  /* Shadow covers */ linear-gradient(\n        transparent 30%,\n        rgba(255, 255, 255, 0)\n      ),\n      linear-gradient(rgba(255, 255, 255, 0), transparent 70%) 0 100%,\n      /* Shadows */\n        radial-gradient(\n          farthest-side at 50% 0,\n          rgb(39 51 51 / 5%),\n          rgba(0, 0, 0, 0)\n        ),\n      radial-gradient(\n          farthest-side at 50% 100%,\n          rgb(39 51 51 / 5%),\n          rgba(0, 0, 0, 0)\n        )\n        0 100%;\n    background-repeat: no-repeat;\n    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;\n    background-attachment: local, local, scroll, scroll;\n    ".concat(scrollable ? "overflow: auto" : "overflow: hidden", ";") : "";
});