import React from "react";
import Grid from "components/Grid";
import { Box } from "@sproutsocial/racine";

const ImageGrid = ({ images, ...rest }) => {
  return (
    <Grid
      {...rest}
      css={`
        figure {
          margin: 0;
        }
        img {
          margin: 0;
          min-height: 100%;
          object-fit: cover;
        }
        figcaption {
          color: ${(p) => p.theme.colors.neutral[700]};
          ${(p) => p.theme.typography[200]};
          font-style: italic;
          text-align: center;
          margin-top: ${(p) => p.theme.space[300]};
        }
      `}
    >
      {Object.keys(images).map((src, i) => {
        const image = images[src];

        return (
          <Box width={image.width} key={i}>
            <figure>
              <img src={src} alt={image.alt} title={image.title} />
              {image.caption && <figcaption>{image.caption}</figcaption>}
            </figure>
          </Box>
        );
      })}
    </Grid>
  );
};

export default ImageGrid;
