import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { visuallyHidden } from "@sproutsocial/seeds-react-mixins";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-10oduzm-0"
})(["position:relative;display:inline-block;padding:", ";color:currentColor;", " ", ""], function (props) {
  return props.theme.space[100];
}, function (props) {
  return props.icon === "chevron" && css(["transition:transform ", ";", ""], function (props) {
    return "".concat(props.theme.duration.medium, " ").concat(props.theme.easing.ease_inout);
  }, props.isOpen && css(["transform:rotateX(180deg);"]));
}, COMMON);
export var IconText = styled.div.withConfig({
  displayName: "styles__IconText",
  componentId: "sc-10oduzm-1"
})(["", ""], visuallyHidden);
export default Container;