import styled, { css } from "styled-components";
import { variant, space } from "styled-system";

const typeStyle = variant({
  key: "typography",
  prop: "typography"
});

const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  ${typeStyle}
  ${(p) =>
    p.bordered &&
    css`
      border-top: ${p.theme.borders[500]} ${p.theme.colors.neutral[300]};
    `}

  tr {
    border-bottom: ${(props) => props.theme.borders[500]}
      ${(props) => props.theme.colors.container.border.base};

    ${(p) =>
      p.light &&
      css`
        border-color: rgba(255, 255, 255, 0.5);
      `}
  }

  td,
  th {
    border: none;
  }

  thead {
    th,
    td {
      select {
        margin-left: ${(props) => props.theme.space[300]};
        vertical-align: middle;
      }
    }
  }

  pre {
    display: inline;
    word-wrap: break-word;
    margin: 0;
    padding: ${(props) => props.theme.space[200]};
    background: ${(props) => props.theme.colors.neutral[100]};
    border: ${(props) => props.theme.borders[500]}
      ${(props) => props.theme.colors.container.border.base};
    border-radius: ${(props) => props.theme.radii[500]};
  }

  ${space}
`;

Table.defaultProps = {
  typography: 300
};

export default Table;
