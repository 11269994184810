export const STATUSES = {
  healthy: {
    color: "green.700",
    bg: "green.0",
    hoverColor: "green.800",
    hoverBg: "green.100",
    description:
      "Healthy content is accurate and represents our most up-to-date recommendations."
  },
  withering: {
    color: "yellow.800",
    bg: "yellow.0",
    hoverColor: "yellow.900",
    hoverBg: "yellow.100",
    description:
      "Withering content has known issues, but is still generally recommended for use."
  },
  dormant: {
    color: "red.700",
    bg: "red.0",
    hoverColor: "red.800",
    hoverBg: "red.100",
    description:
      "Dormant content is no longer recommended for use, and is in need of foundational updates."
  }
};
