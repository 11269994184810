import uniqueId from "lodash.uniqueid";
export var asTokenSpec = function asTokenSpec(text) {
  return {
    id: uniqueId("".concat(text, "-")),
    value: text.trim()
  };
};
var KeyNameToRegExpChar = {
  Enter: "\\n"
};
export var delimitersAsRegExp = function delimitersAsRegExp(delimiters) {
  if (!delimiters) return /[,\n]/;
  var chars = delimiters.map(function (key) {
    return KeyNameToRegExpChar[key] || key;
  }).join("");
  return RegExp("[".concat(chars, "]"));
};