import styled from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-i0wj2q-0"
})(["display:inline-flex;color:", ";background-color:", ";border:1px solid ", ";border-radius:", ";box-shadow:", ";padding:0 ", ";min-width:20px;justify-content:center;", ""], function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.colors.container.background.base;
}, function (props) {
  return props.theme.colors.container.border.base;
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.shadows.low;
}, function (props) {
  return props.theme.space[200];
}, COMMON);
export default Container;