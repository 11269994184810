import styled, { css } from "styled-components";
import { COMMON, LAYOUT } from "@sproutsocial/seeds-react-system-props";
export var Label = styled.span.withConfig({
  displayName: "styles__Label",
  componentId: "sc-4jc00i-0"
})(["display:flex;align-items:center;color:", ";white-space:nowrap;text-transform:capitalize;"], function (props) {
  return props.theme.colors.text.subtext;
});
export var Swatch = styled.span.withConfig({
  displayName: "styles__Swatch",
  componentId: "sc-4jc00i-1"
})(["margin-right:", ";width:", ";height:", ";border-radius:4px;", ""], function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.theme.space[350];
}, function (props) {
  return props.theme.space[350];
}, COMMON);
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-4jc00i-2"
})(["", " ", " ", ""], function (props) {
  return props.inline && css(["display:flex;justify-content:center;", " + ", "{margin-left:", ";}"], Label, Label, function (props) {
    return props.theme.space[450];
  });
}, COMMON, LAYOUT);
Label.displayName = "ChartLegendLabel";
Swatch.displayName = "ChartLegendSwatch";
export default Container;