import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Link from "components/Link";
import { UnorderedList } from "components/Lists";
import media from "utils/media";
import Search from "components/Search";
import Box from "components/global/Box";
import { isLoggedIn, logout, isActuallyLoggedIn } from "utils/auth";
import BlogDropdown from "components/navigation/BlogDropdown";
import { useStaticQuery, graphql } from "gatsby";
import { capitalize, isProduction, isBrowserEnvironment } from "utils/js";
import { loginURL, getUserDetails } from "utils/auth";
import { Text, Icon, MenuButton, Menu } from "@sproutsocial/racine";
import CollaborationButton from "components/collaboration/Button";

const production = isProduction();

const NavContainer = styled.div`
  grid-column: right;
  padding: 0 ${(p) => p.theme.sizes.content.padding}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${media.large`
    overflow-x: auto;
  `}

  ${media.medium`
    padding-right: 0;
    padding-left: 0;
    overflow-x: auto;
  `}

  a {
    white-space: nowrap;
  }
`;

const Nav = styled(UnorderedList).attrs({
  className: "nav"
})`
  max-width: ${(props) => props.theme.sizes.content.maxWidth}px;
  display: flex;
  align-items: stretch;
  height: ${(props) => props.theme.sizes.header.height}px;
  grid-column: 2 / span 5;

  margin-left: -${(props) => props.theme.space[400]};

  & > * {
    cursor: pointer;
    padding-left: ${(props) => props.theme.space[400]};
    padding-right: ${(props) => props.theme.space[400]};
  }

  & li:last-child {
    ${media.small`
      padding-right: 24px;
    `};
  }

  ${media.medium`
    grid-column: 1 / span 6;

    ${(props) => props.flush && `margin-left: ${props.theme.space[450]};`}
  `}
`;

const MenuItem = styled.li`
  position: relative;
  display: inline;
  margin: 0;

  a {
    font-size: inherit;
    color: ${(props) => props.theme.colors.neutral[1000]};
    font-weight: ${(p) => p.theme.fontWeights.bold};
    height: 100%;
    display: flex;
    align-items: center;
  }

  a.selected,
  a:hover {
    text-decoration: none;
  }

  a[isselected="true"]:before {
    content: "";
    position: absolute;
    background: ${(p) => p.theme.colors.blue[1100]};
    height: 4px;
    width: calc(100% - 32px);
    top: calc(100% - 4px);
  }

  ${(p) =>
    p.hovered &&
    `
    &:before {
      content: "";
      position: absolute;
      background: ${p.theme.colors.blue[1100]};
      height: 4px;
      width: calc(100% - 32px);
      top: calc(100% - 4px);
    }
  `}
`;

const HeaderNav = (props) => {
  const { categories } = useStaticQuery(graphql`
    query HeaderQuery {
      categories: allCategory {
        nodes {
          name
          slug
          private
        }
      }
    }
  `);

  const [loggedIn, setLoggedIn] = useState(false);
  const [isBrowser, setIsBrowser] = useState(false);
  const user = getUserDetails();

  useEffect(() => {
    // Moving these checks into useEffect to avoid hydration issues
    setLoggedIn(isLoggedIn());
    setIsBrowser(isBrowserEnvironment);
  }, []);

  if (!isBrowser) return null;

  return (
    <NavContainer>
      <Nav m={0} p={0}>
        {categories.nodes.map((category) => {
          const hidden = category.private && !loggedIn;
          if (hidden) return null;

          return (
            <MenuItem
              key={category.name}
              onMouseOver={props.onMouseOver}
              onFocus={props.onMouseOver}
              hovered={props.hoveredItem === category.name}
            >
              <Link to={category.slug + "/"}>{capitalize(category.name)}</Link>
            </MenuItem>
          );
        })}
      </Nav>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        ml={[400, 400, 400, 400, 600]}
        pr={[600, 600, 600, 0]}
      >
        <CollaborationButton mr={450} />

        <Link to="/updates" color="neutral.700" typography={200} mr={400}>
          Updates
        </Link>

        {loggedIn && (
          <Link to="/resources" color="neutral.700" typography={200} mr={400}>
            Resources
          </Link>
        )}

        {!loggedIn && (
          <>
            <Link
              to="https://sproutsocial.com/careers"
              color="neutral.700"
              css={(p) => p.theme.typography[200]}
              mr={350}
            >
              Careers
            </Link>

            <BlogDropdown mr={450} />
          </>
        )}

        {(!production && !isActuallyLoggedIn()) || (production && !loggedIn) ? (
          <Text
            as="a"
            href={loginURL()}
            color="neutral.700"
            fontSize={200}
            mr={400}
          >
            <Icon name="slack" mr={200} mt="-1px" />
            Log in
          </Text>
        ) : (
          <MenuButton
            appearance="pill"
            content={
              <Menu width="200px">
                <Menu.Group title={user ? user.name : null}>
                  <Menu.Item onClick={logout}>Log out</Menu.Item>
                </Menu.Group>
              </Menu>
            }
            popoutProps={{
              zIndex: 99999
            }}
            aria-label="User settings"
          >
            <Icon name="user-sprout-outline" color="neutral.600" />
          </MenuButton>
        )}

        <Search />
      </Box>
    </NavContainer>
  );
};

export default HeaderNav;
