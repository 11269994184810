import React from "react";
import styled from "styled-components";
import Grid from "components/Grid";
import Box from "components/global/Box";
import media from "utils/media";

const Container = styled(Box)`
  background: ${(p) => p.theme.colors.neutral[0]};
  position: absolute;
  top: 80px;
  left: ${(p) => p.theme.sizes.sidebar.minWidth}px;
  right: 0;

  .no-sidebar & {
    left: 0;
  }

  ${media.medium`
    left: 0;
  `}

  a {
    ${(p) => p.theme.typography[300]}
    color: ${(p) => p.theme.colors.neutral[800]};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 1px;
    z-index: -1;
    box-shadow: 0px 0px 12px 2px ${(p) => p.theme.colors.neutral[700]};
  }

  li + li {
    margin-top: ${(p) => p.theme.space[300]};
  }
`;

const Content = styled.div`
  max-width: ${(props) => props.theme.sizes.content.maxWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: ${(p) => `${p.theme.space[600]} ${p.theme.sizes.content.padding}px`};
`;

const NavDropdown = (props) => {
  return (
    <Container {...props} id="header-menu">
      <Content>
        <Grid>{props.children}</Grid>
      </Content>
    </Container>
  );
};

export default NavDropdown;
