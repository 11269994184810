import React from "react";
import { Stack, Text, Icon, Box } from "@sproutsocial/racine";

const PlannedEnhancements = ({ data, ...rest }) => {
  if (!data || data.length < 1) return null;

  return (
    <Stack>
      <Text as="div" color="text.headline" fontWeight="semibold" fontSize={200}>
        <Icon name="circle-i-outline" mr={300} mt={-200} color="icon.info" />
        Planned enhancements
      </Text>

      {data.map((item, i) => (
        <Box
          borderTop={i !== 0 ? 500 : null}
          borderColor="container.border.base"
          pt={i !== 0 ? 300 : 0}
          key={i}
        >
          <Text color="text.body">{item}</Text>
        </Box>
      ))}
    </Stack>
  );
};

export default PlannedEnhancements;
