import React from "react";
import YouTubePlayer from "react-youtube";

const Youtube = (props) => (
  <div className="medium">
    <YouTubePlayer
      videoId={props.id}
      opts={{
        height: 500
      }}
    />
  </div>
);

export default Youtube;
