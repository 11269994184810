import styled from "styled-components";
import Box from "../Box";
export var StyledTooltipContent = styled(Box).withConfig({
  displayName: "styles__StyledTooltipContent",
  componentId: "sc-19u9ha6-0"
})(["font-family:", ";", " text-align:", ";"], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.appearance === "box" ? "left" : "center";
});