import * as React from "react";
import styled, { css } from "styled-components";
import Box from "@sproutsocial/seeds-react-box";
import PartnerLogo from "@sproutsocial/seeds-react-partner-logo";
import { includes } from "@sproutsocial/seeds-react-utilities";
import { LogoNamesWithoutVariants as PartnerLogoNames } from "@sproutsocial/seeds-partner-logos";

import type { TypeIconProps, TypeToggleProps } from "./IconTypes";
import Container from "./styles";
import {
  ExternalViewBoxes,
  GeneralViewBoxes,
  SproutViewBoxes,
  ExternalIconNames,
  type EnumIconSvgNames,
  type EnumIconNamesWithoutVariants,
} from "@sproutsocial/seeds-icons";

const AllViewboxes = {
  ...ExternalViewBoxes,
  ...GeneralViewBoxes,
  ...SproutViewBoxes,
};

const Icon = ({
  name,
  size = "small",
  fixedWidth = false,
  ariaLabel,
  color,
  svgProps,
  ...rest
}: TypeIconProps) => {
  if (includes(PartnerLogoNames, name)) {
    // Icon's "default" size is equivalent to PartnerLogo's "small" size
    const logoSize = size === "default" ? "small" : size;
    const logoProps = {
      partnerName: name,
      size: logoSize,
      logoType: "symbol" as const,
      svgProps,
    };
    return (
      <PartnerLogo
        // ariaLabel needs to be overridable by aria-label prop in ...rest
        aria-label={ariaLabel}
        {...rest}
        {...logoProps}
      />
    );
  }

  const defaultVariant = size === "mini" ? "solid" : "outline";

  const iconName: EnumIconSvgNames =
    // if not external and has no variant
    !name?.endsWith("-outline") &&
    !name?.endsWith("-solid") &&
    !includes(ExternalIconNames, name)
      ? // then add default variant
        `${name as EnumIconNamesWithoutVariants}-${defaultVariant}`
      : // else use name as is
        (name as EnumIconSvgNames);

  const iconViewBox = AllViewboxes[iconName];
  return (
    <Container
      iconSize={size}
      fixedWidth={!!fixedWidth}
      key={iconName}
      role="img"
      aria-label={ariaLabel}
      data-qa-icon={iconName}
      color={color}
      {...rest}
    >
      <svg
        className="Icon-svg"
        viewBox={iconViewBox}
        focusable={false}
        data-qa-icon-svg={`${iconName}-svg`}
        {...svgProps}
      >
        <use
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xlinkHref={`#seeds-svgs_${iconName}`}
        />
      </svg>
    </Container>
  );
};

const ToggleableIcon = styled(Icon)<{ active: boolean }>`
  transition: all ${(p) => p.theme.duration.fast} linear;

  ${(p) =>
    p.active &&
    css`
      opacity: 1;
      transform: scale(1);
    `}

  ${(p) =>
    !p.active &&
    css`
      position: absolute;
      opacity: 0;
      transform: scale(0);
    `}
`;

const IconToggle = ({
  activeName,
  inactiveName,
  isActive,
  size = "small",
  fixedWidth = false,
  ariaLabel,
  ...rest
}: TypeToggleProps) => {
  return (
    <Box as="span" position="relative" display="inline-flex" {...rest}>
      <ToggleableIcon
        active={isActive}
        name={activeName}
        size={size}
        fixedWidth={fixedWidth}
        aria-label={ariaLabel}
        aria-hidden={!isActive}
      />
      <ToggleableIcon
        active={!isActive}
        name={inactiveName}
        size={size}
        fixedWidth={fixedWidth}
        aria-label={ariaLabel}
        aria-hidden={isActive}
      />
    </Box>
  );
};

IconToggle.displayName = "Icon.Toggle";
/**
 * **Accessibility note:** It is best practice to wrap `<Icon.Toggle />` in a button. The button must include `aria-label` and `aria-pressed` in order for a screen reader to properly communicate the icon's state. See example below.
 *
 * @link https://www.w3.org/TR/wai-aria-practices-1.1/#button
 * @example
 * const [toggleState, setToggleState] = useState(false);
 * <Button  // Wrap Icon.Toggle with Button
 *  appearance='pill'
 *  aria-label='like' // required for accessibility
 *  aria-pressed={toggleState} // required for accessibility
 *  onClick={() => setToggleState(!toggleState)}
 * >
 *  <Icon.Toggle
 *    activeName="heart-solid"
 *    inactiveName="heart-outline"
 *    isActive={toggleState}
 *   />
 * </Button>
 */
Icon.Toggle = IconToggle;

export default Icon;
