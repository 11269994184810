import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import Box from "../Box";
export var Content = styled(Box).withConfig({
  displayName: "styles__Content",
  componentId: "sc-1nfgsze-0"
})(["overflow-y:auto;"]);
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1nfgsze-1"
})(["display:flex;flex-direction:column;position:fixed;top:0;height:100%;width:", "px;background-color:", ";box-shadow:", ";filter:blur(0);", " ", ""], function (props) {
  return props.width;
}, function (props) {
  return props.theme.colors.container.background.base;
}, function (props) {
  return props.theme.shadows.high;
}, function (props) {
  return css(["", ":", "px;"], props.direction, props.offset);
}, COMMON);
export default Container;