import styled, { css } from "styled-components";
import { COMMON, LAYOUT, FLEXBOX, GRID, TYPOGRAPHY } from "@sproutsocial/seeds-react-system-props";
var Container = styled.span.withConfig({
  displayName: "styles__Container",
  componentId: "sc-99cuku-0"
})(["margin:0;padding-left:0;padding-right:0;font-family:", ";font-style:", ";", " ", " ", " ", " ", " ", " ", ""], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return props.isItalicized && "italic";
}, function (props) {
  return props.truncated && css(["display:block;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"]);
}, function (props) {
  return props.breakWord && css(["word-break:break-word;hyphens:auto;"]);
}, COMMON, LAYOUT, FLEXBOX, GRID, TYPOGRAPHY);
export default Container;