'use strict';

module.exports = {
	MOTION_EASE_IN: 'cubic-bezier(.4, 0, .7, .2)',
	MOTION_EASE_OUT: 'cubic-bezier(0, 0, .2, 1)',
	MOTION_EASE_INOUT: 'cubic-bezier(.4, 0, .2, 1)',
	MOTION_DURATION_FAST: 0.15,
	MOTION_DURATION_MEDIUM: 0.3,
	MOTION_DURATION_SLOW: 0.6
};
