import styled, { css } from "styled-components";
import { COMMON, LAYOUT, FLEXBOX, GRID } from "@sproutsocial/seeds-react-system-props";
import { focusRing, disabled, pill } from "@sproutsocial/seeds-react-mixins";
import { IconContainer } from "@sproutsocial/seeds-react-icon";
import Icon from "../Icon/styles";
var Container = styled.button.withConfig({
  displayName: "styles__Container",
  componentId: "sc-17njgx1-0"
})(["display:inline-block;box-sizing:border-box;text-align:center;font-family:", ";border:1px solid ", ";border-radius:", ";border-style:", ";background:", ";color:", ";cursor:pointer;text-decoration:none;line-height:16px;white-space:nowrap;font-weight:", ";transition:all ", " linear;margin:0;padding:", ";font-size:", ";&:visited{color:", ";}&:hover{color:", ";", ";text-decoration:none;box-shadow:", ";}&:active{color:", ";", ";transform:translateY(1px);}&:focus{", "}&:focus:active{box-shadow:none;}", " ", " ", " ", ",", "{vertical-align:text-bottom;}", " ", " ", " ", ""], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.colors.button[props.appearance].border.base;
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.appearance === "placeholder" ? "dashed" : "solid";
}, function (props) {
  return props.theme.colors.button[props.appearance].background.base;
}, function (props) {
  return props.theme.colors.button[props.appearance].text.base;
}, function (props) {
  return props.theme.fontWeights.bold;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.buttonSize === "default" ? "".concat(props.theme.space[300]) : "".concat(props.theme.space[350]);
}, function (props) {
  return props.buttonSize === "default" ? props.theme.typography[200].fontSize : props.theme.typography[300].fontSize;
}, function (props) {
  return props.theme.colors.button[props.appearance].text.base;
}, function (props) {
  return props.theme.colors.button[props.appearance].text.hover;
}, function (props) {
  return props.appearance !== "unstyled" && "background: ".concat(props.theme.colors.button[props.appearance].background.hover);
}, function (props) {
  return props.appearance === "placeholder" ? props.theme.shadows.low : "none";
}, function (props) {
  return props.theme.colors.button[props.appearance].text.hover;
}, function (props) {
  return props.appearance !== "unstyled" && "background: ".concat(props.theme.colors.button[props.appearance].background.active);
}, focusRing, function (props) {
  return props.active && css(["color:", " !important;", ";"], props.theme.colors.button[props.appearance].text.hover, props.appearance !== "unstyled" && "background: ".concat(props.theme.colors.button[props.appearance].background.active, " !important"));
}, function (props) {
  return props.disabled && disabled;
}, function (props) {
  return props.appearance === "pill" && css(["display:inline-flex;align-items:center;justify-content:center;background-color:transparent;", " &:focus:active,&:hover{background-color:transparent;box-shadow:inset 0px 0px 0px 1px ", ";}"], pill, props.theme.colors.button.pill.border.hover);
}, IconContainer, Icon, LAYOUT, COMMON, FLEXBOX, GRID);
Container.displayName = "Button-Container";
export default Container; //${props.theme.mode === "dark" ? "screen" : "multiply"}