import { theme } from "@sproutsocial/racine";

const profiles = [
  {
    icon: {
      name: "twitter",
      color: theme.colors.network.twitter
    },
    profile: "@15charactername",
    uId: "1"
  },
  {
    icon: {
      name: "twitter",
      color: theme.colors.network.twitter
    },
    profile: "@15charactername",
    uId: "2"
  },
  {
    icon: {
      name: "twitter",
      color: theme.colors.network.twitter
    },
    profile: "@15charactername",
    uId: "3"
  },
  {
    icon: {
      name: "twitter",
      color: theme.colors.network.twitter
    },
    profile: "@15charactername",
    uId: "4"
  },
  {
    icon: {
      name: "facebook",
      color: theme.colors.network.facebook
    },
    profile: "15charactername",
    uId: "5"
  },
  {
    icon: {
      name: "facebook",
      color: theme.colors.network.facebook
    },
    profile: "15charactername",
    uId: "6"
  },
  {
    icon: {
      name: "facebook",
      color: theme.colors.network.facebook
    },
    profile: "15charactername",
    uId: "7"
  },
  {
    icon: {
      name: "pinterest",
      color: theme.colors.network.pinterest
    },
    profile: "15charactername",
    uId: "8"
  },
  {
    icon: {
      name: "pinterest",
      color: theme.colors.network.pinterest
    },
    profile: "15charactername",
    uId: "9"
  },
  {
    icon: {
      name: "pinterest",
      color: theme.colors.network.pinterest
    },
    profile: "15charactername",
    uId: "10"
  },
  {
    icon: {
      name: "pinterest",
      color: theme.colors.network.pinterest
    },
    profile: "15charactername",
    uId: "11"
  },
  {
    icon: {
      name: "pinterest",
      color: theme.colors.network.pinterest
    },
    profile: "15charactername",
    uId: "12"
  }
];

export default profiles;
