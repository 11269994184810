import React from "react";
import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";
import Text from "components/global/Text";

const StyledLink = Text.withComponent("a");

const Container = styled(StyledLink)`
  font-size: inherit;
`;

const StyledGatsbyLink = StyledLink.withComponent(GatsbyLink);

const isActive = ({ isCurrent, isPartiallyCurrent }) => {
  return {
    isselected: isPartiallyCurrent ? "true" : undefined,
    isactive: isCurrent ? "true" : undefined
  };
};

const Link = ({ children, to, external = false, ...rest }) => {
  const newTab = to.startsWith("http") || external;
  const internal = /^\/(?!\/)/.test(to);

  if (!internal || external) {
    return (
      <Container
        href={to}
        target={newTab ? `_blank` : undefined}
        rel={newTab ? "noopener" : undefined}
        {...rest}
      >
        {children}
      </Container>
    );
  }

  return (
    <StyledGatsbyLink to={to} getProps={isActive} {...rest}>
      {children}
    </StyledGatsbyLink>
  );
};

export default Link;
