import React from "react";
import { UnorderedList as List } from "components/Lists";
import DirectivesRenderer, {
  shouldRenderDirectives
} from "components/mdx/Directives";

const UnorderedList = ({ children }) => {
  if (shouldRenderDirectives(children)) {
    return <DirectivesRenderer>{children}</DirectivesRenderer>;
  }

  return <List>{children}</List>;
};

export default UnorderedList;
