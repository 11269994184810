import { ElementRef } from "react";

type TypeRef =
  | {
      current: null | HTMLInputElement;
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | ((arg0: ElementRef<any> | HTMLElement) => void);

// Allows a component to pass its own ref *and* a ref prop to the same element.
// Via https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
export const mergeRefs = (refs: (TypeRef | null)[]) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (inst: any) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};
