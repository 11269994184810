import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import Button from "../Button";
var Container = styled.ul.withConfig({
  displayName: "styles__Container",
  componentId: "sc-sgxsgw-0"
})(["display:", ";justify-content:space-between;margin:0;padding:0;list-style:none;border-bottom:", " ", ";", ""], function (props) {
  return props.fullWidth ? "flex" : "inline-flex";
}, function (props) {
  return props.theme.borders[500];
}, function (props) {
  return props.theme.colors.container.border.base;
}, COMMON);
export var TabItem = styled.li.withConfig({
  displayName: "styles__TabItem",
  componentId: "sc-sgxsgw-1"
})(["margin-bottom:-1px;", ";&:not(:last-child){", ";}", ";&:hover{", ";}"], function (props) {
  return props.fullWidth && css(["flex-grow:1;"]);
}, function (props) {
  return !props.fullWidth && css(["margin-right:", ";"], function (props) {
    return props.theme.space[350];
  });
}, function (props) {
  return props.isSelected && css(["box-shadow:", ";"], function (props) {
    return "inset 0 -3px 0 0 ".concat(props.theme.colors.button.primary.background.base);
  });
}, function (props) {
  return props.isSelected && css(["box-shadow:", ";"], function (props) {
    return "inset 0 -3px 0 0 ".concat(props.theme.colors.button.primary.background.hover);
  });
});
export var TabButton = styled(Button).withConfig({
  displayName: "styles__TabButton",
  componentId: "sc-sgxsgw-2"
})(["padding:", ";color:", ";width:100%;", ";&:hover{", ";}&:active{transform:none;}"], function (props) {
  return "".concat(props.theme.space[350], " 0");
}, function (props) {
  return props.theme.colors.text.headline;
}, function (props) {
  return props.isSelected && css(["color:", ";"], function (props) {
    return props.theme.colors.link.base;
  });
}, function (props) {
  return props.isSelected && css(["color:", ";"], function (props) {
    return props.theme.colors.link.hover;
  });
});
export default Container;