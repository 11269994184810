import React, { useState } from "react";
import { MenuButton, Text } from "@sproutsocial/racine";
import CollaborationMenu from "components/collaboration/Menu";
import IntakeDrawer from "components/collaboration/Drawer";
import COLLABORATION_TYPES from "./utils/collaboration-types";
import { isLoggedIn } from "utils/auth";
import sprig from "./images/sprig.svg";
import { trackEvent } from "utils/js";

const ContributeButton = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const toggleDrawer = () => setDrawerIsOpen(!drawerIsOpen);
  const [selectedType, setSelectedType] = useState(undefined);

  const handleSelection = (type) => {
    setSelectedType(type);
    setDrawerIsOpen(true);
  };

  if (!isLoggedIn()) {
    return null;
  }

  return (
    <React.Fragment>
      <MenuButton
        id="collaboration-button"
        px={400}
        appearance="placeholder"
        content={<CollaborationMenu onChange={handleSelection} />}
        popoutProps={{
          onOpen: () => {
            if (drawerIsOpen) {
              setDrawerIsOpen(false);
              setTimeout(() => {
                setSelectedType(undefined);
              }, 800);
            }

            trackEvent("Open collaboration menu", {
              category: "Collaboration"
            });
          },
          zIndex: 99999
        }}
        display="flex"
        alignItems="center"
        css="border-radius: 9999px; min-width: 132px;"
        {...props}
      >
        <img
          src={sprig}
          alt=""
          css={`
            width: 20px;
            margin: 0;
          `}
        />
        <Text ml={300}>Contribute</Text>
      </MenuButton>

      <IntakeDrawer
        type={selectedType}
        title={COLLABORATION_TYPES[selectedType]?.title}
        isOpen={drawerIsOpen}
        onClose={toggleDrawer}
        key={selectedType}
      />
    </React.Fragment>
  );
};

export default ContributeButton;
