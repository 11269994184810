exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brand-assets-hero-illustration-jsx": () => import("./../../../src/pages/brand-assets/HeroIllustration.jsx" /* webpackChunkName: "component---src-pages-brand-assets-hero-illustration-jsx" */),
  "component---src-pages-brand-assets-index-js": () => import("./../../../src/pages/brand-assets/index.js" /* webpackChunkName: "component---src-pages-brand-assets-index-js" */),
  "component---src-pages-brand-index-mdx": () => import("./../../../src/pages/brand/index.mdx" /* webpackChunkName: "component---src-pages-brand-index-mdx" */),
  "component---src-pages-components-index-mdx": () => import("./../../../src/pages/components/index.mdx" /* webpackChunkName: "component---src-pages-components-index-mdx" */),
  "component---src-pages-contribute-index-js": () => import("./../../../src/pages/contribute/index.js" /* webpackChunkName: "component---src-pages-contribute-index-js" */),
  "component---src-pages-craft-index-js": () => import("./../../../src/pages/craft/index.js" /* webpackChunkName: "component---src-pages-craft-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-callback-mjs": () => import("./../../../src/pages/login/callback.mjs" /* webpackChunkName: "component---src-pages-login-callback-mjs" */),
  "component---src-pages-login-index-js": () => import("./../../../src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */),
  "component---src-pages-patterns-index-mdx": () => import("./../../../src/pages/patterns/index.mdx" /* webpackChunkName: "component---src-pages-patterns-index-mdx" */),
  "component---src-pages-resources-index-js": () => import("./../../../src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-resources-tokens-index-js": () => import("./../../../src/pages/resources/tokens/index.js" /* webpackChunkName: "component---src-pages-resources-tokens-index-js" */),
  "component---src-pages-updates-index-js": () => import("./../../../src/pages/updates/index.js" /* webpackChunkName: "component---src-pages-updates-index-js" */),
  "component---src-pages-visual-index-mdx": () => import("./../../../src/pages/visual/index.mdx" /* webpackChunkName: "component---src-pages-visual-index-mdx" */),
  "component---src-pages-writing-index-mdx": () => import("./../../../src/pages/writing/index.mdx" /* webpackChunkName: "component---src-pages-writing-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-avatar-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/avatar/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-avatar-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-avatar-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/avatar/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-avatar-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-badge-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/badge/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-badge-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-banner-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/banner/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-banner-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-box-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/box/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-box-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-box-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/box/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-box-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-breadcrumb-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/breadcrumb/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-breadcrumb-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-breadcrumb-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/breadcrumb/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-breadcrumb-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-button-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/button/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-button-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-button-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/button/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-button-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-card-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/card/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-card-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-changelog-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/changelog/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-changelog-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-character-counter-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/character-counter/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-character-counter-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-character-counter-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/character-counter/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-character-counter-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-chart-legend-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/chart-legend/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-chart-legend-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-chart-legend-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/chart-legend/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-chart-legend-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-checkbox-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/checkbox/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-checkbox-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-checkbox-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/checkbox/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-checkbox-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-collapsible-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/collapsible/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-collapsible-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-date-picker-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/date-picker/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-date-picker-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-date-picker-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/date-picker/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-date-picker-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-drawer-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/drawer/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-drawer-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-empty-state-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/empty-state/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-empty-state-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-empty-state-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/empty-state/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-empty-state-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-fieldset-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/fieldset/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-fieldset-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-form-field-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/form-field/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-form-field-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-getting-started-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/getting-started/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-getting-started-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-icon-add-icon-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/icon/add-icon.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-icon-add-icon-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-icon-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/icon/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-icon-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-icon-search-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/icon/search.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-icon-search-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-icon-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/icon/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-icon-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-image-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/image/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-image-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-indicator-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/indicator/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-indicator-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-indicator-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/indicator/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-indicator-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-input-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/input/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-input-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-input-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/input/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-input-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-keyboard-key-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/keyboard-key/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-keyboard-key-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-label-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/label/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-label-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-label-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/label/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-label-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-link-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/link/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-link-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-link-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/link/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-link-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-listbox-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/listbox/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-listbox-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-loader-button-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/loader-button/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-loader-button-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-loader-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/loader/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-loader-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-loader-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/loader/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-loader-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-menu-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/menu/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-menu-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-message-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/message/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-message-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-message-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/message/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-message-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-migration-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/migration/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-migration-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-modal-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/modal/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-modal-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-modal-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/modal/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-modal-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-numeral-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/numeral/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-numeral-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-partner-logo-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/partner-logo/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-partner-logo-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-popout-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/popout/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-popout-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-popout-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/popout/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-popout-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-radio-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/radio/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-radio-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-radio-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/radio/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-radio-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-segmented-control-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/segmented-control/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-segmented-control-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-select-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/select/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-select-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-select-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/select/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-select-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-skeleton-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/skeleton/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-skeleton-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-skeleton-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/skeleton/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-skeleton-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-spot-illustration-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/spot-illustration/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-spot-illustration-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-stack-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/stack/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-stack-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-switch-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/switch/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-switch-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-switch-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/switch/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-switch-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-system-props-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/system-props/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-system-props-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-table-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/table/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-table-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-tabs-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/tabs/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-tabs-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-tabs-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/tabs/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-tabs-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-text-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/text/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-text-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-textarea-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/textarea/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-textarea-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-textarea-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/textarea/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-textarea-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-theme-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/theme/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-theme-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-toast-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/toast/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-toast-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-toast-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/toast/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-toast-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-token-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/token/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-token-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-token-input-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/token-input/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-token-input-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-tooltip-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/tooltip/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-tooltip-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-tooltip-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/tooltip/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-tooltip-usage-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-utilities-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/utilities/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-utilities-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-visually-hidden-index-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/visually-hidden/index.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-visually-hidden-index-mdx" */),
  "component---src-templates-component-js-content-file-path-docs-components-visually-hidden-usage-mdx": () => import("./../../../src/templates/component.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/components/visually-hidden/usage.mdx" /* webpackChunkName: "component---src-templates-component-js-content-file-path-docs-components-visually-hidden-usage-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-brand-personality-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/brand/personality/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-brand-personality-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-brand-tenets-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/brand/tenets/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-brand-tenets-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-brand-values-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/brand/values/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-brand-values-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-accessibility-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/accessibility/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-accessibility-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-charts-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/charts/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-charts-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-contextual-messaging-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/contextual-messaging/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-contextual-messaging-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-data-states-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/data-states/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-data-states-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-dataviz-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/dataviz/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-dataviz-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-date-and-time-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/date-and-time/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-date-and-time-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-filters-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/filters/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-filters-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-forms-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/forms/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-forms-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-in-app-guide-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/in-app-guide/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-in-app-guide-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-layout-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/layout/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-layout-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-message-feeds-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/message-feeds/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-message-feeds-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-navigation-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/navigation/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-navigation-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-notifications-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/notifications/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-notifications-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-onboarding-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/onboarding/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-onboarding-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-source-tables-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/source-tables/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-source-tables-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-patterns-summary-tables-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/patterns/summary-tables/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-patterns-summary-tables-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-border-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/border/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-border-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-brand-typography-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/brand-typography/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-brand-typography-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-color-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/color/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-color-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-dataviz-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/dataviz/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-dataviz-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-elevation-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/elevation/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-elevation-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-frame-library-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/frame-library/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-frame-library-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-illustration-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/illustration/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-illustration-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-investor-relations-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/investor-relations/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-investor-relations-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-logo-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/logo/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-logo-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-motion-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/motion/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-motion-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-photography-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/photography/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-photography-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-product-typography-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/product-typography/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-product-typography-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-space-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/space/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-space-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-visual-video-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/visual/video/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-visual-video-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-avoiding-cliches-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/avoiding-cliches/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-avoiding-cliches-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-click-worthy-writing-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/click-worthy-writing/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-click-worthy-writing-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-faq-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/faq/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-faq-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-grammar-and-mechanics-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/grammar-and-mechanics/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-grammar-and-mechanics-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-humor-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/humor/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-humor-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-inclusive-language-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/inclusive-language/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-inclusive-language-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-tech-comm-guidelines-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/tech-comm-guidelines/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-tech-comm-guidelines-index-mdx" */),
  "component---src-templates-page-js-content-file-path-docs-writing-voice-and-tone-index-mdx": () => import("./../../../src/templates/page.js?__contentFilePath=/opt/build/repo/apps/reference-site/docs/writing/voice-and-tone/index.mdx" /* webpackChunkName: "component---src-templates-page-js-content-file-path-docs-writing-voice-and-tone-index-mdx" */)
}

