import styled from "styled-components";
import CopyContent from "components/CopyContent";
import Link from "components/Link";
import { Text } from "@sproutsocial/racine";

const Container = styled(CopyContent)`
  display: flex;
  align-items: center;
  border-radius: ${(p) => p.theme.radii[600]};
  border: ${(p) => p.theme.borders[500]} ${(p) => p.theme.colors.neutral[200]}};
  padding: ${(p) => p.theme.space[200]} ${(p) => p.theme.space[300]};
  ${(p) => p.theme.typography[200]};
  color: ${(p) => p.theme.colors.text.body};
  text-align: center;
  background: ${(p) => p.theme.colors.neutral[0]};
`;

const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  border-radius: ${(p) => p.theme.radii[600]};
  border: ${(p) => p.theme.borders[500]} ${(p) => p.theme.colors.neutral[200]};
  padding: ${(p) => p.theme.space[200]} ${(p) => p.theme.space[300]};
  ${(p) => p.theme.typography[200]};
  text-align: center;
  text-transform: capitalize;
`;

const Headline = styled(Text)`
  text-transform: capitalize;
`;

export { Container, LinkContainer, Headline };
