import React from "react";
import Link from "components/Link";
import Box from "components/global/Box";
import { UnorderedList } from "components/Lists";
import NavHeading from "components/navigation/NavHeading";

const WritingMenu = (props) => {
  return (
    <>
      <Box width={[1 / 3]}>
        <NavHeading>Foundation</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/writing/voice-and-tone">Voice and tone</Link>
          </li>
          <li>
            <Link to="/writing/faq">FAQ</Link>
          </li>
        </UnorderedList>
      </Box>

      <Box width={[1 / 3]}>
        <NavHeading>Specific guidelines</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/writing/grammar-and-mechanics">
              Grammar and mechanics
            </Link>
          </li>
          <li>
            <Link to="/writing/humor">Humor</Link>
          </li>
          <li>
            <Link to="/writing/avoiding-cliches">Avoiding clichés</Link>
          </li>
          <li>
            <Link to="/writing/click-worthy-writing">Click-worthy writing</Link>
          </li>
          <li>
            <Link to="/writing/tech-comm-guidelines">
              Technical communications
            </Link>
          </li>
          <li>
            <Link to="/writing/inclusive-language">Inclusive language</Link>
          </li>
        </UnorderedList>
      </Box>
    </>
  );
};

export default WritingMenu;
