import newIllustration from "../images/new.svg";
import oneOffIllustration from "../images/one-off.svg";
import noneIllustration from "../images/none.svg";
import devIllustration from "../images/dev.svg";
import designIllustration from "../images/design.svg";

const COLLABORATION_TYPES = {
  // recipe: {
  //   title: 'Suggest a new recipe',
  //   description: 'Let us know about a new or missing use case.',
  //   icon: 'comment-outline',
  //   color: 'purple.700',
  // },
  issue: {
    title: "Report an issue",
    description: "Something seems off? We can help you fix it.",
    icon: "triangle-exclamation-outline",
    color: "icon.error",
    success: {
      title: "Thanks, we’re on it!",
      description:
        "What happens next? The Design Systems team has received your request, and will review it as a team. We’ll reach out if we need any more information. Here are how things could go from there:",
      outcomes: [
        {
          title: "Design issue",
          description:
            "If the team determines that solving this issue requires new design considerations, we will work with the design teams to identify a solution. We’ll be in touch once we have decided on an approach.",
          image: designIllustration
        },
        {
          title: "Dev issue",
          description:
            "If this issue does not require any new design considerations, we will work to fix the issue as soon as possible or identify a contributor who can help us fix it.",
          image: devIllustration
        },
        {
          title: "No work needed",
          description:
            "The Design Systems team may identify an alternate solution that doesn’t require a change to the system, or we may determine that this is working as expected. We’ll let you know if this is the case, and work with your team to adjust accordingly.",
          image: noneIllustration
        }
      ]
    }
  },
  component: {
    title: "Suggest a new component",
    description: "Tell us about a component you’d like to see.",
    icon: "atom-outline",
    color: "blue.500",
    success: {
      title: "Thanks, we’re on it!",
      description:
        "What happens next? The Design Systems team has received your request, and will review it as a team. We’ll reach out if we need any more information. Here are how things could go from there:",
      outcomes: [
        {
          title: "Something new",
          description:
            "If the team determines that this component belongs in our design system, we’ll add it to our roadmap or work with you and your team to bring it to life.",
          image: newIllustration
        },
        {
          title: "One-off",
          description:
            "If this doesn’t belong at the system level, it might be appropriate to introduce a custom component for this need. We can always pull it into the system later if we need to.",
          image: oneOffIllustration
        },
        {
          title: "No work needed",
          description:
            "The team may identify an alternate solution that doesn’t require a change to the system. We’ll let you know if this is the case, and work with your team to adjust accordingly.",
          image: noneIllustration
        }
      ]
    }
  },
  pattern: {
    title: "Suggest a new guideline",
    description: "Share your expertise with Sprout.",
    icon: "book-outline",
    color: "green.600",
    success: {
      title: "Thanks, we’re on it!",
      description:
        "What happens next? The Design Systems team has received your request, and will review it as a team. We’ll reach out if we need any more information. Here are how things could go from there:",
      outcomes: [
        {
          title: "Something new",
          description:
            "If the team determines that this pattern belongs in our design system, we’ll add it to our roadmap or work with you and your team to bring it to life. ",
          image: newIllustration
        },
        {
          title: "One-off",
          description:
            "If this doesn’t belong at the system level, it might be appropriate to introduce a custom pattern for this need. We can always pull it into the system later if we need to.",
          image: oneOffIllustration
        },
        {
          title: "No work needed",
          description:
            "The Design Systems team may identify an alternate solution that doesn’t require a change to the system. We’ll let you know if this is the case, and work with your team to adjust accordingly.",
          image: noneIllustration
        }
      ]
    }
  }
};

export default COLLABORATION_TYPES;
