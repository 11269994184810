import React from "react";
import {
  Input,
  Textarea,
  FormField,
  Checkbox,
  Label,
  Box,
  Text,
  Select
} from "@sproutsocial/racine";

const IssueForm = (fields, handleChange, dirty) => (
  <React.Fragment>
    <FormField
      label="Title"
      helperText="Summarize the issue in a few words."
      isInvalid={fields.name === "" && dirty}
      mb={0}
    >
      {(props) => (
        <Input
          value={fields.name}
          onChange={handleChange}
          name="name"
          placeholder="e.g. FormField breaks on smaller screens"
          {...props}
        />
      )}
    </FormField>

    <FormField
      label="Description"
      helperText="Give us a brief overview of the issue and how it is affecting you. If we need to, we’ll reach out to get more information."
      isInvalid={fields.description === "" && dirty}
      mb={0}
    >
      {(props) => (
        <Textarea
          value={fields.description}
          onChange={handleChange}
          name="description"
          placeholder="e.g. Elements are being cut off when using the FormField component at a screen width of less than 800px"
          {...props}
        />
      )}
    </FormField>

    <FormField
      label="Urgency"
      helperText="Let us know how severely this is impacting you."
      isInvalid={fields.severity === "" && dirty}
      mb={0}
    >
      {(props) => (
        <Select
          defaultValue={fields.severity}
          onChange={handleChange}
          name="severity"
          {...props}
        >
          <option value="">Select an option...</option>
          <option value="low">This isn't blocking my work</option>
          <option value="medium">This will be blocking my work soon</option>
          <option value="high">This is actively blocking my work</option>
        </Select>
      )}
    </FormField>

    <Box display="flex" alignItems="flex-start">
      <Checkbox
        checked={fields.volunteer}
        onChange={(e) => handleChange(e, !fields.volunteer)}
        name="volunteer"
        id="volunteer-checkbox"
        mr={350}
        mt="3px"
      />
      <Box>
        <Label htmlFor="volunteer-checkbox" mb={200}>
          Volunteer to help with this? (optional)
        </Label>
        <Text as="p" fontSize={200} color="text.subtext">
          If you or your zone/squad has the bandwidth and capacity to
          potentially help us add this component to Seeds, let us know!
        </Text>
      </Box>
    </Box>
  </React.Fragment>
);

export default IssueForm;
