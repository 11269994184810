import React from "react";
import styled from "styled-components";
import Heading from "components/Heading";
import CopyContent from "components/CopyContent";
import media from "utils/media";

const linkIcon = `<svg aria-hidden="true" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>`;

const checkIcon = `<svg width="16" height="16" class="octicon octicon-check" viewBox="0 0 12 16" version="1.1" aria-hidden="true"><path fill-rule="evenodd" d="M12 5l-8 8-4-4 1.5-1.5L4 10l6.5-6.5L12 5z"></path></svg>`;

const Icon = (props) => (
  <span
    className="header-link-icon"
    dangerouslySetInnerHTML={{ __html: props.icon }}
    {...props}
  />
);

const Container = styled.span`
  position: relative;
  color: ${(props) => props.theme.colors.text.headline};

  .copy {
    transition: 0.2s;
    opacity: 0;
  }

  &:hover {
    .copy {
      opacity: 1;
    }
  }
`;

const CopyIcon = styled.span`
  position: absolute;
  left: -48px;
  top: -4px;
  transform: translateX(100%);

  ${media.medium`
    left: -40px;
  `}

  ${media.small`
    top: 0;
    left: unset;
    right: -24px;
  `}
`;

const wrapper = (HeadingComponent, { children, ...props }) => {
  if (!props.id) {
    console.warn(`Header (${children.toString()}) rendered without "id" prop.`);

    return <HeadingComponent {...props}>{children}</HeadingComponent>;
  }

  let location = props.id;

  // If we are in the browser
  if (typeof window !== `undefined`) {
    // strip trailing slashes
    const pathname = window.location.pathname.replace(/\/$/, "");
    // We can't just just location.href because that will give us the *current url*, when actually we want *the url of this particular header*. We can construct that by concatenating the origin and pathname witht the header's id
    location = window.location.origin + pathname + `#${props.id}`;
  }

  return (
    <HeadingComponent {...props}>
      <Container>
        {children}

        <CopyIcon className="copy">
          <CopyContent
            copy={location}
            success={
              <React.Fragment>
                <Icon icon={checkIcon} />
              </React.Fragment>
            }
          >
            <React.Fragment>
              <Icon icon={linkIcon} />
            </React.Fragment>
          </CopyContent>
        </CopyIcon>
      </Container>
    </HeadingComponent>
  );
};

const HeadingHandler = (props) => {
  const doctoredProps = {
    ...props,
    ...(props.fontSize ? { typography: props.fontSize } : {})
  };

  return wrapper(Heading[props.type], doctoredProps);
};

HeadingHandler.h1 = (props) => <HeadingHandler type="h1" {...props} />;
HeadingHandler.h2 = (props) => <HeadingHandler type="h2" {...props} />;
HeadingHandler.h3 = (props) => <HeadingHandler type="h3" {...props} />;
HeadingHandler.h4 = (props) => <HeadingHandler type="h4" {...props} />;
HeadingHandler.h5 = (props) => <HeadingHandler type="h5" {...props} />;
HeadingHandler.h6 = (props) => <HeadingHandler type="h6" {...props} />;

export default HeadingHandler;
