import React from "react";
import { Box, Text, Icon, Stack } from "@sproutsocial/racine";
import Link from "components/Link";
import { trackEvent } from "utils/js";

const trackClick = () => {
  trackEvent("Click mobile link", { category: "scorecard" });
};

const Mobile = ({ data, ...rest }) => {
  if (typeof data === "string") {
    const isMissing = !data.startsWith("https");

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        {...rest}
      >
        <Text as="div" fontWeight="semibold" fontSize={200}>
          <Icon name="mobile-outline" mt={-200} mr={300} /> Available on mobile
        </Text>

        {isMissing ? (
          <Icon name="circle-x-outline" color="icon.error" />
        ) : (
          <Link
            to={data}
            fontWeight="semibold"
            typography={300}
            onClick={trackClick}
            css={`
              display: inline-flex;
              align-items: center;
            `}
          >
            Confluence{" "}
            <Icon name="squares-arrow-up-right-solid" ml={300} size="mini" />
          </Link>
        )}
      </Box>
    );
  }

  return (
    <Box {...rest}>
      <Text as="div" fontWeight="semibold" fontSize={200}>
        <Icon name="mobile-outline" mt={-200} mr={300} /> Available on mobile
      </Text>

      {(data.ios || data.android) && (
        <Stack ml="20px" mt={300} align="stretch">
          {data.ios && (
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="space-between"
              width={1}
            >
              <Text as="span" fontWeight="semibold">
                <Icon name="apple" mt={-200} mr={300} fixedWidth />
                iOS
              </Text>

              <Link
                to={data.ios}
                fontWeight="semibold"
                typography={300}
                onClick={trackClick}
                css={`
                  display: inline-flex;
                  align-items: center;
                `}
              >
                {data.ios.includes("wiki") ? "Confluence" : "H.I.G."}
                <Icon
                  name="squares-arrow-up-right-solid"
                  size="mini"
                  ml={300}
                />
              </Link>
            </Box>
          )}

          {data.android && (
            <Box
              display="inline-flex"
              alignItems="center"
              justifyContent="space-between"
              width={1}
            >
              <Text as="span" fontWeight="semibold">
                <Icon name="android" mt={-200} mr={300} fixedWidth />
                Android
              </Text>

              <Link
                to={data.android}
                fontWeight="semibold"
                typography={300}
                onClick={trackClick}
                css={`
                  display: inline-flex;
                  align-items: center;
                `}
              >
                {data.android.includes("wiki")
                  ? "Confluence"
                  : "Material Design"}
                <Icon
                  name="squares-arrow-up-right-solid"
                  size="mini"
                  ml={300}
                />
              </Link>
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Mobile;
