import styled from "styled-components";
import { border, color, flexbox, grid, layout, position, space, typography } from "styled-system";
import { focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import Icon from "../Icon";

// TODO: Would be really cool to cherry pick specific props from style functions. For example,
// removing the css prop 'color' from the color function or importing just the specific
// props the component needs. It appears to be possible with some and not others.
// https://github.com/styled-system/styled-system/issues/1569

export var StyledCardContent = styled.div.withConfig({
  displayName: "styles__StyledCardContent",
  componentId: "sc-9tyqu4-0"
})(["display:flex;flex-direction:column;padding:", ";box-sizing:border-box;", " ", " ", " ", " ", " ", ""], function (_ref) {
  var theme = _ref.theme;
  return theme.space[400];
}, border, color, flexbox, grid, layout, space);
export var StyledCardHeader = styled(StyledCardContent).withConfig({
  displayName: "styles__StyledCardHeader",
  componentId: "sc-9tyqu4-1"
})(["flex-direction:row;border-bottom:", ";border-top-left-radius:", ";border-top-right-radius:", ";", " ", " ", " ", " ", " ", ""], function (_ref2) {
  var theme = _ref2.theme;
  return "".concat(theme.borderWidths[500], " solid\n  ").concat(theme.colors.container.border.base);
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.radii.inner;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.radii.inner;
}, border, color, flexbox, grid, layout, space);
export var StyledCardFooter = styled(StyledCardContent).withConfig({
  displayName: "styles__StyledCardFooter",
  componentId: "sc-9tyqu4-2"
})(["flex-direction:row;border-top:", ";border-bottom-left-radius:", ";border-bottom-right-radius:", ";", " ", " ", " ", " ", " ", ""], function (_ref5) {
  var theme = _ref5.theme;
  return "".concat(theme.borderWidths[500], " solid\n  ").concat(theme.colors.container.border.base);
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.radii.inner;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.radii.inner;
}, border, color, flexbox, grid, layout, space);
export var SelectedIconWrapper = styled.div.withConfig({
  displayName: "styles__SelectedIconWrapper",
  componentId: "sc-9tyqu4-3"
})(["display:flex;align-items:center;justify-content:center;position:absolute;top:-8px;right:-8px;"]);
export var StyledSelectedIcon = styled(Icon).withConfig({
  displayName: "styles__StyledSelectedIcon",
  componentId: "sc-9tyqu4-4"
})(["border-radius:50%;background:", ";opacity:0;transition:opacity ", ";", ""], function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.container.background.base;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.duration.medium;
}, function (_ref10) {
  var $selected = _ref10.$selected;
  return $selected && "\n    opacity: 1;\n  ";
});
export var StyledCardLink = styled.a.withConfig({
  displayName: "styles__StyledCardLink",
  componentId: "sc-9tyqu4-5"
})(["font-family:", ";font-weight:", ";color:", ";", ";", " ", ""], function (p) {
  return p.theme.fontFamily;
}, function (p) {
  return p.theme.fontWeights.bold;
}, function (p) {
  return p.theme.colors.text.headline;
}, function (p) {
  return p.theme.typography[400];
}, color, typography);
export var StyledCard = styled.div.withConfig({
  displayName: "styles__StyledCard",
  componentId: "sc-9tyqu4-6"
})(["position:relative;display:flex;flex-direction:column;box-sizing:border-box;margin:0;background:", ";border:", " solid ", ";padding:", ";border-radius:", ";transition:box-shadow ", ",border ", ";&[role=\"button\"],&[role=\"checkbox\"]{cursor:pointer;}", " &:hover{box-shadow:", ";}&:focus-within{", " ", ":focus{border:none;box-shadow:none;outline:none;}}&:focus{", "}", " ", " ", " ", " ", " ", " ", " ", " ", ""], function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.container.background.base;
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.borderWidths[500];
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.container.border.base;
}, function (_ref14) {
  var theme = _ref14.theme,
    $compositionalComponents = _ref14.$compositionalComponents;
  return $compositionalComponents ? 0 : theme.space[400];
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.radii.outer;
}, function (_ref16) {
  var theme = _ref16.theme;
  return theme.duration.medium;
}, function (_ref17) {
  var theme = _ref17.theme;
  return theme.duration.medium;
}, function (_ref18) {
  var $isRoleLink = _ref18.$isRoleLink;
  return $isRoleLink && "\n\t\tcursor: pointer;\n\t";
}, function (_ref19) {
  var theme = _ref19.theme,
    _ref19$$elevation = _ref19.$elevation,
    $elevation = _ref19$$elevation === void 0 ? "low" : _ref19$$elevation;
  return theme.shadows[$elevation];
}, function (_ref20) {
  var $isRoleLink = _ref20.$isRoleLink;
  return $isRoleLink ? focusRing : null;
}, StyledCardLink, focusRing, function (_ref21) {
  var $disabled = _ref21.$disabled;
  return $disabled && "\n    ".concat(disabled, "\n  ");
}, function (_ref22) {
  var $selected = _ref22.$selected,
    theme = _ref22.theme;
  return $selected && "\n    border: ".concat(theme.borderWidths[500], " solid ").concat(theme.colors.container.border.selected, ";  \n  ");
}, border, color, flexbox, grid, layout, position, space);
export var StyledCardAffordance = styled(Icon).withConfig({
  displayName: "styles__StyledCardAffordance",
  componentId: "sc-9tyqu4-7"
})(["", ":hover &{transform:translateX(", ");}transition:", ";"], StyledCard, function (p) {
  return p.theme.space[200];
}, function (p) {
  return p.theme.duration.medium;
});