import React, { useState, useContext, useRef, useEffect } from "react";
import styled, { css } from "styled-components";
import SeedsContext from "components/global/Context";
import { color } from "styled-system";
import { LiveProvider, LiveError, LivePreview, LiveEditor } from "react-live";
import * as Racine from "@sproutsocial/racine";
import { illustrationNames } from "@sproutsocial/racine/commonjs/SpotIllustration/illustrationNames";
import Box from "components/global/Box.js";
import ThemePicker from "components/racine/ThemePicker";
import ImportStatement from "components/racine/ImportStatement";
import prismTheme from "utils/prism-theme";
import {
  TwitterGrid,
  MessageMeta,
  MessageDate
} from "docs/examples/nectar/MessageComponents";
import dripCoffee from "docs/components/message/drip-coffee.jpg";
import icedCoffee from "docs/components/message/iced-coffee.jpg";
import carafe from "docs/components/message/carafe.jpg";
import womanCoffee from "docs/components/message/woman-coffee.jpg";
import sproutCoffee from "docs/components/message/sprout-coffee-co.png";
import nate from "docs/components/message/nathan-sanders-teal.png";
import { withPrefix } from "gatsby";
import ModalListExample from "docs/examples/modal/ModalListExample";
import ExpressiveCard from "components/resources/Card";
import moment from "moment";
import clone from "just-clone";
import { partnerNames } from "docs/components/partner-logo/constants";

const MAX_LINES_OF_CODE = 12;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: ${(p) => p.theme.radii.outer};
  margin: 1rem 0 2.5rem;
  overflow: hidden;
`;

const Preview = styled(LivePreview)`
  flex-basis: 100%;
  padding: 64px 40px;
  word-wrap: break-word;
  border-width: 1px;
  border-style: solid;
  border-bottom: ${(p) => (p.editorHidden ? "1px solid currentColor" : "none")};
  border-top: 1px solid
    ${(props) =>
      props.border ? props.theme.colors.neutral[200] : "transparent"};
  overflow: hidden;
  border-radius: ${(p) =>
    p.editorHidden
      ? p.theme.radii.outer
      : `${p.theme.radii.outer} ${p.theme.radii.outer} 0 0`};
  border-color: ${(props) =>
    props.border
      ? props.theme.colors.neutral[200]
      : props.theme.colors.neutral[1000]};
  ${color}

  table {
    margin: 0;
  }
`;

const EditorWrapper = styled.div`
  position: relative;
  flex-basis: 100%;
  padding: ${(props) => props.theme.space[400]};
  padding-left: 40px;
  font-size: 15px;
  line-height: 1.5;
  overflow: ${(props) => (props.open ? "auto" : "hidden")};
  border-top: 1px solid ${(props) => props.theme.colors.neutral[800]};
  border-radius: 0;
  max-height: ${(props) => (props.open ? "none" : "200px")};
  background-color: ${(props) => props.theme.colors.neutral[900]};

  textarea,
  pre {
    padding: 0 !important;
    word-wrap: normal !important;
    white-space: pre !important;
    caret-color: ${(props) => props.theme.colors.neutral[600]};
    font-feature-settings: normal;
  }

  > div {
    min-width: 100%;
    width: max-content;
  }

  > div:focus,
  textarea:focus {
    outline: none;
  }

  &:before {
    content: "1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30 31 32 33 34 35 36 37 38 39 40 41 42 43 44 45 46 47 48 49 50 51 52 53 54 55 56 57 58 59 60 61 62 63 64 65 66 67 68 69 70 71 72 73 74 75 76 77 78 79 80 81 82 83 84 85 86 87 88 89 90 91 92 93 94 95 96 97 98 99 100 101 102 103 104 105 106 107 108 109 110 111 112 113 114 115 116 117 118 119 120 121 122 123 124 125 126 127 128 129 130 131 132 133 134 135 136 137 138 139 140 141 142 143 144 145 146 147 148 149 150 151 152 153 154 155 156 157 158 159 160 161 162 163 164 165 166 167 168 169 170 171 172 173 174 175 176 177 178 179 180 181 182 183 184 185 186 187 188 189 190 191 192 193 194 195 196 197 198 199 199 200";
    width: 20px;
    position: absolute;
    top: 16px;
    left: 8px;
    bottom: 16px;
    color: ${(props) => props.theme.colors.neutral[700]};
    white-space: pre-line;
    overflow: hidden;
    text-align: right;
    line-height: 24px;
  }

  ${(props) =>
    !props.open &&
    css`
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          0deg,
          ${props.theme.colors.neutral[900]} 0%,
          rgba(0, 0, 0, 0) 100%
        );
      }
    `}

  &, & * {
    font-family: ${(p) => p.theme.fontFamily.mono};
  }
`;

const Error = styled(LiveError)`
  flex-basis: 100%;
  background: ${(props) => props.theme.colors.red[100]};
  color: ${(props) => props.theme.colors.red[900]};
  padding: 8px ${(props) => props.theme.space[400]};
  font-family: ${(p) => p.theme.fontFamily.mono};
  ${(props) => props.theme.typography[200]}
`;

const MoreLessButton = styled.button`
  width: 100%;
  border: none;
  text-align: center;
  appearance: none;
  cursor: pointer;
  transition: all 0.2s;
  margin: 0;
  padding: 4px 0 2px;

  color: ${(props) => props.theme.colors.neutral[300]};
  background: ${(props) => props.theme.colors.neutral[900]};
  ${(props) => props.theme.typography[200]}
  border-top: 1px solid ${(props) => props.theme.colors.neutral[800]};

  &:hover {
    background: ${(props) => props.theme.colors.neutral[1000]};
  }
`;

const ThemePickerContainer = styled.div`
  position: absolute;
  top: 4px;
  right: 12px;
`;

const CodeSandbox = ({
  children,
  inline,
  linesOfCode,
  showEditor = true,
  ...props
}) => {
  const context = useContext(SeedsContext);
  const toggleable = linesOfCode > MAX_LINES_OF_CODE;
  const [open, setOpen] = useState(!toggleable);
  const editorScope = {
    ...Racine,
    useState,
    useRef,
    useEffect,
    withPrefix,
    styled,
    TwitterGrid,
    MessageMeta,
    MessageDate,
    dripCoffee,
    womanCoffee,
    icedCoffee,
    carafe,
    nate,
    sproutCoffee,
    ModalListExample,
    ExpressiveCard,
    moment,
    clone,
    partnerNames,
    illustrationNames
  };

  let code = children.toString();

  // Prettier messes up some of our code blocks by beginning them with a random ";" character. This strips it out.
  if (code.indexOf(";") === 0) {
    code = code.slice(1);
  }

  const background = context.codeTheme.bg;
  const setBackground = context.codeTheme.setBackground;
  const mode = context.codeMode.mode;

  return (
    <Container {...props}>
      <LiveProvider
        code={code}
        scope={editorScope}
        noInline={!inline || !inline === "true"}
        mountStylesheet={false}
        theme={prismTheme}
        disabled={props.static || !open}
      >
        <Racine.ThemeProvider theme={Racine[mode]}>
          <ThemePickerContainer>
            <ThemePicker onChange={(color) => setBackground(color)} />
          </ThemePickerContainer>

          <Preview
            border={background === "neutral.0"}
            color={background === "neutral.0" ? "inherit" : "neutral.0"}
            bg={background}
            editorHidden={!showEditor}
          />
        </Racine.ThemeProvider>
        {props.importStatement && (
          <ImportStatement name={props.importStatement} />
        )}

        {showEditor && (
          <React.Fragment>
            <EditorWrapper open={open}>
              <LiveEditor />
            </EditorWrapper>

            {toggleable && (
              <Box flex="1">
                <MoreLessButton onClick={() => setOpen(!open)}>
                  {open ? "Show less" : "Show more"}
                </MoreLessButton>
              </Box>
            )}
          </React.Fragment>
        )}

        <Error />
      </LiveProvider>
    </Container>
  );
};

export default CodeSandbox;
