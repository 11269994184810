import styled from "styled-components";
var Nav = styled.nav.withConfig({
  displayName: "styles__Nav",
  componentId: "sc-1d7ypr1-0"
})(["ol{", ";margin:0;font-family:", ";padding:0;display:flex;}li{margin-right:", ";display:flex;}a,button{", ";max-width:200px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;&:not(.overflow--menu){margin:0;padding:0;}}ol > div + li::before{content:\"/\";margin-right:", ";}li:last-child a,li:last-child button{color:", ";font-weight:bold;}li:not(:last-child)::after{content:\"/\";color:", ";margin-left:", ";}"], function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.space[200];
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.space[200];
});
export default Nav;