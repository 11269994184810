import React, { useRef } from "react";
import {
  Tooltip,
  Text,
  theme as racineTheme,
  Box,
  Icon
} from "@sproutsocial/racine";
import { Container } from "./styles";
import { useWidth } from "./utils";
import ClientOnly from "components/ClientOnly";

const TypeTag = ({ size }) => {
  const textContainer = useRef();
  const width = useWidth(textContainer);
  const typeValue = racineTheme.typography[size].fontSize;
  const lineHeightValue = racineTheme.typography[size].lineHeight;
  const lineNum = lineHeightValue.slice(0, -2);

  return (
    <Tooltip placement="top" content={`${typeValue} / ${Math.round(lineNum)}`}>
      <Container
        copy={typeValue}
        success={
          <Box display="flex" alignItems="center" minWidth={width}>
            <Icon mr={300} color="green.600" name="font-case-outline" />
            <Text color="text.body">Copied!</Text>
          </Box>
        }
      >
        <Box display="flex" alignItems="center" ref={textContainer}>
          <Icon
            mr={300}
            color="green.600"
            name="font-case-outline"
            aria-label="Font"
          />
          <Text color="text.body">Size {size}</Text>
        </Box>
      </Container>
    </Tooltip>
  );
};

export default (props) => (
  <ClientOnly>
    <TypeTag {...props} />
  </ClientOnly>
);
