import { isProduction, trackEvent, isBrowserEnvironment } from "./js";

const AUTH_KEY = "aK#;&|7Wmipg52p5";

const KEYS = {
  auth: "seeds.authKey",
  user_details: "seeds.userDetails",
  legacy_user_details: "gatsbyUser"
};

export const slack = {
  clientID: "2435590492.1178887446611",
  redirectURI: encodeURIComponent(
    isProduction()
      ? "https://seeds.sproutsocial.com/login/callback"
      : "http://localhost:64482/login/callback"
  )
};

export const loginURL = (from = "/") => {
  if (isBrowserEnvironment) {
    from = window.location.pathname;
  }

  const state = JSON.stringify({
    from,
    redirectURI: slack.redirectURI
  });

  return `https://slack.com/oauth/v2/authorize?user_scope=identity.basic,identity.email,identity.team,identity.avatar&client_id=${
    slack.clientID
  }&state=${encodeURIComponent(state)}&redirect_uri=${slack.redirectURI}`;
};

const migrateLegacyUserDetails = () => {
  const legacyUserDetails = window.localStorage[KEYS.legacy_user_details];

  if (legacyUserDetails) {
    const parsed = JSON.parse(legacyUserDetails);

    if (parsed.profile) {
      login(parsed.profile);
    }

    delete window.localStorage[KEYS.legacy_user_details];
  }
};

export const getUserDetails = () => {
  if (!isBrowserEnvironment) return null;
  migrateLegacyUserDetails();
  const userDetails = window.localStorage[KEYS.user_details];
  return userDetails ? JSON.parse(userDetails) : null;
};

const getAuthKey = () => window.localStorage[KEYS.auth] || "";

const setUser = (user) =>
  (window.localStorage[KEYS.user_details] = JSON.stringify(user));

export const login = (profile = {}) => {
  if (!isBrowserEnvironment) return false;
  window.localStorage[KEYS.auth] = AUTH_KEY;
  return setUser(profile);
};

export const isLoggedIn = () => {
  if (!isBrowserEnvironment) return false;
  //TODO: Honestly, I don't know why this is here. It's causing a bug where you can't use dev mode because of hydration issues.
  // if (!isProduction()) return true
  migrateLegacyUserDetails();

  const authKey = getAuthKey();

  if (authKey === AUTH_KEY) {
    const userDetails = getUserDetails();
    if (!userDetails) {
      // Track to see how many users are authorized with Slack but have no associated user details (if this happens, we might need to log them out?)
      trackEvent("User details not returned from Slack", {
        category: "authorization"
      });
    }

    return true;
  }

  return false;
};

export const isActuallyLoggedIn = () => {
  if (!isBrowserEnvironment) return false;

  const authKey = getAuthKey();
  return authKey === AUTH_KEY;
};

export const logout = () => {
  if (!isBrowserEnvironment) return;
  delete window.localStorage[KEYS.auth];
  delete window.localStorage[KEYS.user_details];
};
