import styled, { keyframes } from "styled-components";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
import { color, layout, space } from "styled-system";
var loadingKeyFrame = keyframes(["0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}"]);
export var StyledSwitchButton = styled.button.withConfig({
  displayName: "styles__StyledSwitchButton",
  componentId: "sc-ku462j-0"
})(["position:relative;display:inline-flex;align-self:center;width:40px;height:24px;margin:0;padding:", " ", ";vertical-align:middle;appearance:none;background-color:", ";border-radius:16px;outline:none;cursor:pointer;transition:background-color ", " ", ";border:1px solid ", ";white-space:nowrap;overflow:hidden;&:focus{", "}&[aria-checked]{&::after{display:block;width:16px;height:16px;border-radius:50%;content:\"\";transition:transform ", " ", ";}&.loading{cursor:not-allowed;pointer-events:none;background-size:contain;background-color:", ";&::after{position:absolute;box-sizing:content-box;width:8px;height:8px;border-radius:", ";border:3px dotted ", ";background-color:transparent;animation:", " 2s linear infinite;}}}&[aria-checked=\"true\"]{color:", ";text-align:left;border-color:", ";background-color:", ";.Icon{position:absolute;top:50%;left:4px;transform:translate(0,-50%);color:", ";}&::after{background-color:", ";opacity:1;transform:translate(100%,6%);}&.loading::after{top:4px;right:5px;}&:hover,&:focus{&::after{transform:translate(90%,6%);}}}&[aria-checked=\"false\"]{&::after{background-color:", ";opacity:0.64;transform:translate(0%,6%);}&.loading::after{top:4px;left:5px;}&:hover,&:focus{&::after{transform:translate(10%,6%);}}}&:disabled{opacity:0.4;pointer-events:none;cursor:not-allowed;&[aria-checked=\"true\"]{&:hover,&:focus{background-color:", ";}}}", " ", " ", ""], function (_ref) {
  var theme = _ref.theme;
  return theme.space[100];
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.space[200];
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.form.background.base;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.duration.fast;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.easing.ease_inout;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.form.border.base;
}, focusRing, function (_ref7) {
  var theme = _ref7.theme;
  return theme.duration.fast;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.easing.ease_inout;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.form.background.base;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.radii.pill;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.colors.icon.base;
}, loadingKeyFrame, function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.text.body;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.colors.form.border.selected;
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.form.background.selected;
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.colors.icon.inverse;
}, function (_ref16) {
  var theme = _ref16.theme;
  return theme.colors.icon.inverse;
}, function (_ref17) {
  var theme = _ref17.theme;
  return theme.colors.icon.base;
}, function (_ref18) {
  var theme = _ref18.theme;
  return theme.colors.container.background.selected;
}, color, layout, space);