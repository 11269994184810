import React from "react";
import { Box, Icon, Menu, Text } from "@sproutsocial/racine";
import OpenResponseField from "./OpenResponseField";
import COLLABORATION_TYPES from "./utils/collaboration-types";
import { trackEvent } from "utils/js";

const CollaborationMenu = ({ onChange }) => {
  return (
    <React.Fragment>
      <Menu width="320px">
        <Menu.Group>
          {Object.entries(COLLABORATION_TYPES).map(([type, item], i) => (
            <Menu.Item
              onClick={() => {
                onChange(type);

                trackEvent("Select collaboration menu item", {
                  category: "Collaboration",
                  label: type
                });
              }}
              key={i}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box display="flex">
                  <Box mr={350}>
                    <Icon name={item.icon} color={item.color} fixedWidth />
                  </Box>

                  <Box>
                    <Text as="div" fontWeight="semibold" fontSize={200}>
                      {item.title}
                    </Text>
                    <Text as="div" fontSize={100}>
                      {item.description}
                    </Text>
                  </Box>
                </Box>

                <Box>
                  <Icon
                    name="arrow-right-solid"
                    size="mini"
                    color="neutral.500"
                  />
                </Box>
              </Box>
            </Menu.Item>
          ))}
        </Menu.Group>

        <Menu.Divider />
      </Menu>

      <Box px={400} pt={350} pb={400}>
        <OpenResponseField />
      </Box>
    </React.Fragment>
  );
};

export default CollaborationMenu;
