import React from "react";
import styled from "styled-components";
import media from "utils/media";
import logo_light from "assets/logo_light.svg";
import logo_dark from "assets/logo_dark.svg";
import logo_small from "assets/logo_small.svg";
import Link from "components/Link";

const Container = styled.div`
  grid-column: left;
  height: ${(props) => props.theme.sizes.header.height}px;
  min-width: ${(props) => props.theme.sizes.sidebar.minWidth}px;
  max-width: ${(props) => props.theme.sizes.sidebar.minWidth}px;
  padding: 0 ${(props) => props.theme.space[500]};

  a {
    height: 100%;
    width: 100%;
  }

  ${media.medium`
    padding: 0 ${(props) => props.theme.space[400]};

    a {
      width: auto;
    }
    
  `}

  background: ${(props) => props.theme.colors.aqua[1100]};

  .light-logo {
    display: block;
  }

  .dark-logo {
    display: none;
  }

  .no-sidebar & {
    background: ${(props) => props.theme.colors.neutral[0]};
    border-bottom: 1px solid ${(p) => p.theme.colors.neutral[200]};

    .light-logo {
      display: none;
    }

    .dark-logo {
      display: block;
    }
  }

  ${media.medium`
    min-width: auto;
    background: ${(props) => props.theme.colors.neutral[0]};
  `}
`;

const LogoImage = styled.img`
  margin: 0;
  width: ${(props) => (props.small ? `100%` : `70%`)};
  min-width: 179px;
  min-height: 79px;

  ${media.medium`
    width: 75%;
    min-width: 36px;
    min-height: 79px;
  `};
`;

const LargeLogo = styled(Link)`
  display: flex;

  ${media.medium`
    display: none;
  `};
`;

const SmallLogo = styled(Link)`
  display: none;

  ${media.medium`
    display: flex;
  `};
`;

const Logo = (props) => (
  <Container {...props}>
    <LargeLogo to="/">
      <LogoImage src={logo_dark} alt="Sprout Social" className="dark-logo" />
      <LogoImage src={logo_light} alt="Sprout Social" className="light-logo" />
    </LargeLogo>
    <SmallLogo to="/">
      <LogoImage small src={logo_small} alt="Sprout Social" />
    </SmallLogo>
  </Container>
);

export default Logo;
