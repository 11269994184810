import styled from "styled-components";
import Box from "../Box";

/**
 *
 * @param borderRadius
 * @returns classname string for handling appropriate loader style depending on passed in properties
 */
var SkeletonAttrs = function SkeletonAttrs(_ref) {
  var borderRadius = _ref.borderRadius,
    height = _ref.height,
    width = _ref.width;
  return {
    className: borderRadius === "pill" && height === width ? "circular" : "linear"
  };
};
var Skeleton = styled(Box).attrs(SkeletonAttrs).withConfig({
  displayName: "Skeleton",
  componentId: "sc-s6hpnd-0"
})(["position:relative;background:", ";overflow:hidden;&.circular{&:before{position:absolute;top:-25%;left:-25%;content:'';background-image:", ";height:150%;width:150%;animation:SkeletonRotate 2s infinite linear;}&:after{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);content:'';height:calc(100% - 8px);width:calc(100% - 8px);background:", ";border-radius:50%;}}&.linear{position:relative;background-image:", " background-size:400%;background-repeat:no-repeat;animation:SkeletonShimmer 2s linear infinite reverse;overflow:hidden;&:after{position:absolute;bottom:0;content:\"\";height:calc(100% - 4px);width:100%;background:", ";}}@media (prefers-reduced-motion){&.linear,&.circular::before{animation:none;}&:before,&:after{display:none;}&.linear,&.circular{border:1px solid ", ";animation:SkeletonPulse 2s linear infinite alternate;}}@keyframes SkeletonRotate{100%{transform:rotate(360deg);}}@keyframes SkeletonRotateFade{50%{transform:rotate(360deg);}90%{opacity:1;}100%{transform:rotate(720deg);opacity:0;}}@keyframes SkeletonShimmer{0%{background-position:0% 0;}100%{background-position:100% 0;}}@keyframes SkeletonPulse{0%{border-color:", "FF;}100%{border-color:", "1A;}}"], function (props) {
  return props.theme.colors.container.background.decorative.neutral;
}, function (props) {
  return "conic-gradient(\n      ".concat(props.theme.colors.container.background.decorative.neutral, " 270deg,\n      ").concat(props.theme.colors.container.border.decorative.neutral, " 300deg\n    );");
}, function (p) {
  return p.theme.colors.app.background.base;
}, function (props) {
  return "linear-gradient(\n      288deg,\n      ".concat(props.theme.colors.container.background.decorative.neutral, " 32%,\n      ").concat(props.theme.colors.container.border.decorative.neutral, ",\n      ").concat(props.theme.colors.container.background.decorative.neutral, " 68%\n    );");
}, function (props) {
  return props.theme.colors.container.background.decorative.neutral;
}, function (props) {
  return props.theme.colors.container.border.decorative.neutral;
}, function (props) {
  return props.theme.colors.container.border.decorative.neutral;
}, function (props) {
  return props.theme.colors.container.border.decorative.neutral;
});
export default Skeleton;