import React, { useRef } from "react";
import {
  Tooltip,
  Text,
  Box,
  Icon,
  theme as racineTheme
} from "@sproutsocial/racine";
import { Container, Headline } from "./styles";
import { useWidth } from "./utils";
import ClientOnly from "components/ClientOnly";

const FontWeightTag = (p) => {
  const weightContainer = useRef();
  const weightValue = racineTheme.fontWeights[p.weight];
  const width = useWidth(weightContainer);

  return (
    <Tooltip placement="top" content={weightValue}>
      <Container
        copy={weightValue}
        success={
          <Box display="flex" alignItems="center" minWidth={width}>
            <Icon mr={300} size="mini" name="weight-solid" />
            <Text color="text.body">Copied!</Text>
          </Box>
        }
      >
        <Box display="flex" alignItems="center" ref={weightContainer}>
          <Icon mr={300} size="mini" name="weight-solid" />
          <Headline color="text.body" fontWeight={p.weight}>
            {p.weight}
          </Headline>
        </Box>
      </Container>
    </Tooltip>
  );
};

export default (props) => (
  <ClientOnly>
    <FontWeightTag {...props} />
  </ClientOnly>
);
