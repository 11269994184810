import styled, { css } from "styled-components";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-egruk2-0"
})(["position:relative;box-sizing:border-box;select{box-sizing:border-box;width:100%;border:1px solid ", ";border-radius:", ";background-color:", ";color:", ";cursor:", ";outline:none;appearance:none;transition:border-color ", " ", ",box-shadow ", " ", ";font-family:", ";font-weight:", ";margin:0;visibility:visible;padding:", ";font-size:", ";line-height:", ";&::-ms-expand{display:none;}&:focus{", "}&:not(output):not(:focus):-moz-ui-invalid{box-shadow:none;}}", " ", " ", ""], function (props) {
  return props.theme.colors.form.border.base;
}, function (props) {
  return props.theme.radii[500];
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.fontWeights.normal;
}, function (props) {
  switch (props.size) {
    case "large":
      return "".concat(props.theme.space[350], " ").concat(props.theme.space[600], " ").concat(props.theme.space[350], " ").concat(props.theme.space[400]);
    case "small":
      return "".concat(props.theme.space[200], " ").concat(props.theme.space[500], " ").concat(props.theme.space[200], " ").concat(props.theme.space[200]);
    case "default":
    default:
      return "".concat(props.theme.space[300], " ").concat(props.theme.space[500], " ").concat(props.theme.space[300], " ").concat(props.theme.space[300]);
  }
}, function (props) {
  switch (props.size) {
    case "large":
      return props.theme.typography[300].fontSize;
    case "small":
    case "default":
    default:
      return props.theme.typography[200].fontSize;
  }
}, function (props) {
  switch (props.size) {
    case "large":
      return props.theme.typography[300].lineHeight;

    /* hardcoded to 'normal' so the large change doesn't impact small/default */
    case "small":
    case "default":
    default:
      return "normal";
  }
}, focusRing, function (props) {
  return props.disabled && css(["opacity:0.4;"]);
}, function (props) {
  return props.invalid && css(["select{border-color:", ";}", "{color:", ";}"], function (props) {
    return props.theme.colors.form.border.error;
  }, Arrow, function (props) {
    return props.theme.colors.icon.error;
  });
}, COMMON);
export var Arrow = styled.span.withConfig({
  displayName: "styles__Arrow",
  componentId: "sc-egruk2-1"
})(["position:absolute;top:50%;right:", ";transform:translateY(-50%);color:", ";pointer-events:none;"], function (props) {
  switch (props.size) {
    case "large":
      return props.theme.space[350];
    case "small":
    case "default":
    default:
      return props.theme.space[300];
  }
}, function (props) {
  return props.theme.colors.icon.base;
});
Container.displayName = "SelectContainer";
Arrow.displayName = "Select Arrow";
export default Container;