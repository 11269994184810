import "./src/styles/index.scss";
import "focus-visible";
import "./includeSprite.js";
import { isLoggedIn } from "./src/utils/auth.js";

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === "undefined") {
    await import("intersection-observer");
  }

  if (window !== "undefined" && window.gtag) {
    window.gtag("set", "dimension1", isLoggedIn());
  }
};
