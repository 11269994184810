import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import Text from "../Text";
var Container = styled.span.withConfig({
  displayName: "styles__Container",
  componentId: "sc-w38s4t-0"
})(["display:inline-flex;align-items:center;box-sizing:border-box;", ""], COMMON);
Container.displayName = "Radio";
export var Input = styled.input.withConfig({
  displayName: "styles__Input",
  componentId: "sc-w38s4t-1"
})(["box-sizing:border-box;position:absolute;top:0;left:0;width:100%;height:100%;margin:0;appearance:none;cursor:", ";border:1px solid ", ";border-radius:50%;background-color:", ";transition:border-color ", " ", ",background-color ", " ", ";&:focus{", "}"], function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.theme.colors.form.border.base;
}, function (props) {
  return props.theme.colors.form.background.base;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, focusRing);
Input.displayName = "Input";
export var InputWrapper = styled.span.withConfig({
  displayName: "styles__InputWrapper",
  componentId: "sc-w38s4t-2"
})(["position:relative;width:", ";height:", ";cursor:", ";", " &:before{content:\"\";position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:6px;height:6px;border-radius:50%;opacity:0;transition:opacity ", " ", ";z-index:1;pointer-events:none;cursor:", ";}", " ", ""], function (props) {
  return props.theme.space[400];
}, function (props) {
  return props.theme.space[400];
}, function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.disabled && disabled;
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.easing.ease_in;
}, function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return !props.checked && css(["&:hover,&:focus{", "{border-color:", ";background-color:", ";}&:before{opacity:", ";background-color:", ";}}"], Input, props.theme.colors.form.border.base, props.theme.colors.form.background.base, props.disabled ? 0 : 1, props.theme.colors.form.background.base);
}, function (props) {
  return props.checked && css(["", "{border-color:", ";background-color:", ";}&:before{opacity:1;background-color:", ";}"], Input, props.theme.colors.form.border.selected, props.theme.colors.form.background.selected, props.theme.colors.form.background.base);
});
InputWrapper.displayName = "InputWrapper";
export var LabelText = styled(Text).withConfig({
  displayName: "styles__LabelText",
  componentId: "sc-w38s4t-3"
})(["margin-left:", ";font-size:", ";line-height:1;cursor:", ";color:", ";", ""], function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.theme.typography[200].fontSize;
}, function (props) {
  return props.disabled ? "not-allowed" : "pointer";
}, function (props) {
  return props.theme.colors.text.headline;
}, function (props) {
  return props.disabled && css(["opacity:0.4;"]);
});
LabelText.displayName = "LabelText";
export default Container;