import styled, { css } from "styled-components";
import { COMMON, LAYOUT } from "@sproutsocial/seeds-react-system-props";
var ImageContainer = styled.img.attrs({
  className: "Image"
}).withConfig({
  displayName: "styles__ImageContainer",
  componentId: "sc-dngsi7-0"
})(["display:block;", " ", " ", ""], function (props) {
  return props.isLoading && css(["visibility:hidden;"]);
}, COMMON, LAYOUT);
export default ImageContainer;