import React, { useRef } from "react";
import { Tooltip, Text, theme as racineTheme, Box } from "@sproutsocial/racine";
import { Container } from "./styles";
import { useWidth } from "./utils";
import ClientOnly from "components/ClientOnly";

const SpaceTag = ({ size, dynamic = false }) => {
  const spaceContainer = useRef();
  const spaceValue = racineTheme.space[size];
  const width = useWidth(spaceContainer);
  const barWidth = dynamic ? spaceValue : racineTheme.space[300];

  return (
    <Tooltip placement="top" content={spaceValue}>
      <Container
        copy={spaceValue}
        success={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            minWidth={width}
          >
            <Box display="flex" alignItems="center">
              <Box width="1px" height="9px" bg="pink.600"></Box>
              <Box mr={200} width={barWidth} height="1px" bg="pink.600"></Box>
            </Box>
            <Text color="pink.600">Copied!</Text>
            <Box display="flex" alignItems="center">
              <Box ml={200} width={barWidth} height="1px" bg="pink.600"></Box>
              <Box width="1px" height="9px" bg="pink.600"></Box>
            </Box>
          </Box>
        }
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          ref={spaceContainer}
        >
          <Box display="flex" alignItems="center">
            <Box width="1px" height="9px" bg="pink.600"></Box>
            <Box mr={200} width={barWidth} height="1px" bg="pink.600"></Box>
          </Box>
          <Text color="pink.600">Space {size}</Text>
          <Box display="flex" alignItems="center">
            <Box ml={200} width={barWidth} height="1px" bg="pink.600"></Box>
            <Box width="1px" height="9px" bg="pink.600"></Box>
          </Box>
        </Box>
      </Container>
    </Tooltip>
  );
};

export default (props) => (
  <ClientOnly>
    <SpaceTag {...props} />
  </ClientOnly>
);
