import React from "react";
import { Box, Text, Stack } from "@sproutsocial/racine";

const SuccessState = ({ data }) => {
  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      p={450}
    >
      <Text as="h1" fontSize={800} color="text.headline" mb={400}>
        {data.title}
      </Text>
      <Text as="p" fontSize={300} color="text.body">
        {data.description}
      </Text>

      <Stack mt={600} space={450}>
        {data.outcomes.map((outcome, i) => (
          <Box display="flex" alignItems="flex-start" key={i}>
            <img src={outcome.image} alt="" css="min-width: 56px;" />

            <Box ml={400} mt={200}>
              <Text as="h3" fontSize={400} color="text.body" mb={200}>
                {outcome.title}
              </Text>
              <Text as="p" fontSize={300} color="text.body">
                {outcome.description}
              </Text>
            </Box>
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default SuccessState;
