import React from "react";
import styled, { css } from "styled-components";
import Link from "components/Link";
import {
  color,
  space,
  width,
  border,
  borderColor,
  height,
  minHeight
} from "styled-system";

const Container = styled(Link)`
  display: block;
  transition: all ${(props) => props.theme.duration.medium};
  transition-timing-function: ${(props) => props.theme.easing.ease_inout};
  cursor: pointer;

  &:hover {
    text-decoration: none;
    box-shadow: ${(props) => props.theme.shadows.medium};

    ${(p) =>
      p.hover &&
      css`
        transform: translateY(-${p.theme.space[400]});
      `}
  }

  color: ${(p) => p.theme.colors.neutral[1000]};
  background: ${(p) => p.theme.colors.neutral[0]};
  padding: ${(p) => p.theme.space[500]};
  border: 1px solid ${(p) => p.theme.colors.neutral[300]};

  img {
    margin: 0;
    vertical-align: top;
  }

  ${color}
  ${space}
  ${width}
  ${border}
  ${borderColor}
  ${height}
  ${minHeight}
`;

const Image = styled.img`
  width: 100%;
  margin: 0;
`;

const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const DivContainer = Container.withComponent("div");

const Card = ({ image, title, content, hover, ...rest }) => {
  const BaseElement = !rest.to ? DivContainer : Container;

  return (
    <BaseElement hover={hover ? "true" : undefined} {...rest}>
      {image && <Image src={image} alt="" />}

      <Content>
        {title}
        {content}
      </Content>
    </BaseElement>
  );
};

export default Card;
