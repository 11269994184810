import React from "react";
import styled from "styled-components";
import Box from "components/global/Box";
import CopyContent from "components/CopyContent";
import { Icon } from "@sproutsocial/racine";

const Code = styled.code`
  color: ${(p) => p.theme.colors.neutral[400]};
  background: ${(p) => p.theme.colors.neutral[800]};
  white-space: nowrap;
  overflow: auto;
  width: 100%;
  display: block;
  cursor: pointer;
  padding: 8px 16px;
  border-top: 1px solid ${(props) => props.theme.colors.neutral[800]};
  border-radius: 0;

  > span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &:focus,
  &:active {
    outline: 0;
    background: ${(p) => p.theme.colors.neutral[800]};
  }
`;

const CopySuccessMessage = (props) => (
  <React.Fragment>
    <span>Copied!</span>

    <Icon name="check-outline" />
  </React.Fragment>
);

const ImportStatement = ({ name, ...rest }) => (
  <Box bg="neutral.900" width="100%" {...rest}>
    <Code>
      <CopyContent
        copy={`import { ${name} } from '@sproutsocial/racine'`}
        success={<CopySuccessMessage />}
      >
        <span>{`import { ${name} } from '@sproutsocial/racine'`}</span>

        <Icon name="clipboard-outline" />
      </CopyContent>
    </Code>
  </Box>
);

export default ImportStatement;
