import React, { useEffect } from "react";
import { Stack } from "@sproutsocial/racine";
import { useForm } from "utils/hooks";

const FormManager = ({ fields, validate, onChange, children, dirty }) => {
  const [formFields, handleChange] = useForm(fields, validate);

  useEffect(() => {
    onChange(formFields);
  }, [formFields, onChange]);

  return (
    <Stack align="stretch" space={450}>
      {children(formFields, handleChange, dirty).props.children}
    </Stack>
  );
};

export default FormManager;
