import React, { useState } from "react";
import styled from "styled-components";
import { Box, Link, Collapsible, Icon, Text } from "@sproutsocial/racine";

const Container = styled(Box)`
  transition: border-color ${(p) => p.theme.duration.medium}
    ${(p) => p.theme.easing.ease_inout};
  overflow: hidden;

  &:hover {
    border-color: ${(p) => p.theme.colors.neutral[300]};
  }
`;

const ToggleButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-weight: normal;
  transition: all ${(p) => p.theme.duration.medium}
    ${(p) => p.theme.easing.ease_inout};

  &:hover {
    background: ${(p) => p.theme.colors.neutral[100]};
    text-decoration: none;
  }
`;

const RotatingIcon = styled(Icon)`
  transition: transform ${(p) => p.theme.duration.medium}
    ${(p) => p.theme.easing.ease_inout};
  will-change: transform;
  transform: rotate(${(p) => (p.shouldRotate ? "0deg" : "180deg")});
`;

const Accordion = ({
  title = "Click to expand",
  children,
  isOpen = false,
  ...rest
}) => {
  const [open, setOpen] = useState(isOpen);
  const toggle = () => setOpen(!open);

  return (
    <Box {...rest}>
      <Collapsible isOpen={open}>
        <Container
          border={500}
          borderColor="container.border.base"
          borderRadius="outer"
          mx={-400}
        >
          <Collapsible.Trigger>
            <ToggleButton onClick={toggle} py={350} px={400}>
              <Text as="div" fontSize={300}>
                {title}
              </Text>

              <RotatingIcon
                shouldRotate={open}
                fixedWidth
                name="chevron-up-outline"
                color="neutral.500"
                mr={350}
              />
            </ToggleButton>
          </Collapsible.Trigger>

          <Collapsible.Panel>
            <Box pb={400} px={400}>
              {children}
            </Box>
          </Collapsible.Panel>
        </Container>
      </Collapsible>
    </Box>
  );
};

export default Accordion;
