import ComponentRequestForm from "../forms/ComponentRequest";
import PatternRequestForm from "../forms/PatternRequest";
import IssueRequestForm from "../forms/Issue";

const requireNameAndDescription = (fields) =>
  fields.name.length > 0 && fields.description.length > 0;

const formConfig = {
  component: {
    fields: {
      name: "",
      description: "",
      examples: "",
      "visual-reference": "",
      volunteer: false
    },
    validate: requireNameAndDescription,
    component: ComponentRequestForm
  },
  pattern: {
    fields: {
      name: "",
      description: "",
      "visual-reference": "",
      volunteer: false
    },
    validate: requireNameAndDescription,
    component: PatternRequestForm
  },
  issue: {
    fields: {
      name: "",
      description: "",
      severity: "",
      volunteer: false
    },
    validate: (fields) =>
      fields.name.length > 0 &&
      fields.description.length > 0 &&
      fields.severity !== "",
    component: IssueRequestForm
  },
  recipe: {
    fields: {
      name: "",
      description: ""
    },
    validate: requireNameAndDescription,
    component: ComponentRequestForm
  }
};

export default formConfig;
