import { useStaticQuery, graphql } from "gatsby";

const useComponentDescription = (componentName) => {
  const {
    components: { nodes }
  } = useStaticQuery(query);
  const data = nodes.find(
    (component) => component.title.toLowerCase() === componentName.toLowerCase()
  );
  const description = data ? data.excerpt : null;
  return description;
};

export const query = graphql`
  query ComponentDescriptions {
    components: allDocument(
      filter: {
        excerpt: { ne: null }
        category: { name: { eq: "components" } }
        group: { ne: "Documentation" }
      }
    ) {
      nodes {
        title
        excerpt
      }
    }
  }
`;

export default useComponentDescription;
