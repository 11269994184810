import styled, { css } from "styled-components";
import { verticalAlign } from "styled-system";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var sizes = {
  mini: "12px",
  /** TODO: deprecate default  in favor of small in future release */
  default: "16px",
  small: "16px",
  medium: "24px",
  large: "32px",
  jumbo: "64px"
};
var stylesForSize = function stylesForSize(iconActualSize, fixedWidth) {
  return css(["line-height:", ";&,.Icon-svg{height:", ";fill:currentColor;}", ""], iconActualSize, iconActualSize, fixedWidth && "\n    &,\n    & .Icon-svg {\n      width: ".concat(iconActualSize, "\n    }\n  "));
};
var Container = styled.span.attrs({
  className: "Icon"
}).withConfig({
  displayName: "styles__Container",
  componentId: "sc-1v500hw-0"
})(["display:inline-block;color:inherit;vertical-align:middle;", " ", " ", ""], function (props) {
  return stylesForSize(sizes[props.iconSize], props.fixedWidth);
}, COMMON, verticalAlign);
export default Container;