export var MENU_ROLES = Object.freeze({
  MENU: "menu",
  LIST: "list",
  LISTBOX: "listbox"
});
export var MENU_ITEM_ROLES = Object.freeze({
  MENUITEM: "menuitem",
  LISTITEM: "listitem",
  OPTION: "option",
  RADIO: "menuitemradio",
  CHECKBOX: "menuitemcheckbox"
});