import styled from "styled-components";
import { Box as RacineBox } from "@sproutsocial/racine";
import { variant } from "styled-system";

const typeStyle = variant({
  key: "typography",
  prop: "typography"
});

const Box = styled(RacineBox)`
  ${typeStyle}
  ${(p) => p.flex && `display: flex;`}
`;

export default Box;
