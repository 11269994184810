import styled, { css } from "styled-components";
import { visuallyHidden, focusRing, disabled } from "@sproutsocial/seeds-react-mixins";
import Box from "../Box";
import Text from "../Text";
export var SegmentedControlContainer = styled(Box).withConfig({
  displayName: "styles__SegmentedControlContainer",
  componentId: "sc-kx0lgh-0"
})(["border:1px solid ", ";border-radius:", ";padding:", ";legend{", "}", ""], function (props) {
  return props.theme.colors.button.secondary.border.base;
}, function (props) {
  return props.theme.radii.outer;
}, function (props) {
  return props.theme.space[100];
}, visuallyHidden, function (props) {
  return props.disabled && disabled;
});
export var SegmentedControlItemContainer = styled(Box).withConfig({
  displayName: "styles__SegmentedControlItemContainer",
  componentId: "sc-kx0lgh-1"
})(["flex:1 1 auto;display:flex;cursor:pointer;& + &{margin-left:", ";}&:focus-within label{", "}input{", "}", ""], function (props) {
  return props.theme.space[100];
}, focusRing, visuallyHidden, function (props) {
  return props.disabled && disabled;
});
export var SegmentedControlLabel = styled(Text).withConfig({
  displayName: "styles__SegmentedControlLabel",
  componentId: "sc-kx0lgh-2"
})(["flex:1 1 auto;display:flex;align-items:center;justify-content:center;text-align:center;color:", ";cursor:pointer;font-size:", ";line-height:16px;font-weight:", ";border-radius:", ";padding:calc(", " - 6px);transition:all ", ";&:hover{background-color:", ";}", ""], function (props) {
  return props.theme.colors.text.body;
}, function (props) {
  return props.theme.typography[200].fontSize;
}, function (props) {
  return props.theme.fontWeights.semibold;
}, function (props) {
  return props.theme.radii.inner;
}, function (props) {
  return props.theme.space[350];
}, function (props) {
  return props.theme.duration.fast;
}, function (props) {
  return props.theme.colors.listItem.background.hover;
}, function (props) {
  return props.isActive && css(["color:", ";background-color:", ";&:hover{background-color:", ";}"], function (props) {
    return props.theme.colors.text.inverse;
  }, function (props) {
    return props.theme.colors.listItem.background.selected;
  }, function (props) {
    return props.theme.colors.listItem.background.selected;
  });
});