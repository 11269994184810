import styled from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.td.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1k0c6fx-0"
})(["", " font-weight:", ";padding:", ";text-align:", ";", ""], function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.fontWeights.normal;
}, function (props) {
  return props.theme.space[300];
}, function (props) {
  return props.alignment;
}, COMMON);
export default Container;