function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
export var canUseDOM = function canUseDOM() {
  return Boolean(typeof window !== "undefined" && window.document && window.document.createElement);
};
// Allows a component to pass its own ref *and* a ref prop to the same element.
// Via https://www.davedrinks.coffee/how-do-i-use-two-react-refs/
export var mergeRefs = function mergeRefs(refs) {
  var filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function (inst) {
    var _iterator = _createForOfIteratorHelper(filteredRefs),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var ref = _step.value;
        if (typeof ref === "function") {
          ref(inst);
        } else if (ref) {
          ref.current = inst;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  };
};

/**
 * Type safe includes with narrowing
 * https://fettblog.eu/typescript-array-includes/
 */
export function includes(coll, el) {
  return coll.includes(el);
}

// https://stackoverflow.com/questions/71193818/react-onclick-argument-of-type-eventtarget-is-not-assignable-to-parameter-of-t
export function assertIsNode(e) {
  if (!e || !("nodeType" in e)) {
    throw new Error("Node expected");
  }
}
export function assertIsElement(e) {
  assertIsNode(e);
  if (e.nodeType !== 1) {
    throw new Error("Element expected");
  }
}