import React from "react";
import {
  Input,
  Textarea,
  FormField,
  Checkbox,
  Label,
  Box,
  Text
} from "@sproutsocial/racine";

const PatternRequestForm = (fields, handleChange, dirty) => (
  <React.Fragment>
    <FormField
      label="Name"
      helperText="What’s the name of the guideline you’d like to suggest?"
      isInvalid={fields.name === "" && dirty}
      mb={0}
    >
      {(props) => (
        <Input
          value={fields.name}
          onChange={handleChange}
          name="name"
          placeholder="e.g. Password requirements"
          {...props}
        />
      )}
    </FormField>

    <FormField
      label="Description"
      helperText="Give us a brief overview of what this guideline would be used for, and why it would be valuable to you."
      isInvalid={fields.description === "" && dirty}
      mb={0}
    >
      {(props) => (
        <Textarea
          value={fields.description}
          onChange={handleChange}
          name="description"
          placeholder="e.g. We need guidance on the proper way to display and validate password requirements in a form."
          {...props}
        />
      )}
    </FormField>

    <FormField
      label="Reference (optional)"
      helperText="Have a Figma/Miro/Paper/Google document that captures this pattern? Share the link here."
      mb={0}
    >
      {(props) => (
        <Input
          value={fields["visual-reference"]}
          onChange={handleChange}
          name="visual-reference"
          placeholder="Placeholder text..."
          {...props}
        />
      )}
    </FormField>

    <Box display="flex" alignItems="flex-start">
      <Checkbox
        checked={fields.volunteer}
        onChange={(e) => handleChange(e, !fields.volunteer)}
        name="volunteer"
        id="volunteer-checkbox"
        mr={350}
        mt="3px"
      />
      <Box>
        <Label htmlFor="volunteer-checkbox" mb={100}>
          Volunteer to help with this? (optional)
        </Label>
        <Text as="p" fontSize={200} color="text.subtext">
          If you or your team has the capacity to help us define and add this
          guideline to Seeds, let us know!
        </Text>
      </Box>
    </Box>
  </React.Fragment>
);

export default PatternRequestForm;
