import React, { useState, useReducer, useCallback, useRef } from "react";
import {
  Box,
  LoaderButton,
  Textarea,
  FormField,
  toast
} from "@sproutsocial/racine";
import fetch from "node-fetch";
import { useEventListener } from "utils/hooks";
import { getUserDetails } from "utils/auth";
import outdent from "outdent";
import { trackEvent } from "utils/js";

const STATE_MACHINE = {
  form: {
    SUBMIT: "loading"
  },
  loading: {
    SUCCESS: "success",
    ERROR: "form"
  },
  success: {
    RESET: "form"
  }
};

const reducer = (state, event) => {
  const nextState = STATE_MACHINE[state][event];
  return nextState !== undefined ? nextState : state;
};

const OpenResponseField = () => {
  const [value, setValue] = useState("");
  const [state, dispatch] = useReducer(reducer, "form");
  const [focused, setFocused] = useState(false);
  const containerRef = useRef(null);

  const postToSlack = useCallback(async (message) => {
    const user = getUserDetails();

    const formattedMessage = outdent`
      ${
        user
          ? `*Item submitted by ${user.name} on \`${window.location.pathname}\`*\n`
          : ""
      }
      ${message}
    `;

    trackEvent("Submit open response", {
      category: "Collaboration"
    });

    dispatch("SUBMIT");
    try {
      await fetch("/api/post-to-slack", {
        headers: {
          "content-type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          message: formattedMessage
        })
      });

      dispatch("SUCCESS");

      setValue("");
      toast({
        theme: "success",
        content:
          "Your feedback has been shared with the Design Systems team. We’ll be in touch soon!"
      });

      document.getElementById("collaboration-button").click();
    } catch (error) {
      dispatch("ERROR");
      console.error(error);

      toast({
        theme: "error",
        content:
          "Uh oh! There was some issue submitting your information. Please try again, and let the Design Systems team know if the issue persists."
      });
    }
  }, []);

  const focusHandler = useCallback((event) => {
    if (
      containerRef.current &&
      containerRef.current.contains(document.activeElement)
    ) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, []);

  const keypressHandler = useCallback(
    (event) => {
      if (event.key === "Enter" && event.metaKey) {
        postToSlack(value);
      }
    },
    [postToSlack, value]
  );

  useEventListener("focusin", focusHandler);
  useEventListener("keypress", keypressHandler);

  return (
    <Box
      ref={containerRef}
      css={`
        textarea {
          margin: 0;
        }

        textarea:placeholder-shown {
          max-height: 72px;
        }

        &:focus-within textarea,
        textarea:not(:placeholder-shown) {
          max-height: none;
        }
      `}
    >
      <FormField label="Questions and feedback" mb={0}>
        {(props) => (
          <React.Fragment>
            <Textarea
              placeholder="Any idea, feedback, question, or suggestion is welcome! We want to hear from you."
              value={value}
              onChange={(e) => setValue(e.target.value)}
              rows={6}
              {...props}
            />

            <Box display={focused || value !== "" ? "block" : "none"}>
              <LoaderButton
                mt={350}
                isLoading={state === "loading"}
                appearance="secondary"
                width={1}
                loaderLabel="Loading..."
                disabled={!value.length > 0}
                onClick={() => postToSlack(value)}
              >
                Submit feedback
              </LoaderButton>
            </Box>
          </React.Fragment>
        )}
      </FormField>
    </Box>
  );
};

export default OpenResponseField;
