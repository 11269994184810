import React, { useState, useReducer } from "react";
import { Box, Button, LoaderButton, Drawer, toast } from "@sproutsocial/racine";
import FormManager from "components/collaboration/FormManager";
import formConfig from "./utils/form-config";
import createIssue from "./utils/create-issue";
import COLLABORATION_TYPES from "./utils/collaboration-types";
import Success from "./SuccessState";
import { trackEvent } from "utils/js";

const STATE_MACHINE = {
  form: {
    SUBMIT: "loading"
  },
  loading: {
    SUCCESS: "success",
    ERROR: "form"
  },
  success: {
    RESET: "form"
  }
};

const reducer = (state, event) => {
  const nextState = STATE_MACHINE[state][event];
  return nextState !== undefined ? nextState : state;
};

const getFormComponentForType = (type, onChange, dirty) => {
  const { fields, validate, component } = formConfig[type];

  return (
    <FormManager
      fields={fields}
      validate={validate}
      onChange={onChange}
      children={component}
      dirty={dirty}
    />
  );
};

const IntakeDrawer = ({ type = "issue", title, isOpen, onClose }) => {
  const [formData, setFormData] = useState({});
  const [formIsDirty, setFormIsDirty] = useState(false);
  const [state, dispatch] = useReducer(reducer, "form");
  const formComponent = getFormComponentForType(type, setFormData, formIsDirty);
  const [content, setContent] = useState(formComponent);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormIsDirty(true);
    if (!formData.isValid) return;

    setFormIsDirty(false);
    dispatch("SUBMIT");

    trackEvent("Submit form", {
      category: "Collaboration",
      label: type
    });

    try {
      const result = await createIssue(formData, type);
      if (result.status !== 200) throw Error("Failed to create issue.");
      setContent(<Success data={COLLABORATION_TYPES[type]["success"]} />);
      dispatch("SUCCESS");
    } catch (error) {
      dispatch("ERROR");

      toast({
        theme: "error",
        content:
          "Uh oh! There was some issue submitting your information. Please try again, and let the Design Systems team know if the issue persists."
      });
    }
  };

  const handleClose = () => {
    setFormIsDirty(false);
    onClose && onClose();

    if (state === "success") {
      setTimeout(() => {
        setContent(formComponent);
        dispatch("RESET");
      }, 800);
    }
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={handleClose}
      closeButtonLabel="Close drawer"
      disableCloseOnClickOutside
      id="right-drawer"
      zIndex={900}
    >
      <form
        onSubmit={handleSubmit}
        css={`
          display: flex;
          flex-direction: column;
          height: 100%;
          margin: 0;
        `}
      >
        <Drawer.Header
          title={title}
          borderBottom={500}
          borderColor="container.border.base"
          pb={400}
        />

        <Drawer.Content>{content}</Drawer.Content>

        <Box
          py={400}
          px={450}
          borderTop={500}
          borderColor="container.border.base"
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
        >
          {state === "success" ? (
            <Button appearance="primary" width={1} onClick={handleClose}>
              Close
            </Button>
          ) : (
            <LoaderButton
              isLoading={state === "loading"}
              appearance="primary"
              width={1}
              type="submit"
              loaderLabel="Loading..."
            >
              Submit
            </LoaderButton>
          )}
        </Box>
      </form>
    </Drawer>
  );
};

export default IntakeDrawer;
