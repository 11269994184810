import React from "react";
import { MDXProvider } from "@mdx-js/react";
import Heading from "components/AnchoredHeading";
import Text from "components/global/Text";
import Table from "components/Table";
import UnorderedList from "components/mdx/UnorderedList";
import Pre from "components/mdx/Pre";
import Image from "components/mdx/Image";
import SystemProps from "components/racine/SystemProps";
import PropTable from "components/racine/PropTable";
import Grid from "components/Grid";
import Box from "components/global/Box";
import SpaceTag from "components/token-tags/SpaceTag";
import ColorTag from "components/token-tags/ColorTag";
import TypeTag from "components/token-tags/TypeTag";
import FontWeightTag from "components/token-tags/FontWeightTag";
import ComponentTag from "components/token-tags/ComponentTag";
import PropTag from "components/token-tags/PropTag";
import Youtube from "components/Youtube";
import {
  UnorderedList as UnorderedListBase,
  OrderedList as OrderedListBase
} from "components/Lists";
import { Icon, KeyboardKey, Stack, Banner } from "@sproutsocial/racine";
import GroupCards from "components/mdx/GroupCards";
import Link from "components/Link";
import Callout from "components/Callout";
import { withPrefix } from "gatsby";
import Directives from "components/global/Directives";
import ImageGrid from "components/ImageGrid";
import Accordion from "components/Accordion";
import Scorecard from "components/scorecard";

const components = {
  h1: Heading.h1,
  h2: Heading.h2,
  h3: Heading.h3,
  h4: Heading.h4,
  h5: Heading.h5,
  h6: Heading.h6,
  p: Text.p,
  table: Table,
  Table,
  ul: UnorderedList,
  pre: Pre,
  img: Image,
  SystemProps: SystemProps,
  PropTable: PropTable,
  grid: Grid,
  Grid,
  box: Box,
  Box,
  text: Text,
  Text,
  Heading: Heading,
  Type: TypeTag,
  Color: ColorTag,
  Weight: FontWeightTag,
  ColorTag,
  TypeTag,
  SpaceTag,
  PropTag,
  FontWeightTag,
  ComponentTag,
  Youtube: Youtube,
  unorderedlist: UnorderedListBase,
  UnorderedListBase,
  UnorderedList: UnorderedList,
  OrderedList: OrderedListBase,
  Icon,
  Stack,
  Lead: Text.lead,
  P: Text.p,
  Group: GroupCards,
  Link,
  Callout,
  withPrefix,
  Image,
  Directives,
  Directive: Directives.Directive,
  Do: Directives.Do,
  Dont: Directives.Dont,
  KeyboardKey,
  ImageGrid,
  Accordion,
  Banner,
  Scorecard,
  Scorecard_Issue: Scorecard.Issue,
  Scorecard_PlannedEnhancement: Scorecard.PlannedEnhancement
};

const Provider = (props) => <MDXProvider components={components} {...props} />;

const MDX = {
  Provider
};

export default MDX;
