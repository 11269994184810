import Text from "components/global/Text";

const Heading = Text.withComponent("h1");

Heading.defaultProps = {
  typography: 600,
  color: "text.headline"
};

Heading.h1 = Heading.withComponent("h1");
Heading.h2 = Heading.withComponent("h2");
Heading.h3 = Heading.withComponent("h3");
Heading.h4 = Heading.withComponent("h4");
Heading.h5 = Heading.withComponent("h5");
Heading.h6 = Heading.withComponent("h6");

Heading.defaultProps = {
  // h3
  typography: 800,
  responsiveType: 700,
  color: "text.headline"
};

Heading.h1.defaultProps = {
  typography: 900,
  responsiveType: 800,
  color: "text.headline"
};

Heading.h2.defaultProps = {
  typography: 800,
  responsiveType: 700,
  color: "text.headline"
};

Heading.h3.defaultProps = {
  typography: 700,
  responsiveType: 600,
  color: "text.headline",
  mb: 450
};

Heading.h4.defaultProps = {
  typography: 600,
  responsiveType: 500,
  color: "text.headline",
  mb: 400
};

Heading.h5.defaultProps = {
  typography: 400,
  color: "text.headline"
};

Heading.h6.defaultProps = {
  typography: 300,
  color: "text.headline"
};

export default Heading;
