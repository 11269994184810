import styled from "styled-components";
import Text from "../Text";
export var Container = styled(Text).withConfig({
  displayName: "styles__Container",
  componentId: "sc-19ypa4h-0"
})(["font-variant-numeric:tabular-nums;"]);
export var AbbrContainer = styled(Text).withConfig({
  displayName: "styles__AbbrContainer",
  componentId: "sc-19ypa4h-1"
})(["font-variant-numeric:tabular-nums;border-bottom:1px dotted ", ";"], function (props) {
  return props.theme.colors.container.border.base;
});