import React, { useState, useEffect } from "react";
import { localStorageSet, localStorageGet } from "utils/js";

const SeedsContext = React.createContext();

export const SeedsContextProvider = (props) => {
  const defaultCodeBackground = localStorageGet("codeThemeBG") || "neutral.0";
  const [codeBackground, setCodeBackground] = useState(defaultCodeBackground);

  const defaultCodeMode = localStorageGet("codeMODE") || "";
  const [codeMode, setCodeMode] = useState(defaultCodeMode);

  useEffect(() => {
    if (codeBackground === "neutral.1000") {
      setCodeMode("darkTheme");
      localStorageSet("codeMODE", "darkTheme");
    } else {
      setCodeMode("");
      localStorageSet("codeMODE", "");
    }
  }, [codeBackground]);

  const context = {
    codeTheme: {
      bg: codeBackground,
      setBackground: (color) => {
        setCodeBackground(color);
        localStorageSet("codeThemeBG", color);
      }
    },
    codeMode: {
      mode: codeMode
    }
  };

  return <SeedsContext.Provider value={context} {...props} />;
};

export default SeedsContext;
