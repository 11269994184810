import { useLayoutEffect, useState } from "react";

export const useWidth = (container) => {
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    if (container.current) {
      setWidth(container.current.getBoundingClientRect().width);
    }
  }, [container]);

  return width;
};
