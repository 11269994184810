import React from "react";
import styled from "styled-components";
import {
  Box,
  Icon,
  Text,
  Message,
  focusRing,
  Avatar
} from "@sproutsocial/racine";
import sproutCoffee from "docs/components/message/sprout-coffee-co.png";

const gridsizes = {
  large: {
    height: "240px",
    width: "100%"
  },
  small: {
    height: "140px",
    width: "100%"
  },
  condensed: {
    height: "48px",
    width: "48px"
  }
};

const ImgBtn = styled.button`
  ${(props) => gridsizes[props.gridsizes]}
  border-radius: 6px;
  cursor: pointer;
  border: none;
  background-image: url(${(props) => props.backgroundimage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: ${(props) => props.theme.space[350]};
  transition: box-shadow ${(props) => props.theme.duration.medium}
    ${(props) => props.theme.easing.ease_inout};
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &:hover {
    box-shadow: ${(props) => props.theme.shadows.medium};
  }

  &:last-child {
    margin-right: 0px;
  }

  &:focus {
    ${focusRing}
  }
`;

const MessageMetaContainer = styled.button`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: none;
  background: transparent;
  padding: ${(props) => props.theme.space[200]};
  transition: all ${(props) => props.theme.duration.fast}
    ${(props) => props.theme.easing.ease_inout};

  &:hover {
    background: ${(props) => props.theme.colors.neutral[100]};
  }

  &:focus {
    ${focusRing}
  }
`;

const ThumbFilm = styled(Box)`
  background: ${(props) => props.theme.colors.neutral[1000]};
  opacity: 0.8;
  width: 100%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  color: ${(props) => props.theme.colors.neutral[0]};
  text-align: center;
`;

const FocusedMessage = styled(Message)`
  ${focusRing}
  display: flex;
  flex-direction: column;

  &:focus {
    ${focusRing}
  }
`;

const SelectedMessage = styled(Message)`
  border: 1px solid ${(props) => props.theme.colors.neutral[1000]};

  &:focus {
    ${focusRing}
  }
`;

const findSize = (density) => {
  switch (density) {
    case "small":
      return "32px";
    case "condensed":
      return "20px";
    default:
      break;
  }
};

const TwitterGrid = (props) => (
  <Box display="flex">
    {props.density === "large" || props.density === "small" ? (
      props.images.map((image) => (
        <ImgBtn
          key={image}
          gridsizes={props.density}
          onClick={() => alert("lightbox")}
          backgroundimage={image}
        />
      ))
    ) : (
      <Box mr={350}>
        <ImgBtn
          onClick={() => alert("lightbox")}
          backgroundimage={props.images[0]}
          gridsizes={props.density}
          as={Box}
        >
          <ThumbFilm>+{props.images.length - 1}</ThumbFilm>
        </ImgBtn>
      </Box>
    )}
  </Box>
);

const MessageType = (props) => <Text {...props} />;

const MessageDate = (props) => <Text {...props} />;

const ProfileLine = (props) => (
  <Box display="flex" flexWrap="wrap" flexDirection="column">
    <Box display="flex" flexWrap="wrap" alignItems="center">
      <Icon
        name="twitter"
        size={props.density === "condensed" ? "mini" : "default"}
        color="network.twitter"
        aria-label="X"
      />
      <Text
        mx={300}
        color="neutral.1000"
        fontSize={props.density === "large" ? 300 : 200}
        fontWeight="semibold"
      >
        Sprout Coffee Co.
      </Text>
      <Text color="neutral.700" fontSize={200}>
        @mysproutcoffee
      </Text>
    </Box>
    {props.messageType ? (
      <Box display="flex">
        <MessageType>{props.messageType}</MessageType>
      </Box>
    ) : null}
  </Box>
);

const MessageMeta = (props) => (
  <MessageMetaContainer onClick={() => alert("layer")}>
    <Avatar
      name="Sprout Coffee Co."
      appearance="circle"
      src={sproutCoffee}
      size={findSize(props.density)}
    />
    <Box
      ml={props.density === "large" || props.density === "small" ? 350 : 300}
    >
      <ProfileLine messageType={props.messageType} density={props.density} />
    </Box>
  </MessageMetaContainer>
);

export {
  TwitterGrid,
  MessageMeta,
  FocusedMessage,
  SelectedMessage,
  MessageDate
};
