export const capitalize = (string) =>
  string.replace(/^\w/, (c) => c.toUpperCase());

export const localStorageSet = (key, value) => {
  if (typeof localStorage !== "undefined" && localStorage !== null) {
    localStorage.setItem(key, value);
  }
};

export const localStorageGet = (key) => {
  if (typeof localStorage !== "undefined" && localStorage !== null) {
    return localStorage.getItem(key);
  }

  return null;
};

// https://stackoverflow.com/a/44627252
export const valueAtKeyPath = (array, keyPath) =>
  keyPath.split(".").reduce((previous, current) => previous[current], array);

// https://stackoverflow.com/a/34890276
export const groupArrayBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item]
    }),
    {}
  );

export const dasherize = (string) =>
  string
    .trim()
    .replace(/([A-Z])/g, "-$1")
    .replace(/[-_\s]+/g, "-")
    .toLowerCase()
    .substr(1);

export const slugify = dasherize;

// Check for object value equivalency
// stolen from http://adripofjavascript.com/blog/drips/object-equality-in-javascript.html
export const isEquivalent = (a, b) => {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) {
    return false;
  }

  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  return true;
};

export const moveToEnd = (array, item) => {
  array.push(array.splice(array.indexOf(item), 1)[0]);
  return array;
};

export const moveToBeginning = (array, item) => {
  const index = array.indexOf(item);

  if (index) {
    array.splice(index, 1);
    array.unshift(item);
  }

  return array;
};

export const objectFromEntries = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

export const isProduction = () => {
  return process.env.NODE_ENV === "production";
};

export const trackEvent = (action, { category, label }) => {
  if (!isProduction()) {
    console.log("🎯 Tracking event: ", {
      action,
      category,
      label: label || window.location.pathname
    });
  }

  if (window.gtag && isProduction()) {
    window.gtag("event", action, {
      event_category: category,
      event_label: label || window.location.pathname,
      transport_type: "beacon"
    });
  }
};

export const isBrowserEnvironment = typeof window !== "undefined";
