import React from "react";
import Link from "components/Link";
import Box from "components/global/Box";
import Grid from "components/Grid";
import { UnorderedList } from "components/Lists";
import NavHeading from "components/navigation/NavHeading";

const ProductMenu = (props) => {
  return (
    <React.Fragment>
      <Box width={1 / 3}>
        <NavHeading>App-wide</NavHeading>

        <Grid>
          <Box width={1 / 2}>
            <UnorderedList unstyled mb={400}>
              <li>
                <Link to="/patterns/accessibility">Accessibility</Link>
              </li>
              <li>
                <Link to="/patterns/contextual-messaging">
                  Contextual messaging
                </Link>
              </li>
              <li>
                <Link to="/patterns/date-and-time">Date & time</Link>
              </li>
              <li>
                <Link to="/patterns/filters">Filters</Link>
              </li>
              <li>
                <Link to="/patterns/forms">Forms</Link>
              </li>
              <li>
                <Link to="/patterns/layout">Layout</Link>
              </li>
              <li>
                <Link to="/patterns/message-feeds">Message feeds</Link>
              </li>
              <li>
                <Link to="/patterns/navigation">Navigation</Link>
              </li>
            </UnorderedList>
          </Box>

          <Box width={1 / 2}>
            <UnorderedList unstyled>
              <li>
                <Link to="/patterns/notifications">Notifications</Link>
              </li>
              <li>
                <Link to="/patterns/onboarding">Onboarding</Link>
              </li>
              <li>
                <Link to="/patterns/in-app-guide">In-app guide</Link>
              </li>
            </UnorderedList>
          </Box>
        </Grid>
      </Box>

      <Box width={1 / 3}>
        <NavHeading>Analytics</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/patterns/charts">Charts</Link>
          </li>
          <li>
            <Link to="/patterns/data-states">Data states</Link>
          </li>
          <li>
            <Link to="/patterns/dataviz">Data visualization</Link>
          </li>
          <li>
            <Link to="/patterns/source-tables">Source tables</Link>
          </li>
          <li>
            <Link to="/patterns/summary-tables">Summary tables</Link>
          </li>
        </UnorderedList>
      </Box>
    </React.Fragment>
  );
};

export default ProductMenu;
