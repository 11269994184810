import React, { useEffect } from "react";
import styled from "styled-components";
import docsearch from "@docsearch/js";
import { Button, Icon } from "@sproutsocial/racine";

const Container = styled.div`
  .DocSearch-Button {
    display: none;
  }
`;

const Search = () => {
  useEffect(() => {
    docsearch({
      container: "#docsearch",
      indexName: "sproutsocial_seeds",
      appId: "CRYDQLZXSR",
      apiKey: "b9776f30bd34f50fe8f19fcf5354bfb7",
      placeholder: "Search Seeds"
    });
  }, []);

  const clickSearchButton = () => {
    const button = document.getElementsByClassName("DocSearch-Button")[0];
    button && button.click();
  };

  return (
    <React.Fragment>
      <Button
        appearance="pill"
        onClick={clickSearchButton}
        aria-label="Search Seeds"
      >
        <Icon name="magnifying-glass-outline" color="neutral.600" />
      </Button>

      <Container id="docsearch" />
    </React.Fragment>
  );
};

export default Search;
