import styled from "styled-components";
import {
  variant,
  space,
  color,
  fontSize,
  fontWeight,
  fontFamily,
  borders,
  borderColor,
  textAlign,
  width
} from "styled-system";
import media from "utils/media";

const typeStyle = variant({
  key: "typography",
  prop: "typography"
});

const responsiveTypeStyle = variant({
  key: "typography",
  prop: "responsiveType"
});

const Text = styled.div`
  ${space}
  ${color}
  ${fontSize}
  ${fontWeight}
  ${fontFamily}
  ${typeStyle}
  ${borders}
  ${borderColor}
  ${textAlign}
  ${width}

  text-decoration: ${(p) => (p.underlined ? "underline" : "none")};

  ${(p) =>
    p.uppercase &&
    `
    text-transform: uppercase;
    letter-spacing: 0.1em;
  `}

  ${media.small`
    ${responsiveTypeStyle}
  `}
`;

Text.defaultProps = {
  typography: "400"
};

Text.p = Text.withComponent("p");

Text.span = Text.withComponent("span");

Text.lead = Text.withComponent("p");

Text.lead.defaultProps = {
  typography: "600"
};

export default Text;
