var defaultPurple = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.purple"
};
var suggestion = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.blue"
};
var passive = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.neutral"
};
var primary = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.blue"
};
var secondary = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.yellow"
};
var common = {
  color: "colors.text.inverse",
  background: "colors.aqua.600"
};
var approval = {
  color: "colors.text.body",
  background: "colors.container.background.decorative.orange"
};

//Deprecated former "types"

/**
 * @deprecated Use badgeColor instead
 */
export var legacyBadgeColors = {
  primary: primary,
  secondary: secondary,
  passive: passive,
  common: common,
  approval: approval,
  default: defaultPurple,
  suggestion: suggestion
};
export var badgeColors = {
  green: "green",
  blue: "blue",
  purple: "purple",
  yellow: "yellow",
  orange: "orange",
  red: "red",
  neutral: "neutral",
  magenta: "magenta",
  pink: "pink",
  aqua: "aqua",
  teal: "teal"
};