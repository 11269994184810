import React from "react";
import Link from "components/Link";
import Box from "components/global/Box";
import { UnorderedList } from "components/Lists";
import NavHeading from "components/navigation/NavHeading";

const VisualMenu = (props) => {
  return (
    <>
      <Box width={[1 / 3]}>
        <NavHeading>Core</NavHeading>

        <UnorderedList unstyled>
          <li>
            <Link to="/visual/border">Border</Link>
          </li>
          <li>
            <Link to="/visual/brand-typography">Brand typography</Link>
          </li>
          <li>
            <Link to="/visual/color">Color</Link>
          </li>
          <li>
            <Link to="/visual/elevation">Elevation</Link>
          </li>
          <li>
            <Link to="/visual/logo">Logo system</Link>
          </li>
          <li>
            <Link to="/visual/motion">Motion</Link>
          </li>
          <li>
            <Link to="/visual/product-typography">Product typography</Link>
          </li>
          <li>
            <Link to="/visual/space">Space</Link>
          </li>
        </UnorderedList>
      </Box>

      <Box width={[1 / 3]}>
        <NavHeading>Art direction</NavHeading>

        <UnorderedList unstyled mb={400}>
          <li>
            <Link to="/visual/frame-library">Frame library</Link>
          </li>
          <li>
            <Link to="/visual/dataviz">Data visualization</Link>
          </li>
          <li>
            <Link to="/visual/illustration">Illustration</Link>
          </li>
          <li>
            <Link to="/visual/investor-relations">Investor relations</Link>
          </li>
          <li>
            <Link to="/visual/photography">Photography</Link>
          </li>
          <li>
            <Link to="/visual/video">Video</Link>
          </li>
        </UnorderedList>
      </Box>
    </>
  );
};

export default VisualMenu;
