var hasOwnProperty = function hasOwnProperty(obj, property) {
  return Object.prototype.hasOwnProperty.call(obj, property);
};
var hasChildren = function hasChildren(node) {
  return hasOwnProperty(node, "children");
};
var hasProps = function hasProps(node) {
  return hasOwnProperty(node, "props");
};
var reduceNodeToString = function reduceNodeToString(previous, current) {
  return previous.length ? "".concat(previous, " ").concat(innerText(current)) : innerText(current);
};
var innerText = function innerText(node) {
  if (node === null || typeof node === "undefined" || typeof node === "boolean") {
    return "";
  } else if (typeof node === "string") {
    return node;
  } else if (typeof node === "number") {
    return node.toString();
  } else if (Array.isArray(node)) {
    return node.reduce(reduceNodeToString, "");
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - hasProps checks for props, but may be able to fix this
  } else if (hasProps(node) && hasChildren(node.props)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - how can we make sure ts knows this is ok?
    return innerText(node.props.children);
  }
  return "";
};
export default innerText;