import styled, { css, keyframes } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { visuallyHidden } from "@sproutsocial/seeds-react-mixins";
var getSize = function getSize(size) {
  var spinnerSize = Math.round(size * 1);
  var borderWidth = Math.round(size * 0.1);
  return css(["width:", "px;height:", "px;box-shadow:", ";&:after{width:", "px;height:", "px;box-sizing:border-box;border-width:", "px;}.no-cssanimations &::after{background-size:", "px;}"], size, size, function (_ref) {
    var theme = _ref.theme;
    return "0 0 0 2px ".concat(theme.colors.neutral[600], ", \n      inset 0 0 0 ").concat(borderWidth + 2, "px ").concat(theme.colors.neutral[600]);
  }, spinnerSize, spinnerSize, borderWidth, spinnerSize);
};
var spin = keyframes(["from{transform:translate(-50%,-50%) rotate(0deg);}to{transform:translate(-50%,-50%) rotate(360deg);}"]);
var delayAnimation = keyframes(["0%{opacity:0;}80%{opacity:0;}100%{opacity:1;}"]);
var Container = styled.div.attrs({
  className: "Loader"
}).withConfig({
  displayName: "styles__Container",
  componentId: "sc-1edimrk-0"
})(["position:relative;margin:0 auto;padding:0;overflow:hidden;border-radius:100%;animation:", ";&:after{position:absolute;top:50%;left:50%;background:transparent;border-style:solid;border-radius:100%;content:\"\";transition:opacity 250ms;border-color:", ";animation:", ";}", " ", ""], function (props) {
  return props.delay ? css(["", " 2s 1;"], delayAnimation) : "none";
}, function (_ref2) {
  var theme = _ref2.theme;
  return "".concat(theme.colors.neutral[0], " ").concat(theme.colors.neutral[0], " ").concat(theme.colors.neutral[600], " ").concat(theme.colors.neutral[600]);
}, function (props) {
  return props.delay ? css(["", " 2.25s infinite linear,", " 2s 1"], spin, delayAnimation) : css(["", " 2.25s infinite linear"], spin);
}, function (props) {
  return props.small ? getSize(20) : getSize(40);
}, COMMON);
export var Text = styled.div.withConfig({
  displayName: "styles__Text",
  componentId: "sc-1edimrk-1"
})(["", ""], visuallyHidden);
export default Container;