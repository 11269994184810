import { useState, useLayoutEffect, type RefObject } from "react";

interface DOMRectObject {
  x: number;
  y: number;
  width: number;
  height: number;
  top: number;
  right: number;
  bottom: number;
  left: number;
}
const initialBounds = Object.freeze({
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});

export function useMeasure<TElement extends Element>(ref: RefObject<TElement>) {
  const [bounds, setContentRect] =
    useState<Readonly<DOMRectObject>>(initialBounds);

  useLayoutEffect(() => {
    const element = ref.current;

    if (
      !element ||
      // in non-browser environments (e.g. Jest tests) ResizeObserver is not defined
      !("ResizeObserver" in window)
    ) {
      return;
    }

    const resizeObserver = new ResizeObserver(([entry]) => {
      const { x, y, width, height, top, right, bottom, left } =
        entry.contentRect;
      setContentRect({
        x,
        y,
        width,
        height,
        top,
        right,
        bottom,
        left,
      });
    });
    resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [ref]);

  return bounds;
}
