import React from "react";
import { createGlobalStyle } from "styled-components";
import Typography from "typography";
import theme from "utils/theme";

const typography = new Typography({
  includeNormalize: false,
  baseFontSize: "18px",
  baseLineHeight: 1.625,
  bodyFontFamily: theme.fontFamily.body,
  headerFontFamily: theme.fontFamily.body,
  scaleRatio: 2.4,
  bodyColor: theme.colors.text.body,
  headerWeight: theme.fontWeights.extrabold,
  bodyWeight: theme.fontWeights.normal,
  boldWeight: theme.fontWeights.bold,
  overrideStyles: ({ adjustFontSizeTo, rhythm }, options, styles) => ({
    body: {
      "-webkit-font-smoothing": "antialiased"
    },
    "h1,h3,h4,h5,h6": {
      marginTop: rhythm(1)
    },
    h1: {
      ...adjustFontSizeTo(theme.typography[900].fontSize)
    },
    h2: {
      ...adjustFontSizeTo(theme.typography[800].fontSize)
    },
    h3: {
      ...adjustFontSizeTo(theme.typography[700].fontSize)
    },
    h4: {
      ...adjustFontSizeTo(theme.typography[600].fontSize)
    },
    h5: {
      ...adjustFontSizeTo(theme.typography[400].fontSize)
    },
    h6: {
      ...adjustFontSizeTo(theme.typography[200].fontSize)
    },
    a: {
      WebkitTextDecorationSkip: "unset",
      color: theme.colors.link.base,
      textDecoration: "none"
    },
    "a:hover,a:active": {
      textDecoration: "underline"
    },
    "a.selected": {
      textDecoration: "underline"
    },
    ul: {
      marginLeft: "1.2rem"
    },
    "li > ul": {
      marginLeft: "1.2rem"
    },
    ol: {
      marginLeft: "1.2rem"
    },
    "li > ol": {
      marginLeft: "1.2rem"
    },
    "ol > li:last-child, ul > li:last-child": {
      marginBottom: 0
    },
    "li *:last-child": null,
    table: {
      marginBottom: rhythm(1.5)
    },
    hr: {
      margin: `${rhythm(1)} 0 ${rhythm(2)}`
    },
    blockquote: {
      borderLeft: `4px solid ${theme.colors.neutral[200]}`,
      color: theme.colors.neutral[600],
      marginTop: 0,
      marginRight: 0,
      marginLeft: 0,
      paddingLeft: `calc(${rhythm(1 / 2)} - 1px)`
    },
    pre: {
      fontSize: "1em"
    },
    code: {
      lineHeight: 1.5,
      fontSize: "0.75em"
    },
    ".lead": {
      ...adjustFontSizeTo("24px")
    },
    ".gatsby-resp-image-figcaption": {
      marginTop: "8px",
      color: theme.colors.neutral[600],
      ...adjustFontSizeTo("16px")
    },
    "p img": {
      width: "100%"
    },
    video: {
      marginBottom: rhythm(1)
    }
  })
});

const BaseStyles = createGlobalStyle`
  ${typography.toString()} 
`;

const Overrides = createGlobalStyle`
  .keybutton {
    ${(props) => props.theme.typography[300]}
    font-family: ${(props) => props.theme.fontFamily.mono};
    display: inline-block;
    padding: ${(props) =>
      `${props.theme.space[200]} ${props.theme.space[300]}`};
    border-radius: ${(props) => props.theme.space[200]};
    border-bottom: 2px solid ${(props) => props.theme.colors.neutral[700]};
    background-color: ${(props) => props.theme.colors.neutral[1000]};
    color: ${(props) => props.theme.colors.neutral[0]};
  }

  pre {
    font-family: ${(props) => props.theme.fontFamily.mono};
    font-feature-settings: normal;
    overflow: auto;
    background-color: ${(props) => props.theme.colors.neutral[900]};
    border-radius: ${(props) => props.theme.radii.outer};

    code {
      background-color: transparent;
      padding: ${(props) =>
        `${props.theme.space[350]} ${props.theme.space[400]}`};
      border: none;
      width: 100%;
    }
  }

  code {
    font-feature-settings: normal;
    font-family: ${(props) => props.theme.fontFamily.mono};
    background-color: ${(props) => props.theme.colors.neutral[100]};
    padding: ${(props) =>
      `${props.theme.space[100]} ${props.theme.space[200]}`};
    display: inline-block;
    word-wrap: normal;
    border-radius: ${(props) => props.theme.radii.inner};
  }
`;

const TypographyStyles = () => (
  <React.Fragment>
    <BaseStyles />
    <Overrides />
  </React.Fragment>
);

export default TypographyStyles;
