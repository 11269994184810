import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.tbody.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1s8pba9-0"
})(["border-bottom:1px solid ", ";", ""], function (props) {
  return props.theme.colors.container.border.base;
}, COMMON);
export var Detail = styled.tr.withConfig({
  displayName: "styles__Detail",
  componentId: "sc-1s8pba9-1"
})(["display:none;", ""], function (props) {
  return props.isExpanded && css(["display:table-row;"]);
});
export var Trigger = styled.div.withConfig({
  displayName: "styles__Trigger",
  componentId: "sc-1s8pba9-2"
})(["cursor:pointer;"]);
export default Container;