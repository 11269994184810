import React from "react";
import useSWR from "swr";
import { Text, Icon, Box } from "@sproutsocial/racine";
import Markdown from "react-markdown";

const fetcher = async (url, context) => {
  const body = { context };
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body)
  });
  const result = await response.json();
  return result;
};

const Divider = (props) => (
  <Box
    borderTop={500}
    borderColor="container.border.base"
    height="1px"
    ml={-450}
    mr={-450}
    {...props}
  />
);

const Issues = ({ mdxIssues, status, context, ...rest }) => {
  const { data: githubIssues } = useSWR(
    ["/.netlify/functions/get-issues", context],
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const hasMdxIssues = mdxIssues && mdxIssues.length > 0;
  const hasGithubIssues = githubIssues && githubIssues.length > 0;
  const hasIssues = hasGithubIssues || hasMdxIssues;

  return (
    <Box>
      {hasIssues && (
        <Text
          as="div"
          color="text.headline"
          fontWeight="semibold"
          fontSize={200}
          mb={300}
        >
          <Icon
            name="triangle-exclamation-outline"
            mr={300}
            mt={-200}
            color={status === "dormant" ? "icon.error" : "icon.warning"}
          />
          Issues
        </Text>
      )}

      {mdxIssues.map((item, i) => (
        <Box
          borderTop={i !== 0 ? 500 : null}
          borderColor="container.border.base"
          pt={i !== 0 ? 300 : 0}
          mt={300}
          key={i}
        >
          <Text color="text.body">{item}</Text>
        </Box>
      ))}

      {hasGithubIssues && (
        <Box
          {...(hasMdxIssues
            ? {
                borderTop: 500,
                borderColor: "container.border.base",
                mt: 300
              }
            : {})}
        >
          {githubIssues.map((issue, i) => (
            <React.Fragment key={i}>
              <Box
                borderTop={i !== 0 ? 500 : null}
                borderColor="container.border.base"
                pt={i !== 0 ? 300 : 0}
                mt={300}
                key={i}
              >
                {issue.labels.includes("Type: Issue") && (
                  <Text as="div" fontWeight="semibold" mb={100}>
                    {issue.title}
                  </Text>
                )}
                <Text
                  as="div"
                  css={`
                    p:last-of-type {
                      margin: 0;
                    }
                  `}
                >
                  <Markdown>{issue.description}</Markdown>
                </Text>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      )}

      {hasIssues && <Divider my={400} />}
    </Box>
  );
};

export default Issues;
