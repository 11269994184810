import React from "react";
import styled from "styled-components";
import Grid from "/src/components/Grid";
import Image from "/src/components/mdx/Image";
import { Box, Icon, Text } from "@sproutsocial/racine";
import media from "utils/media";

const Container = styled(Box)`
  width: ${({ width }) => (width ? width : "50%")};
  ${media.small`
    flex: 1 1 100%;
  `}
  display: flex;
  flex-direction: column;
`;

const DirectiveHeadline = styled(Box)`
  color: ${(props) => props.theme.colors.text.base};
  padding: ${(props) => props.theme.space[300]}
    ${(props) => props.theme.space[400]};
  font-weight: ${(props) => props.theme.fontWeights.extrabold};
  border-top-left-radius: ${(props) => props.theme.radii.outer};
  border-top-right-radius: ${(props) => props.theme.radii.outer};
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

const DirectiveBody = styled(Box)`
  padding: ${(props) => props.theme.space[400]};
  border: 1px solid ${(props) => props.theme.colors.neutral[200]};
  border-top: 0px;
  border-bottom-right-radius: ${(props) => props.theme.radii.outer};
  border-bottom-left-radius: ${(props) => props.theme.radii.outer};
  flex: 1 1 auto;
  img {
    width: 100%;
  }
`;

const DirectiveEmptyBody = styled(DirectiveBody)`
  display: flex;
  flex-direction: column;
  img {
    height: 192px;
    object-fit: contain;
  }
`;

const Directives = ({ children, ...rest }) => {
  return (
    <Grid mb={450} gutter={16} className="medium" {...rest}>
      {children}
    </Grid>
  );
};

Directives.Directive = ({ children, image, text, type, ...rest }) => {
  const name = type === "do" ? "circle-check-solid" : "circle-x-outline";
  const bgColor = type === "do" ? "teal.50" : "orange.100";

  return (
    <Container {...rest}>
      <DirectiveHeadline bg={bgColor}>
        <Icon mr={300} name={name} />
        {type}
      </DirectiveHeadline>
      {children ? (
        <DirectiveBody>{children}</DirectiveBody>
      ) : (
        <DirectiveEmptyBody>
          {image && <Image src={image} />}
          {text && <Text>{text}</Text>}
        </DirectiveEmptyBody>
      )}
    </Container>
  );
};

Directives.Do = ({ children, ...rest }) => (
  <Directives.Directive type="do" {...rest}>
    {children}
  </Directives.Directive>
);

Directives.Dont = ({ children, ...rest }) => (
  <Directives.Directive type="don't" {...rest}>
    {children}
  </Directives.Directive>
);

export default Directives;
