import styled, { createGlobalStyle, css } from "styled-components";
import moment from "moment";
import { disabled } from "@sproutsocial/seeds-react-mixins";
import Box from "../Box";

/*
 * Partial list of modifiers given to renderDayContents by airbnb/react-dates. There may be more.
 *
 * today, blocked, blocked-calendar, blocked-out-of-range, highlighted-calendar, valid,
 * selected, selected-start, selected-end, blocked-minimum-nights, selected-span, last-in-range,
 * hovered, hovered-span, hovered-offset, after-hovered-start, first-day-of-week, last-day-of-week,
 * hovered-start-first-possible-end, hovered-start-blocked-minimum-nights, before-hovered-end,
 * no-selected-start-before-selected-end, selected-start-in-hovered-span, selected-start-no-selected-end,
 * selected-end-no-selected-start
 *
 */
var isSelected = function isSelected(modifiers) {
  return modifiers.has("selected-span") || modifiers.has("selected") || modifiers.has("selected-start") || modifiers.has("selected-end") || modifiers.has("hovered-span") || modifiers.has("after-hovered-start");
};
var isOutOfRange = function isOutOfRange(modifiers) {
  return modifiers.has("blocked-out-of-range");
};
var isHoveredAndInRange = function isHoveredAndInRange(modifiers) {
  return modifiers.has("hovered") && !modifiers.has("blocked-out-of-range");
};
var shouldHaveLeftPill = function shouldHaveLeftPill(modifiers, day) {
  if (!isSelected(modifiers)) {
    return false;
  }
  if (modifiers.has("selected") || modifiers.has("selected-start") || modifiers.has("first-day-of-week") || day.isSame(moment(day).startOf("month"), "day")) {
    return true;
  }
  return false;
};
var shouldHaveRightPill = function shouldHaveRightPill(modifiers, day) {
  if (!isSelected(modifiers)) {
    return false;
  }
  if (modifiers.has("selected") || modifiers.has("selected-end") || modifiers.has("last-day-of-week") || day.isSame(moment(day).endOf("month"), "day")) {
    return true;
  }
  return false;
};
export var CalendarDay = styled(Box).withConfig({
  displayName: "styles__CalendarDay",
  componentId: "sc-uyf25l-0"
})(["", ";"], function (_ref) {
  var modifiers = _ref.modifiers,
    day = _ref.day,
    theme = _ref.theme;
  if (isSelected(modifiers)) {
    return css(["background-color:", ";color:", ";margin-left:", ";margin-right:", ";border-top-left-radius:", ";border-bottom-left-radius:", ";border-top-right-radius:", ";border-bottom-right-radius:", ";"], theme.colors.container.background.selected, theme.colors.text.inverse, shouldHaveLeftPill(modifiers, day) && theme.space[200], shouldHaveRightPill(modifiers, day) && theme.space[200], shouldHaveLeftPill(modifiers, day) && theme.radii.pill, shouldHaveLeftPill(modifiers, day) && theme.radii.pill, shouldHaveRightPill(modifiers, day) && theme.radii.pill, shouldHaveRightPill(modifiers, day) && theme.radii.pill);
  }
  if (isHoveredAndInRange(modifiers)) {
    return css(["margin:0 ", ";border-radius:", ";border:1px solid ", ";"], theme.space[200], theme.radii.pill, theme.colors.container.border.selected);
  } else if (isOutOfRange(modifiers)) {
    return css(["color:", ";", ";"], theme.colors.text.subtext, disabled);
  }
});
export var ReactDatesCssOverrides = createGlobalStyle([".DayPicker{box-sizing:content-box;font-weight:", ";font-family:", ";&_transitionContainer{height:228px !important;}&_weekHeader{color:", ";border-bottom:1px solid ", ";top:26px;width:230px;&_ul{padding-left:10px;}}&_weekHeaders__horizontal{margin-left:0}&_weekHeader_li{", " color:", ";font-weight:", ";margin:0;}&__horizontal{box-shadow:none;background:", ";}}.CalendarDay{background-color:transparent;&__selected,&__selected_span,&:hover{background-color:transparent;}&__default{color:", ";}&__default,&__default:hover{border:none;color:", ";}}.CalendarMonth{", " background:", ";padding:0 15px;&_caption{", " color:", ";padding-top:0;background:", ";strong{font-weight:", ";}}&_table{line-height:21.333px;tr{vertical-align:middle;}td{padding:0;border-bottom:none;}}}.CalendarMonthGrid{background:", ";}.DayPickerNavigation_button__horizontal{color:", ";top:-4px;padding:7px 8px;position:absolute;line-height:0.78;border-radius:9999px;border:none;background:", ";&:nth-child(1){left:22px;}&:nth-child(2){right:22px;}&:hover{background:", ";}}"], function (_ref2) {
  var theme = _ref2.theme;
  return theme.fontWeights.normal;
}, function (props) {
  return props.theme.fontFamily;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.text.headline;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.container.border.base;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.typography[200];
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.text.subtext;
}, function (_ref7) {
  var theme = _ref7.theme;
  return theme.fontWeights.semibold;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.container.background.base;
}, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.text.body;
}, function (_ref10) {
  var theme = _ref10.theme;
  return theme.colors.text.body;
}, function (_ref11) {
  var theme = _ref11.theme;
  return theme.typography[200];
}, function (_ref12) {
  var theme = _ref12.theme;
  return theme.colors.container.background.base;
}, function (_ref13) {
  var theme = _ref13.theme;
  return theme.typography[200];
}, function (_ref14) {
  var theme = _ref14.theme;
  return theme.colors.text.headline;
}, function (_ref15) {
  var theme = _ref15.theme;
  return theme.colors.container.background.base;
}, function (_ref16) {
  var theme = _ref16.theme;
  return theme.fontWeights.semibold;
}, function (_ref17) {
  var theme = _ref17.theme;
  return theme.colors.container.background.base;
}, function (_ref18) {
  var theme = _ref18.theme;
  return theme.colors.button.pill.text.base;
}, function (_ref19) {
  var theme = _ref19.theme;
  return theme.colors.button.pill.background.base;
}, function (_ref20) {
  var theme = _ref20.theme;
  return theme.colors.button.pill.background.hover;
});