import React from "react";
import styled from "styled-components";

const Container = styled.span`
  cursor: pointer;

  ${(p) =>
    p.block &&
    `
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

class CopyContent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
      copyText: this.props.copy || this.props.children
    };
  }

  componentDidUpdate = (_, prevState) => {
    if (this.props.copy && prevState.copyText !== this.props.copy) {
      this.setState({ copyText: this.props.copy });
    }

    if (!this.props.copy && prevState.copyText !== this.props.children) {
      this.setState({ copyText: this.props.children });
    }
  };

  copyContent = (e) => {
    if (this.state.copied) return;
    const temp = document.createElement("input");
    const text = this.state.copyText || e.currentTarget.innerText;
    document.body.appendChild(temp);
    temp.value = text;
    temp.select();
    document.execCommand("copy");
    temp.remove();
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 500);
  };

  render() {
    const copied = this.state.copied;
    const successMessage = this.props.success || "Copied!";

    return (
      <Container
        title="Click to copy"
        onClick={this.copyContent}
        {...this.props}
      >
        {copied ? successMessage : this.props.children}
      </Container>
    );
  }
}

export default CopyContent;
