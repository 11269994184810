import * as React from "react";
import Container from "./styles";
import type { TypeBoxProps } from "./BoxTypes";

const Box = React.forwardRef<HTMLDivElement, TypeBoxProps>(
  ({ color, ...props }, ref) => {
    return (
      <Container
        {...props}
        ref={ref}
        // TODO: fix this type since `color` should be valid here. TS can't resolve the correct type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        color={color}
      />
    );
  }
);

Box.displayName = "Box";

export default Box;
