import React from "react";
import Text from "components/global/Text";
import Heading from "components/Heading";
import Grid from "components/Grid";
import Box from "components/global/Box";
import Card from "components/Card";
import { Icon } from "@sproutsocial/racine";

const LandingPageCard = (props) => {
  const cta = `View ${
    props.title !== "FAQ" ? props.title.toLowerCase() : props.title
  }`;

  return (
    <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 3]}>
      <Card
        to={props.to}
        title={
          <Heading.h2
            typography={700}
            mt={0}
            mb={400}
            dangerouslySetInnerHTML={{ __html: props.title }}
          />
        }
        content={
          <Grid gutter={0} justifyContent="space-between" flex="1">
            {props.excerpt && (
              <Box>
                <Text.p typography={400}>{props.excerpt}</Text.p>
              </Box>
            )}

            <Box display="flex" alignItems="flex-end" pt={500}>
              <Text.span
                typography={400}
                fontWeight="semibold"
                color="green.700"
              >
                <span dangerouslySetInnerHTML={{ __html: cta }} />
                <Icon name="arrow-right-solid" size="mini" mt="-2px" ml={300} />
              </Text.span>
            </Box>
          </Grid>
        }
        height="100%"
      />
    </Box>
  );
};

export default LandingPageCard;
