import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-8ntjxw-0"
})(["font-family:", ";", ";font-weight:", ";line-height:1;font-variant-numeric:tabular-nums;color:", ";", " ", ""], function (props) {
  return props.theme.fontFamily;
}, function (props) {
  return props.theme.typography[200];
}, function (props) {
  return props.theme.fontWeights.semibold;
}, function (props) {
  return props.overlimit ? props.theme.colors.text.error : props.theme.colors.text.subtext;
}, function (p) {
  return p.mini && css(["", ""], p.theme.typography[100]);
}, COMMON);
export default Container;