import React from "react";
import { Box, Text, Icon } from "@sproutsocial/racine";
import Link from "components/Link";
import figmaIcon from "pages/resources/images/figma-icon.svg";
import { trackEvent } from "utils/js";

const trackClick = () => {
  trackEvent("Click Figma link", { category: "scorecard" });
};

const Figma = ({ url, ...rest }) => {
  const isMissing = !url.startsWith("https");

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      {...rest}
    >
      <Text as="div" fontWeight="semibold" fontSize={200}>
        <img
          src={figmaIcon}
          alt=""
          css={`
            display: inline;
            margin: -3px 8px 0 0;
          `}
        />{" "}
        UI Kit counterpart
      </Text>

      {isMissing ? (
        <Icon name="circle-x-outline" color="icon.error" />
      ) : (
        <Link
          to={url}
          fontWeight="semibold"
          typography={300}
          onClick={trackClick}
          css={`
            display: inline-flex;
            align-items: center;
          `}
        >
          Figma{" "}
          <Icon name="squares-arrow-up-right-solid" size="mini" ml={300} />
        </Link>
      )}
    </Box>
  );
};

export default Figma;
