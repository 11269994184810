import styled, { css } from "styled-components";
import { COMMON, LAYOUT } from "@sproutsocial/seeds-react-system-props";
import Icon from "../Icon";
var Container = styled.div.withConfig({
  displayName: "styles__Container",
  componentId: "sc-r8d7e5-0"
})(function (props) {
  return css(["display:flex;overflow:hidden;align-items:center;justify-content:space-between;color:", ";border-radius:", ";font-family:", ";", " padding:", ";border:1px solid ", ";background-color:", ";a,button{font-weight:", ";color:", ";font-size:inherit;&:hover{color:", ";text-decoration:underline;}}> span:not(.Icon){display:block;}", " ", ""], props.theme.colors.text.body, props.theme.radii.outer, props.theme.fontFamily, props.theme.typography[200], props.theme.space[300], props.theme.colors.container.border[props.type], props.theme.colors.container.background[props.type], props.theme.fontWeights.semibold, props.theme.colors.text.body, props.theme.colors.text.body, COMMON, LAYOUT);
});
export default Container;
export var StyledBannerIcon = styled(Icon).withConfig({
  displayName: "styles__StyledBannerIcon",
  componentId: "sc-r8d7e5-1"
})(["align-self:flex-start;min-width:16px;"]);