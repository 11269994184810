function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
import { theme } from "@sproutsocial/seeds-react-theme";
var breakpoints = theme.breakpoints;
var LENGTH = breakpoints.length + 1;
export var normalizeResponsiveProp = function normalizeResponsiveProp(value) {
  if (["string", "number"].includes(_typeof(value))) {
    return Array(LENGTH).fill(value);
  }
  if (value.length) {
    switch (value.length) {
      case 1:
        return Array(LENGTH).fill(value[0]);
      case 2:
        return [value[0]].concat(_toConsumableArray(Array(LENGTH - 1).fill(value[1])));
      case 3:
        return [value[0], value[1]].concat(_toConsumableArray(Array(LENGTH - 2).fill(value[2])));
      case 4:
        return [value[0], value[1], value[2]].concat(_toConsumableArray(Array(LENGTH - 3).fill(value[3])));
      case 5:
        return value;
      default:
        throw new Error("Invalid responsive prop length: ".concat(JSON.stringify(value)));
    }
  } else {
    throw new Error("Invalid responsive prop type: ".concat(JSON.stringify(value)));
  }
};