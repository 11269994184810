import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Metadata = (props) => {
  const {
    title,
    description,
    article,
    hidden,
    children,
    location = {}
  } = props;
  const { pathname } = location;
  const data = useStaticQuery(query);

  const { defaultTitle, defaultDescription, titleTemplate, siteUrl } =
    data.site.siteMetadata;

  const seo = {
    title: title,
    description: description || defaultDescription,
    url: `${siteUrl}${pathname || "/"}`
  };

  return (
    <React.Fragment>
      <Helmet
        title={seo.title}
        titleTemplate={titleTemplate}
        defaultTitle={defaultTitle}
      >
        <meta name="description" content={seo.description} />

        {seo.url && <meta property="og:url" content={seo.url} />}

        {(article ? true : null) && (
          <meta property="og:type" content="article" />
        )}

        {seo.title && <meta property="og:title" content={seo.title} />}

        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}

        <meta name="twitter:card" content="summary" />

        {seo.title && <meta name="twitter:title" content={seo.title} />}

        {seo.description && (
          <meta name="twitter:description" content={seo.description} />
        )}

        {hidden && <meta name="robots" content="noindex,nofollow" />}

        {children}
      </Helmet>
    </React.Fragment>
  );
};

Metadata.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  hidden: false
};

export const query = graphql`
  query Metadata {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        titleTemplate
        siteUrl
      }
    }
  }
`;

export default Metadata;
