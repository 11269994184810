import React from "react";
import Box from "components/global/Box";
import Text from "components/global/Text";

const Callout = ({ color = "purple.600", title, message, children }) => {
  const textColor = color.substring(0, color.indexOf("."));

  return (
    <Box
      border={500}
      borderStyle="solid"
      borderColor="neutral.200"
      display="flex"
      borderRadius={500}
      mb={600}
      overflow="hidden"
    >
      <Box
        width={1 / 4}
        bg={color}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Text color={textColor + ".0"} fontWeight={800} typography="700">
          {title || "Pro tip"}
        </Text>
      </Box>
      <Box width={3 / 4} display="flex" alignItems="center" p={450}>
        <Text typography="300" fontWeight={600}>
          {children || message}
        </Text>
      </Box>
    </Box>
  );
};

export default Callout;
