import { useState, useEffect } from "react";

// Read more: https://joshwcomeau.com/react/the-perils-of-rehydration/

const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return children;
};

export default ClientOnly;
