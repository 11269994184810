import React from "react";
import useSWR from "swr";
import { Box, Text } from "@sproutsocial/racine";
import { isValid, formatDistanceToNow } from "date-fns";

const fetcher = async (url, path) => {
  const body = { path };
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(body)
  });
  const result = await response.text();
  return result;
};

const ScorecardDates = ({
  publishDate,
  lastModifiedDateLookupKey,
  ...rest
}) => {
  const { data: date } = useSWR(
    [
      "/.netlify/functions/github-modified-date",
      `/docs/${lastModifiedDateLookupKey}`
    ],
    fetcher,
    {
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );

  const modifiedDate = new Date(date);

  return (
    <Box display="flex" {...rest}>
      <Box mr={600}>
        <Text
          as="div"
          fontSize={200}
          fontWeight="semibold"
          color="text.headline"
        >
          Published
        </Text>
        <Text as="div" fontSize={200} color="text.body">
          {publishDate}
        </Text>
      </Box>

      <Box>
        <Text
          as="div"
          fontSize={200}
          fontWeight="semibold"
          color="text.headline"
        >
          Last modified
        </Text>
        <Text as="div" fontSize={200} color="text.body">
          {date && isValid(modifiedDate)
            ? formatDistanceToNow(new Date(modifiedDate), {
                addSuffix: true
              })
            : "–"}
        </Text>
      </Box>
    </Box>
  );
};

export default ScorecardDates;
