import styled, { css } from "styled-components";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { focusRing } from "@sproutsocial/seeds-react-mixins";
import { useInteractiveColor } from "../utils/useInteractiveColor";
var Container = styled.button.withConfig({
  displayName: "styles__Container",
  componentId: "sc-1mt4sof-0"
})(["position:relative;display:inline-flex;align-items:center;margin:0;line-height:1;outline:none;", " &:focus{", "}", " ", " ", " ", " ", ""], function (_ref) {
  var theme = _ref.theme;
  return css(["", " font-family:", ";font-weight:", ";border:1px solid ", ";border-radius:", ";color:", ";background:", ";padding:", " ", ";transition:all ", " ", ";"], theme.typography[200], theme.fontFamily, theme.fontWeights.normal, theme.colors.container.border.base, theme.radii[500], theme.colors.text.body, theme.colors.container.background.base, theme.space[200], theme.space[300], theme.duration.fast, theme.easing.ease_inout);
}, focusRing, function (_ref2) {
  var closeable = _ref2.closeable,
    theme = _ref2.theme;
  return closeable && css(["cursor:pointer;&:hover,&:active{box-shadow:", ";border:1px solid ", ";}"], theme.shadows.low, useInteractiveColor(theme.colors.container.border.base));
}, function (_ref3) {
  var theme = _ref3.theme,
    palette = _ref3.palette;
  return palette === "blue" && css(["color:", ";background:", ";border:1px solid ", ";&:hover,&:active{cursor:pointer;box-shadow:", ";border:1px solid ", ";}"], theme.colors.text.body, theme.colors.container.background.decorative.blue, theme.colors.container.border.decorative.blue, theme.shadows.low, useInteractiveColor(theme.colors.container.border.decorative.blue));
}, function (_ref4) {
  var disabled = _ref4.disabled,
    theme = _ref4.theme;
  return disabled && css(["opacity:0.4;cursor:not-allowed;&:hover,&:active{box-shadow:none;border:1px solid ", ";}"], theme.colors.container.border.base);
}, function (_ref5) {
  var valid = _ref5.valid,
    theme = _ref5.theme;
  return !valid && css(["color:", ";background:", ";border:1px solid ", ";&:hover{box-shadow:", ";border:1px solid ", ";}"], theme.colors.text.error, theme.colors.container.background.error, theme.colors.container.border.error, theme.shadows.low, useInteractiveColor(theme.colors.container.border.error));
}, COMMON);
export default Container;