import React from "react";
import styled, { css } from "styled-components";
import { ThemeProvider } from "styled-components";
import { SeedsContextProvider } from "components/global/Context";
import theme from "utils/theme";
import media from "utils/media";
import CSSReset from "components/global/CSSReset";
import TypographyStyles from "components/global/Typography";
import Metadata from "components/global/Metadata";
import GlobalStyles from "components/global/GlobalStyles";
import { Box, ToastContainer } from "@sproutsocial/racine";
import FullStoryScript from "components/FullStoryScript";
import MDX from "templates/MDX";
import Header from "components/navigation/Header";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding-top: ${(p) => p.theme.sizes.header.height}px;

  #site-header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    background: ${(p) => p.theme.colors.neutral[0]};
    overflow: visible;
    height: ${(p) => p.theme.sizes.header.height}px;
  }

  #sidebar {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    ${media.small`
      position: relative;
      top: 0;
      padding-bottom: 0;
      width: 100%;
    `}
  }

  #main {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    position: relative;
  }

  #sidebar + #main {
    width: calc(100% - ${(props) => props.theme.sizes.sidebar.minWidth}px);

    ${media.medium`
      width: calc(100% - ${(props) =>
        props.theme.sizes.sidebar.minWidthSmall}px);
    `}

    ${media.small`
      width: 100%;
    `}
  }

  #main > #content {
    position: relative;
  }

  #site-footer {
    width: 100%;
    background: ${(p) => p.theme.colors.neutral[100]};
    display: flex;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-column: full;
  }
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <SeedsContextProvider>
      <MDX.Provider>
        <Metadata />
        <CSSReset />
        <TypographyStyles />
        <GlobalStyles />
        <ToastContainer />
        <FullStoryScript />

        <Container>
          <Header />
          {children}
        </Container>
      </MDX.Provider>
    </SeedsContextProvider>
  </ThemeProvider>
);

const LAYOUTS = {
  default: "main",
  medium: "medium",
  wide: "full"
};

Layout.Grid = styled(Box)(
  ({
    theme,
    /* The default layout that child items should use in the grid */
    defaultLayout,
    /* Whether the grid should be sized based on its container or the browser's width */
    relativeToViewport
  }) => css`
    --maxWidth: ${theme.sizes.content.maxWidth}px;
    --padding: ${theme.sizes.content.padding}px;
    --sidebarWidth: ${theme.sizes.sidebar.minWidth}px;
    --bounds: ${relativeToViewport ? "100vw" : "100%"};
    --gutter: calc(var(--bounds) - var(--sidebarWidth) - var(--maxWidth));

    display: grid;

    /* Grid for pages with a sidebar. Note that on these pages, we do not allow content to span edge-to-edge, so we insert our padding columns before the "full" area starts  */
    grid-template-columns:
    /* First column: flexible gutter that centers the content */
      calc(var(--gutter) / 2)
      /* Gutter column */
      var(--padding)
      /* 4 columns for the "main" area */
      [full-start main-start medium-start] repeat(4, 1fr) [main-end]
      /* 5 columns for the "medium" area */
      1fr [medium-end]
      /* 6 columns for the "full" area */
      1fr [full-end]
      /* Gutter column */
      var(--padding)
      /* Last column: flexible gutter that centers the content */
      calc(var(--gutter) / 2);

    /* Below the medium breakpoint, we make the full, medium, and main areas the same width */
    ${media.medium`
    grid-template-columns:
      var(--padding)
      [full-start main-start medium-start] repeat(4, 1fr) [main-end medium-end full-end]
      var(--padding);
  `}

    .no-sidebar & {
      /* Grid for pages without a sidebar  */
      grid-template-columns:
      /* First column: flexible gutter that centers the content */
        [full-start] calc((var(--bounds) - var(--maxWidth)) / 2)
        /* Gutter column */
        var(--padding)
        /* Begin the "medium" area (5 columns) */
        [medium-start] 1fr
        /* 4 columns for the "main" area */
        [main-start] repeat(4, 1fr) [main-end]
        /* End the "medium" area (5 columns) */
        1fr [medium-end]
        /* Gutter column */
        var(--padding)
        /* Last column: flexible gutter that centers the content */
        calc((var(--bounds) - var(--maxWidth)) / 2) [full-end];

      /* Below the medium breakpoint we make the main and medium areas 4 columns instead of 5 and 6, respectively */
      ${media.medium`
      grid-template-columns:
        [full-start] var(--padding)
        0.8fr
        [main-start medium-start] repeat(4, 1fr) [main-end medium-end]
        0.8fr
        var(--padding) [full-end];
    `}

      /* Below the small breakpoint, we make the main area the same as the medium area */
    ${media.small`
      grid-template-columns:
        [full-start] var(--padding)
        [main-start medium-start] repeat(6, 1fr) [main-end medium-end]
        var(--padding) [full-end];
    `}
    }

    * {
      grid-column: ${LAYOUTS[defaultLayout || "default"]};
    }

    .wide {
      grid-column: full;
    }

    .medium {
      grid-column: medium;
    }

    ${media.medium`
    --padding: 32px;
  `}

    ${media.small`
    --padding: 24px;
  `}
  `
);

export default Layout;
