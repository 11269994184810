import React from "react";
import Grid from "components/Grid";
import Card from "components/LandingPageCard";

const GroupCards = ({ data, name }) => {
  return (
    <Grid className="wide algolia-ignore" mb={600}>
      {data.category.items
        .filter((node) => node.group.toLowerCase() === name.toLowerCase())
        .map((node) => (
          <Card
            to={node.slug}
            title={node.title}
            excerpt={node.excerpt}
            key={node.id}
          />
        ))}
    </Grid>
  );
};

export default GroupCards;
