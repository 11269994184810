import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { COMMON } from "@sproutsocial/seeds-react-system-props";
import { legacyBadgeColors } from "./constants";
var Container = styled.span.withConfig({
  displayName: "styles__Container",
  componentId: "sc-727amf-0"
})(["font-family:", ";", ";border-radius:9999px;line-height:16px;display:inline-block;color:", ";background:", ";padding:", ";", ";"], function (p) {
  return p.theme.fontFamily;
}, function (p) {
  return p.size === "small" ? p.theme.typography[100] : p.theme.typography[200];
}, function (p) {
  return p.type ? themeGet(legacyBadgeColors[p.type].color) : p.theme.colors.text.body;
}, function (p) {
  return p.type ? themeGet(legacyBadgeColors[p.type].background) : p.theme.colors.container.background.decorative[p.badgeColor];
}, function (p) {
  return p.size === "small" ? "".concat(p.theme.space[0], " ").concat(p.theme.space[200]) : "".concat(p.theme.space[300]);
}, COMMON);
export default Container;