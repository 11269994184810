import { getUserDetails } from "utils/auth";
import outdent from "outdent";

const createIssue = async (
  {
    name,
    description,
    volunteer = false,
    examples,
    "visual-reference": reference,
    severity
  },
  type
) => {
  const user = getUserDetails();

  const formattedDescription = outdent`
    ${description}
    ${examples ? `\n**Examples:** ${examples}` : ""}
    ${reference ? `**Reference:** ${reference}` : ""}
  `;

  const result = await fetch("/api/create-issue", {
    headers: {
      "content-type": "application/json"
    },
    method: "POST",
    body: JSON.stringify({
      title: name,
      description: formattedDescription,
      context: window.location.pathname,
      severity: severity,
      type: type,
      volunteer,
      ...(user && {
        author: {
          name: user.name,
          email: user.email
        }
      })
    })
  });

  return result;
};

export default createIssue;
