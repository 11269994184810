import React, { useRef } from "react";
import {
  Tooltip,
  Text,
  Icon,
  theme as racineTheme,
  Box
} from "@sproutsocial/racine";
import { Container } from "./styles";
import { useWidth } from "./utils";
import { valueAtKeyPath } from "utils/js";
import ClientOnly from "components/ClientOnly";

const ColorTag = ({ color, ...rest }) => {
  const colorContainer = useRef();
  const colorValue = valueAtKeyPath(racineTheme.colors, color);
  const width = useWidth(colorContainer);

  return (
    <Tooltip
      placement="top"
      content={colorValue ? colorValue : color}
      zIndex={501}
    >
      <Container
        copy={colorValue}
        background={colorValue}
        success={
          <Box display="flex" alignItems="center" minWidth={width}>
            {colorValue === "#FFFFFF" ? (
              <Box
                mr={300}
                height="12px"
                width="12px"
                borderRadius="pill"
                border={500}
                borderColor="neutral.200"
              ></Box>
            ) : color === "transparent" ? (
              <Box mr={300} display="flex" width="12px" justifyContent="center">
                <Icon name="sparkle-outline" />
              </Box>
            ) : (
              <Box
                mr={300}
                height="12px"
                width="12px"
                borderRadius="pill"
                bg={colorValue}
              ></Box>
            )}
            <Text>Copied!</Text>
          </Box>
        }
      >
        <Box display="flex" alignItems="center" ref={colorContainer}>
          {
            colorValue === "#FFFFFF" ? (
              <Box
                mr={300}
                height="12px"
                width="12px"
                borderRadius="pill"
                border={500}
                borderColor="neutral.200"
              ></Box>
            ) : colorValue === "transparent" ? (
              <Box mr={300} display="flex" width="12px" justifyContent="center">
                <Icon name="sparkle-outline" />
              </Box>
            ) : (
              <Box
                mr={300}
                height="12px"
                width="12px"
                borderRadius="pill"
                bg={colorValue}
              ></Box>
            )
            //Please someone figure out how to bring this back...
            //<Icon mr={300} size="mini" color={color} name="paint-can-solid" />
          }
          <Text {...rest}>{color}</Text>
        </Box>
      </Container>
    </Tooltip>
  );
};

export default (props) => (
  <ClientOnly>
    <ColorTag {...props} />
  </ClientOnly>
);
