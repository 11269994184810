import React from "react";
import Text from "components/global/Text";
import Heading from "components/Heading";

const NavHeading = (props) => (
  <Heading.h2
    typography={500}
    mt={0}
    mb={400}
    pb={350}
    fontWeight="extrabold"
    borderBottom="2px solid"
    borderColor="neutral.800"
    {...props}
  />
);

NavHeading.sub = (props) => (
  <Text.p
    uppercase
    color="neutral.500"
    typography={100}
    fontWeight="bold"
    mb={300}
    {...props}
  />
);

export default NavHeading;
