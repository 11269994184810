import styled, { css } from "styled-components";
import { verticalAlign } from "styled-system";
import { COMMON, FLEXBOX } from "@sproutsocial/seeds-react-system-props";
var sizes = {
  mini: "12px",
  small: "16px",
  medium: "24px",
  large: "32px",
  jumbo: "64px"
};

// TODO: remove width from stylesForSize
var stylesForSize = function stylesForSize(height, width, logoSize) {
  return css(["height:", ";line-height:", ";.logo-svg{height:", ";width:", ";}"], logoSize ? logoSize : height, logoSize ? logoSize : height, logoSize ? logoSize : height, logoSize ? logoSize : "");
};
var Container = styled("span").withConfig({
  displayName: "styles__Container",
  componentId: "sc-hrrn7g-0"
})(["display:inline-block;color:inherit;vertical-align:middle;", " ", " ", " ", ""], COMMON, FLEXBOX, verticalAlign, function (props) {
  return stylesForSize(props.height, props.width, sizes[props.logoSize]);
});
export default Container;