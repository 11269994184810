import styled from "styled-components";
import {
  COMMON,
  BORDER,
  LAYOUT,
  POSITION,
  FLEXBOX,
  GRID,
} from "@sproutsocial/seeds-react-system-props";
import { TypeContainerProps } from "./BoxTypes";

const Container = styled.div<TypeContainerProps>`
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};

  ${COMMON}
  ${BORDER}
  ${LAYOUT}
  ${POSITION}
  ${FLEXBOX}
  ${GRID}
`;

export default Container;
