import React, { useContext, createContext, useReducer, useEffect } from "react";
import { Box } from "@sproutsocial/racine";
import Dates from "./Dates";
import Figma from "./Figma";
import Mobile from "./Mobile";
import Issues from "./Issues";
import PlannedEnhancements from "./PlannedEnhancements";
import { STATUSES } from "./constants";
import ContributeButton from "components/collaboration/Button";

const ScorecardContext = createContext({});

const Divider = (props) => (
  <Box
    borderTop={500}
    borderColor="container.border.base"
    height="1px"
    ml={-450}
    mr={-450}
    {...props}
  />
);

const reducer = (state, action) => {
  switch (action.type) {
    case "issue":
      return { ...state, issues: [...state.issues, action.value] };
    case "plannedEnhancement":
      return {
        ...state,
        plannedEnhancements: [...state.plannedEnhancements, action.value]
      };
    default:
      return state;
  }
};

const Scorecard = ({
  children,
  status,
  publishDate,
  lastModifiedDateLookupKey,
  figmaURL,
  mobile,
  ...rest
}) => {
  const [state, dispatch] = useReducer(reducer, {
    issues: [],
    plannedEnhancements: []
  });

  if (!publishDate) {
    console.warn(
      `Warning: Scorecard with status "${status}" was not provided with date information. Did you forget to spread props down onto the <Scorecard /> component?`
    );
  }

  const { bg } = STATUSES[status.toLowerCase()];

  return (
    <ScorecardContext.Provider value={{ state, dispatch }}>
      <Box bg={bg} mx={-450} px={450} pb={400}>
        <Dates
          publishDate={publishDate}
          lastModifiedDateLookupKey={lastModifiedDateLookupKey}
          mt={-400}
          bg={bg}
        />
      </Box>

      <Divider mb={400} borderColor={bg.replace("0", "100")} />

      {figmaURL && <Figma url={figmaURL} mt={300} />}

      {mobile && <Mobile data={mobile} mt={350} />}

      {(figmaURL || mobile) && <Divider my={400} />}

      <Issues
        mdxIssues={state.issues}
        status={status}
        context={lastModifiedDateLookupKey}
      />

      <PlannedEnhancements data={state.plannedEnhancements} />

      {state.plannedEnhancements.length > 0 && <Divider my={400} />}

      <ContributeButton width={1} css="justify-content: center;" mt={200} />

      {children}
    </ScorecardContext.Provider>
  );
};

const Issue = ({ children }) => {
  const { dispatch } = useContext(ScorecardContext);

  useEffect(() => {
    dispatch({ type: "issue", value: children });
    // eslint-disable-next-line
  }, []);

  return null;
};

const PlannedEnhancement = ({ children }) => {
  const { dispatch } = useContext(ScorecardContext);

  useEffect(() => {
    dispatch({ type: "plannedEnhancement", value: children });
    // eslint-disable-next-line
  }, []);

  return null;
};

Scorecard.Issue = Issue;
Scorecard.PlannedEnhancement = PlannedEnhancement;

export default Scorecard;
